import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";

import {
  SearchOutlined,
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  PaperClipOutlined,
  FileExcelOutlined,
  CheckOutlined
} from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Button,
  Form,
  Table,
  Layout,
  Typography,
  Divider,
  Input,
  Space,
  Modal,
  Descriptions,
  Card,
  Select,
  Badge,
  BackTop,
  Tabs,
  Tag,
  PageHeader,
  Carousel,
  ConfigProvider
} from "antd";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/es/date-picker/locale/th_TH";
import * as xlsx from "xlsx";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";
import "./index.css";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_main_participant = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const DatePicker = generatePicker(dayjsGenerateConfig);

  const [show1, _show1] = useState(false);
  const [show2, _show2] = useState(true);

  const [xStatus, _xStatus] = useState("");
  const [xStatusValue, _xStatusValue] = useState("0");

  const [xType, _xType] = useState("");
  const [xTypeValue, _xTypeValue] = useState("0");

  const [xtitle, _xtitle] = useState("");
  const [xNumData, _xNumData] = useState("");

  const [xCountP1, _xCountP1] = useState("");
  const [xCountI1, _xCountI1] = useState("");
  const [xCountC1, _xCountC1] = useState("");
  const [xCountF1, _xCountF1] = useState("");
  const [xCountS1, _xCountS1] = useState("");

  const [dataSource1, _dataSource1] = useState();
  const [dataSourceEx, _dataSourceEx] = useState();

  const [pageTable, _pageTable] = useState(1);
  const [pageSizeTable, _pageSizeTable] = useState(100);

  const [paginationInfo] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const xresearch_id = localStorage.getItem("research_id");

    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          //ค้นหาสถานะโครงการ
          axios
            .get(xURL + "Project_status")
            .then(result => {
              //console.log(result.data);

              _xStatus(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหาประเภทโครงการ
          axios
            .get(xURL + "ptype")
            .then(result => {
              //console.log(result.data);

              _xType(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          bOkclick();
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const bClearclick = () => {
    form1.resetFields();

    _dataSource1([]);

    _pageTable(1);
    _pageSizeTable(100);

    _xtitle("");
    _xNumData("");

    _xStatusValue("0");
    _xTypeValue("0");

    _xCountP1("");
    _xCountI1("");
    _xCountC1("");
    _xCountF1("");
    _xCountS1("");

    _show1(false);
    _show2(true);

    bOkclick();
  };

  const onFinish = f => {
    //ช่วงปี,วันที่
    let xyearsearch = 0;

    let xdatesearch = 0,
      xsdate = "",
      xedate = "";

    let xstatussearch = 0,
      xstatus = "";

    let xreserchsearch = 0,
      xreserch_name = "";

    let xcmumis_idsearch = 0,
      xcmumis_id = "";

    let xprojectsearch = 0,
      xprojectname = "";

    let xtypesearch = 0,
      xtype = "";

    if (f.ydate) {
      xyearsearch = 1;
      //console.log(dayjs(f.ydate).format('YYYY'));
    }

    if (f.sedate) {
      xdatesearch = 1;
      xsdate = dayjs(f.sedate[0]).format("YYYY-MM-DD");
      xedate = dayjs(f.sedate[1]).format("YYYY-MM-DD");

      //console.log(xsdate,xedate);
    }

    //สถานะโครงการ
    if (xStatusValue !== "0") {
      xstatussearch = 1;
      xstatus = xStatusValue;
    }

    //ชื่อนักวิจัย
    if (f.edresercher !== "") {
      xreserchsearch = 1;
      xreserch_name = f.edresercher;
    }

    //รหัสโครงการ
    if (f.edid !== "") {
      xcmumis_idsearch = 1;
      xcmumis_id = f.edid;
    }

    //ชื่อโครงการวิจัย
    if (f.edpjname !== "") {
      xprojectsearch = 1;
      xprojectname = f.edpjname;
    }

    //ประเภทโครงการ
    if (xTypeValue !== "0") {
      xtypesearch = 1;
      xtype = xTypeValue;
    }

    axios
      .post(xURL + "Allproject2", {
        yearsearch: xyearsearch,
        ydate: dayjs(f.ydate).format("YYYY"),
        datesearch: xdatesearch,
        sdate: xsdate,
        edate: xedate,
        statussearch: xstatussearch,
        status: xstatus,
        reserchsearch: xreserchsearch,
        reserch_name: xreserch_name,
        cmumis_idsearch: xcmumis_idsearch,
        cmumis_id: xcmumis_id,
        projectsearch: xprojectsearch,
        projectname: xprojectname,
        typesearch: xtypesearch,
        type: xtype
      })
      .then(result => {
        //.log(result.data);

        if (result.data === "") {
          Modal.warning({
            title: "แสดงรายการไม่ได้",
            content: "ไม่มีข้อมูลตามเงื่อนไขที่เลือก"
          });
        } else {
          ShowAll(result.data);
          // _xtitle(ztitle);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bOkclick = () => {
    const f = form1.getFieldsValue();

    axios
      .post(xURL + "Allproject2", {})
      .then(result => {
        //.log(result.data);

        if (result.data === "") {
          Modal.warning({
            title: "แสดงรายการไม่ได้",
            content: "ไม่มีข้อมูลตามเงื่อนไขที่เลือก"
          });
        } else {
          ShowAll(result.data);
          // _xtitle(ztitle);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const ShowAll = alldata => {
    const Tb1 = [],
      Tbex = [];

    let xP = 0,
      xI = 0,
      xC = 0,
      xF = 0,
      xS = 0;

    let xnum = 0,
      xcmumis_id = "";

    alldata.forEach((i, idx) => {
      let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
      let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

      let xbudget = 0,
        xresearch = "";

      if (i.budget > 0) {
        xbudget = i.budget.toLocaleString(undefined, {
          minimumFractionDigits: 2
        });
      }

      if (!i.researname) {
        xresearch = "";
      } else {
        xresearch = i.researname;
      }

      if (i.cmumis_id !== xcmumis_id) {
        xnum = xnum + 1;

        if (i.status_id === "P") {
          xP = xP + 1;
        }
        if (i.status_id === "I") {
          xI = xI + 1;
        }
        if (i.status_id === "C") {
          xC = xC + 1;
        }
        if (i.status_id === "F") {
          xF = xF + 1;
        }
        if (i.status_id === "S") {
          xS = xS + 1;
        }
      }

      Tb1.push({
        key: idx,
        num: xnum,
        cmumis_id: i.cmumis_id,
        name_P: i.name_p,
        name_shotP: i.short_name,
        resercher: xresearch,
        resercher_status: i.position_name,
        start_date: xsdate,
        end_date: xedate,
        budget: (
          <Text style={{ fontWeight: "bold" }}>
            {xbudget}
          </Text>
        ),
        status: i.status_id,
        typeP: i.project_type_name
      });

      Tbex.push({
        cmumis_id: i.cmumis_id,
        name_P: i.name_p,
        name_shotP: i.short_name,
        resercher: xresearch,
        resercher_status: i.position_name,
        start_date: xsdate,
        end_date: xedate,
        budget: xbudget,
        status: i.status_id,
        typeP: i.project_type_name
      });

      xcmumis_id = i.cmumis_id;
    });

    _xCountP1(xP + " โครงการ");
    _xCountI1(xI + " โครงการ");

    _xCountC1(xC + " โครงการ");
    _xCountF1(xF + " โครงการ");
    _xCountS1(xS + " โครงการ");

    _xNumData(" * " + xnum + " โครงการ");

    _show1(true);

    //console.log(Tb1);
    // console.log(Tbex);
    _dataSource1(Tb1);
    _dataSourceEx(Tbex);
  };

  const bExcelclick = () => {
    let xdata = [],
      xtabtitle = "",
      header = [];

    xtabtitle = "รายงานโครงการวิจัย (ผู้ร่วมวิจัย)";

    header.push(
      "รหัสโครงการ",
      "ชื่อโครงการวิจัย",
      "ชื่อย่อโครงการ",
      "นักวิจัย",
      "สถานะในโครงการ",
      "วันที่เริ่มต้น",
      "สิ้นสุด",
      "งบประมาณรวม",
      "สถานะโครงการวิจัย",
      "ประเภทโครงการวิจัย"
    );

    xdata.push([xtabtitle]);

    xdata.push([""]);
    xdata.push(header);

    let excmumis_id = "",
      exname_P = "",
      exname_shotP = "",
      exstart_date = "",
      exend_date = "",
      exbudget = "",
      exstatus = "",
      extypeP = "";

    for (var row in dataSourceEx) {
      const xx = [];

      let xchk = 0;

      for (var col in dataSourceEx[row]) {
        if (col == "cmumis_id") {
          if (excmumis_id == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (dataSourceEx[row]["cmumis_id"] == excmumis_id) {
              xx.push("");

              xchk = 1;
            } else {
              xx.push(dataSourceEx[row][col]);

              xchk = 0;
            }
          }

          excmumis_id = dataSourceEx[row]["cmumis_id"];
        } else if (col == "name_P") {
          if (exname_P == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exname_P = dataSourceEx[row]["name_P"];
        } else if (col == "name_shotP") {
          if (exname_shotP == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exname_shotP = dataSourceEx[row]["name_shotP"];
        } else if (col == "start_date") {
          if (exstart_date == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exstart_date = dataSourceEx[row]["start_date"];
        } else if (col == "end_date") {
          if (exend_date == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exend_date = dataSourceEx[row]["end_date"];
        } else if (col == "budget") {
          if (exbudget == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exbudget = dataSourceEx[row]["budget"];
        } else if (col == "status") {
          if (exstatus == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exstatus = dataSourceEx[row]["status"];
        } else if (col == "typeP") {
          if (extypeP == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          extypeP = dataSourceEx[row]["typeP"];
        } else {
          xx.push(dataSourceEx[row][col]);
        }
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "รายงานโครงการวิจัย (ผู้ร่วมวิจัย).xlsx");
  };

  const GoPage = cmumis_id => {
    window.open("/Research_main?cmumis_id=" + cmumis_id, "_blank");
  };

  const columns1 = [
    {
      title: "ลำดับ",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "รหัสโครงการ",
      dataIndex: "cmumis_id",
      key: "cmumis_id",
      align: "center",
      width: 100,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                GoPage(text);
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อโครงการวิจัย",
      dataIndex: "name_P",
      key: "name_P",
      //align: "center",
      width: 450,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                GoPage(record.cmumis_id);
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อย่อโครงการ",
      dataIndex: "name_shotP",
      key: "name_shotP",
      //align: "center",
      width: 150,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "นักวิจัย",
      dataIndex: "resercher",
      key: "resercher",
      align: "center",
      width: 180,
      render(text, record, index) {
        return {
          children: __TBShowText(text, 0, 1)
        };
      }
      // sorter: (a, b) => {
      //   return a.resercher.localeCompare(b.resercher);
      // }
    },
    {
      title: "สถานะในโครงการ",
      dataIndex: "resercher_status",
      key: "resercher_status",
      align: "center",
      width: 180,
      render(text, record, index) {
        return {
          children: __TBShowText(text, 0, 1)
        };
      }
      // sorter: (a, b) => {
      //   return a.resercher_status.localeCompare(b.resercher_status);
      // }
    },
    {
      title: "วันที่เริ่มต้น",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      width: 110,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "สิ้นสุด",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      width: 110,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "งบประมาณรวม",
      dataIndex: "budget",
      key: "budget",
      align: "center",
      width: 120,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: __TBShowText(text, 1, 0),
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "สถานะโครงการวิจัย",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
      // ,
      // render: status => {
      //   let xcolor = "";

      //   if (status === "P") {
      //     xcolor = "gold";
      //   } else if (status === "I") {
      //     xcolor = "geekblue";
      //   } else if (status === "C") {
      //     xcolor = "green";
      //   } else if (status === "F") {
      //     xcolor = "red";
      //   } else if (status === "S") {
      //     xcolor = "red";
      //   }

      //   return (
      //     <Tag color={xcolor} key={status}>
      //       {status.toUpperCase()}
      //     </Tag>
      //   );
      // }
    },
    {
      title: "ประเภทโครงการวิจัย",
      dataIndex: "typeP",
      key: "typeP",
      align: "center",
      width: 150,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.cmumis_id === dataSource1[trueIndex - 1].cmumis_id
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.cmumis_id === dataSource1[trueIndex + i].cmumis_id;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    }
  ];

  function handleChange(value) {
    //console.log(`selected ${value}`);

    if (value === "0") {
      _show2(true);
    } else if (value === "1") {
      _show2(false);
    }
  }

  function handleChange2(value) {
    //console.log(`selected ${value}`);

    _xStatusValue(value);
  }

  function handleChange3(value) {
    //console.log(`selected ${value}`);

    _xTypeValue(value);
  }

  const pageOnChange = (zpage, zpageSize) => {
    _pageTable(zpage);
    _pageSizeTable(zpageSize);
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content className="site-layout" style={{ padding: 0, marginTop: 0 }}>
        <div
          className="site-layout-background"
          style={{ padding: 30, minHeight: 380 }}
        >
          <PageHeader
            className="site-page-header"
            title="ค้นหา โครงการวิจัย (ผู้ร่วมวิจัย)"
          />

          <Divider />

          <Form
            form={form1}
            onFinish={onFinish}
            initialValues={{
              slstatus: "สถานะทั้งหมด",
              edid: "",
              edpjname: "",
              edresercher: ""
            }}
            {...layout}
          >
            <Row justify="space-around">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card
                  style={{
                    borderRadius: "15px",
                    overflow: "hidden"
                  }}
                >
                  <Form.Item label="แสดงตาม" name="sldate">
                    <Select
                      defaultValue="0"
                      style={{ width: 200 }}
                      onChange={handleChange}
                    >
                      <Option value="0">ปีงบประมาณ</Option>
                      <Option value="1">ช่วงวันที่งบประมาณ</Option>
                    </Select>
                  </Form.Item>

                  <ConfigProvider locale={configuredLocale}>
                    {show2
                      ? <Form.Item label="ปี" name="ydate">
                          <DatePicker
                            picker="year"
                            format={"BBBB"}
                            style={{ width: 200 }}
                          />
                        </Form.Item>
                      : <Form.Item label="ช่วงวันที่" name="sedate">
                          <DatePicker.RangePicker
                            format="DD/MM/BBBB"
                            style={{ width: "50%" }}
                          />
                        </Form.Item>}
                  </ConfigProvider>

                  <Form.Item label="สถานะโครงการ" name="slstatus">
                    <Select
                      defaultValue="สถานะทั้งหมด"
                      style={{ width: "50%" }}
                      onChange={handleChange2}
                    >
                      {xStatus.length > 0
                        ? xStatus.map((i, idx) => {
                            return (
                              <Option value={i.id}>
                                {i.id} : {i.name}
                              </Option>
                            );
                          })
                        : <Option value="0">สถานะทั้งหมด</Option>}
                    </Select>
                  </Form.Item>

                  <Form.Item label="ชื่อนักวิจัย" name="edresercher">
                    <Input
                      //disabled={Export}
                      allowClear
                      placeholder="ชื่อนักวิจัย"
                      style={{ width: "50%" }}
                    />
                  </Form.Item>

                  <Form.Item label="รหัสโครงการ" name="edid">
                    <Input
                      //disabled={Export}
                      allowClear
                      placeholder="คีย์รหัสโครงการ"
                      style={{ width: "50%" }}
                      maxLength="10"
                    />
                  </Form.Item>

                  <Form.Item label="ชื่อโครงการวิจัย" name="edpjname">
                    <Input
                      //disabled={Export}
                      allowClear
                      placeholder="ชื่อโครงการวิจัย"
                      style={{ width: "50%" }}
                    />
                  </Form.Item>

                  <Form.Item label="ประเภทโครงการ" name="sltype">
                    <Select
                      defaultValue="ประเภททั้งหมด"
                      style={{ width: "50%" }}
                      onChange={handleChange3}
                    >
                      {xType.length > 0
                        ? xType.map((i, idx) => {
                            return (
                              <Option value={i.id}>
                                {i.id} : {i.name}
                              </Option>
                            );
                          })
                        : <Option value="0">ประเภททั้งหมด</Option>}
                    </Select>
                  </Form.Item>
                </Card>
              </Col>
            </Row>

            <Divider />

            <Row justify="center">
              <Space>
                <Button ghost type="primary" htmlType="submit">
                  <SearchOutlined /> แสดงโครงการวิจัย
                </Button>

                <Button ghost type="danger" onClick={bClearclick}>
                  <ClearOutlined /> เคลียร์
                </Button>

                <Button ghost type="success" onClick={bExcelclick}>
                  <FileExcelOutlined /> Excel
                </Button>
              </Space>
            </Row>

            <Divider />

            <Text style={{ fontWeight: "bold" }}>
              {"แสดงโครงการวิจัย "} {xtitle}
            </Text>

            <Text style={{ fontWeight: "bold", textalign: "right" }}>
              {xNumData}
            </Text>

            {show1
              ? <div>
                  <br />
                  <Card
                    //size="small"
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden"
                    }}
                  >
                    <Row>
                      <Col span={8}>
                        <Badge
                          color="yellow"
                          text="โครงการวิจัยที่อยู่ระหว่างยื่นข้อเสนอโครงการ (P)"
                        />
                      </Col>
                      <Col span={16}>
                        <Badge color="yellow" text={xCountP1} />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={8}>
                        <Badge
                          color="blue"
                          text="โครงการวิจัยที่ได้รับอนุมัติและอยู่ระหว่างดำเนินการ (I)"
                        />
                      </Col>
                      <Col span={16}>
                        <Badge color="blue" text={xCountI1} />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={8}>
                        <Badge
                          color="green"
                          text="โครงการวิจัยที่เสร็จสิ้นแล้ว (C)"
                        />
                      </Col>
                      <Col span={16}>
                        <Badge color="green" text={xCountC1} />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={8}>
                        <Badge
                          color="red"
                          text="ข้อเสนอโครงการที่ไม่ได้รับอนุมัติ (F)"
                        />
                      </Col>
                      <Col span={16}>
                        <Badge color="red" text={xCountF1} />
                      </Col>
                    </Row>

                    <Row>
                      <Col span={8}>
                        <Badge
                          color="red"
                          text="โครงการวิจัยที่ได้รับอนุมัติ แต่ภายหลังยกเลิกโครงการ (S)"
                        />
                      </Col>
                      <Col span={16}>
                        <Badge color="red" text={xCountS1} />
                      </Col>
                    </Row>
                  </Card>

                  <br />
                </div>
              : <div />}

            <br />

            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={{
                onChange: (zpage, zpageSize) => {
                  pageOnChange(zpage, zpageSize);
                },
                current: pageTable,
                pageSize: pageSizeTable
              }}
            />
          </Form>
        </div>
      </Content>

      <Footer_last />

      <StyledForm />

      <BackTop />
    </React.Fragment>
  );
};

export default Research_main_participant;
