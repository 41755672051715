import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";

import { createGlobalStyle } from "styled-components";
import { PaperClipOutlined, FileExcelOutlined } from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Table,
  Typography,
  Button
} from "antd";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import moment from "moment";
import "moment/locale/th";

import * as xlsx from "xlsx";

dayjs.locale("th");
dayjs.extend(buddhistEra);

const { Content } = Layout;
const { Text } = Typography;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Research_report_patent = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState();
  const [dataSourceEx, _dataSourceEx] = useState();

  const [paginationInfo] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          Patent_Data();
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const Patent_Data = () => {
    axios
      .get(xURL + "data_patent")
      .then(result => {
        const Tb1 = [],
          Tb2 = [];

        let xnum = 0,
          xpatent_name = "";

        result.data.forEach((i, idx) => {
          let xtype = "",
            xrole = "",
            xscope = "";

          if (i.patent_type == 1) {
            xtype = "อนุสิทธิบัตร";
          } else if (i.patent_type == 2) {
            xtype = "สิทธิบัตร";
          }

          if (i.patent_role == 1) {
            xrole = "ผู้ประดิษฐ์หลัก";
          } else if (i.patent_role == 2) {
            xrole = "ผู้ประดิษฐ์ร่วม";
          }

          if (i.patent_scope == 1) {
            xscope = "ระดับชาติ";
          } else if (i.patent_scope == 2) {
            xscope = "ระดับนานาชาติ";
          }

          let xdate1 = "",
            xdate2 = "",
            xdate3 = "";

          if (!i.date_input) {
            xdate1 = "";
          } else {
            xdate1 = dayjs(i.date_input).format("DD/MM/BBBB");
          }
          if (!i.date_publish) {
            xdate2 = "";
          } else {
            xdate2 = dayjs(i.date_publish).format("DD/MM/BBBB");
          }
          if (!i.date_expire) {
            xdate3 = "";
          } else {
            xdate3 = dayjs(i.date_expire).format("DD/MM/BBBB");
          }

          let url_file = "",
            url_file2 = "";

          url_file = `${xURL}file6/${i.patent_submit_file}`;
          url_file2 = `${xURL}file7/${i.patent_certificate_file}`;

          if (i.patent_name !== xpatent_name) {
            xnum = xnum + 1;
          }

          Tb1.push({
            key: idx,
            num: xnum,
            patent_name: i.patent_name,
            patent_type: xtype,
            patent_researchers: i.patent_researchers,
            patent_role: xrole,
            date_input: xdate1,
            patent_request_number: i.patent_request_number,
            patent_number: i.patent_number,
            date_publish: xdate2,
            date_expire: xdate3,
            patent_scope: xscope,
            linkfile: url_file,
            linkfile2: url_file2,
            chkfile1: i.patent_submit_file,
            chkfile2: i.patent_certificate_file
          });

          Tb2.push({
            num: idx + 1,
            patent_name: i.patent_name,
            patent_name_eng: i.patent_name_eng,
            patent_type: xtype,
            patent_researchers: i.patent_researchers,
            patent_role: xrole,
            date_input: xdate1,
            patent_request_number: i.patent_request_number,
            patent_number: i.patent_number,
            date_publish: xdate2,
            date_expire: xdate3,
            patent_scope: xscope
          });

          xpatent_name = i.patent_name;
        });

        _dataSource1(Tb1);
        _dataSourceEx(Tb2);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bExcel = () => {
    let xdata = [],
      xtabtitle = "",
      header = [];

    xtabtitle = "รายงานทรัพย์สินทางปัญญา";

    header.push(
      "ที่",
      "ชื่อที่แสดงถึงการประดิษฐ์/การออกแบบผลิตภัณฑ์",
      "(eng) ชื่อที่แสดงถึงการประดิษฐ์/การออกแบบผลิตภัณฑ์",
      "ประเภท",
      "ผู้ประดิษฐ์",
      "สถานะผู้ประดิษฐ์",
      "วันที่ยื่นจด",
      "เลขที่คำขอ",
      "เลขที่สิทธิบัตร/อนุสิทธิบัตร",
      "วันที่เผยแพร่",
      "วันที่หมดอายุ",
      "ระดับการเผยแพร่"
    );

    xdata.push([xtabtitle]);
    xdata.push([""]);
    xdata.push(header);

    for (var row in dataSourceEx) {
      const xx = [];
      for (var col in dataSourceEx[row]) {
        xx.push(dataSourceEx[row][col]);
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "รายงานทรัพย์สินทางปัญญา.xlsx");
  };

  const columns1 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อที่แสดงถึงการประดิษฐ์/การออกแบบผลิตภัณฑ์",
      dataIndex: "patent_name",
      key: "patent_name",
      width: 300,
      // sorter: (a, b) => {
      //   return a.patent_name.localeCompare(b.patent_name);
      // },
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <p style={{ fontWeight: "bold" }}>
              {text}
            </p>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ประเภท",
      dataIndex: "patent_type",
      key: "patent_type",
      align: "center",
      width: 100,
      // sorter: (a, b) => {
      //   return a.patent_type.localeCompare(b.patent_type);
      // }
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ผู้ประดิษฐ์",
      dataIndex: "patent_researchers",
      key: "patent_researchers",
      width: 200
      // sorter: (a, b) => {
      //   return a.patent_researchers.localeCompare(b.patent_researchers);
      // }
    },
    {
      title: "สถานะผู้ประดิษฐ์",
      dataIndex: "patent_role",
      key: "patent_role",
      align: "center",
      width: 100
      // sorter: (a, b) => {
      //   return a.patent_role.localeCompare(b.patent_role);
      // }
    },
    {
      title: "วันที่ยื่นจด",
      dataIndex: "date_input",
      key: "date_input",
      align: "center",
      width: 100,
      // sorter: (a, b) => {
      //   return a.date_input.localeCompare(b.date_input);
      // }
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "เลขที่คำขอ",
      dataIndex: "patent_request_number",
      key: "patent_request_number",
      align: "center",
      width: 100,
      // sorter: (a, b) => a.patent_request_number - b.patent_request_number
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "เลขที่สิทธิบัตร/อนุสิทธิบัตร",
      dataIndex: "patent_number",
      key: "patent_number",
      align: "center",
      width: 100,
      // sorter: (a, b) => a.patent_number - b.patent_number
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "วันที่เผยแพร่",
      dataIndex: "date_publish",
      key: "date_publish",
      align: "center",
      width: 100,
      // sorter: (a, b) =>
      //   moment(moment(a.date_publish, "DD/MM/YYYY")).unix() -
      //   moment(moment(b.date_publish, "DD/MM/YYYY")).unix()
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "วันที่หมดอายุ",
      dataIndex: "date_expire",
      key: "date_expire",
      align: "center",
      width: 100,
      // sorter: (a, b) =>
      //   moment(moment(a.date_expire, "DD/MM/YYYY")).unix() -
      //   moment(moment(b.date_expire, "DD/MM/YYYY")).unix()
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ระดับการเผยแพร่",
      dataIndex: "patent_scope",
      key: "patent_scope",
      align: "center",
      width: 100,
      // sorter: (a, b) => {
      //   return a.patent_scope.localeCompare(b.patent_scope);
      // }
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ไฟล์เอกสารคำขอ",
      dataIndex: "patent_submit_file ",
      key: "patent_submit_file ",
      align: "center",
      width: 100,
      // render(text, record, index) {
      //   if (record.chkfile1) {
      //     return {
      //       children: (
      //         <a href={record.linkfile} target="_blank">
      //           <PaperClipOutlined /> ไฟล์เอกสารคำขอ
      //         </a>
      //       )
      //     };
      //   }
      // }
      render(text, record, index) {
        if (record.chkfile1) {
          const trueIndex =
            index + paginationInfo.pageSize * (paginationInfo.current - 1);

          const obj = {
            children: (
              <a href={record.linkfile} target="_blank">
                <PaperClipOutlined /> ไฟล์เอกสารคำขอ
              </a>
            ),
            props: {}
          };

          if (
            index >= 1 &&
            record.patent_name === dataSource1[trueIndex - 1].patent_name
          ) {
            obj.props.rowSpan = 0;
          } else {
            for (
              let i = 0;
              trueIndex + i !== dataSource1.length &&
              record.patent_name === dataSource1[trueIndex + i].patent_name;
              i += 1
            ) {
              obj.props.rowSpan = i + 1;
            }
          }

          return obj;
        } else {
          const trueIndex =
            index + paginationInfo.pageSize * (paginationInfo.current - 1);

          const obj = {
            children: "",
            props: {}
          };

          if (
            index >= 1 &&
            record.patent_name === dataSource1[trueIndex - 1].patent_name
          ) {
            obj.props.rowSpan = 0;
          } else {
            for (
              let i = 0;
              trueIndex + i !== dataSource1.length &&
              record.patent_name === dataSource1[trueIndex + i].patent_name;
              i += 1
            ) {
              obj.props.rowSpan = i + 1;
            }
          }

          return obj;
        }
      }
    },
    {
      title: "ไฟล์ Certificate",
      dataIndex: "patent_certificate_file",
      key: "patent_certificate_file",
      align: "center",
      width: 100,
      // render(text, record, index) {
      //   if (record.chkfile2) {
      //     return {
      //       children: (
      //         <a href={record.linkfile2} target="_blank">
      //           <PaperClipOutlined /> ไฟล์ Certificate
      //         </a>
      //       )
      //     };
      //   }
      // }

      render(text, record, index) {
        if (record.chkfile2) {
          const trueIndex =
            index + paginationInfo.pageSize * (paginationInfo.current - 1);

          const obj = {
            children: (
              <a href={record.linkfile2} target="_blank">
                <PaperClipOutlined /> ไฟล์ Certificate
              </a>
            ),
            props: {}
          };

          if (
            index >= 1 &&
            record.patent_name === dataSource1[trueIndex - 1].patent_name
          ) {
            obj.props.rowSpan = 0;
          } else {
            for (
              let i = 0;
              trueIndex + i !== dataSource1.length &&
              record.patent_name === dataSource1[trueIndex + i].patent_name;
              i += 1
            ) {
              obj.props.rowSpan = i + 1;
            }
          }

          return obj;
        } else {
          const trueIndex =
            index + paginationInfo.pageSize * (paginationInfo.current - 1);

          const obj = {
            children: "",
            props: {}
          };

          if (
            index >= 1 &&
            record.patent_name === dataSource1[trueIndex - 1].patent_name
          ) {
            obj.props.rowSpan = 0;
          } else {
            for (
              let i = 0;
              trueIndex + i !== dataSource1.length &&
              record.patent_name === dataSource1[trueIndex + i].patent_name;
              i += 1
            ) {
              obj.props.rowSpan = i + 1;
            }
          }

          return obj;
        }
      }
    }
  ];

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="รายงานทรัพย์สินทางปัญญา"
            />
            <Divider />
          </Col>
        </Row>

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            <Button ghost type="success" onClick={bExcel}>
              <FileExcelOutlined /> Excel
            </Button>
          </Col>
        </Row>

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={false}
            />
          </Col>
        </Row>
      </Content>

      <div style={{ height: "80px" }} />

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_report_patent;
