import React, { useState } from "react";

import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const Barchart = prop => {
  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height={210}>
        <ComposedChart
          layout="vertical"
          width={500}
          height={400}
          data={prop.dataBar}
          margin={{
            top: 15,
            right: 25,
            bottom: 10,
            left: 10
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" scale="band" />
          <Tooltip />
          <Legend />
          <Bar
            name="Room"
            dataKey="room"
            fill="#8884d8"
            label={{ position: "right" }}
          />
          <Bar
            name="Teacher"
            dataKey="teacher"
            fill="#82ca9d"
            label={{ position: "right" }}
          />
          <Bar
            name="Children"
            dataKey="children"
            fill="#ACE2E1"
            label={{ position: "right" }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default Barchart;
