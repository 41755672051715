import React, { useEffect, useState } from "react";
import axios from "axios";

import "./css/dashboard.css";

import {
  Row,
  Col,
  Layout,
  Card,
  Divider,
  Space,
  Select,
  Button,
  Form,
  Modal,
  InputNumber
} from "antd";

import moment from "moment";

import { SaveOutlined, ClearOutlined } from "@ant-design/icons";

import swal from "sweetalert";

import Barchart from "./Barchart";

const { Option } = Select;

const Dashboard_he = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  let YearNow = moment().format("YYYY");

  const [YearValue, _YearValue] = useState(YearNow);
  const [xYeardh, _xYeardh] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);

  const [Kr9_old, _Kr9_old] = useState("");
  const [Kr10_old, _Kr10_old] = useState("");
  const [Kr11_old, _Kr11_old] = useState("");

  const [Num_Color1, _Num_Color1] = useState("red");
  const [Num_Color2, _Num_Color2] = useState("red");
  const [Num_Color3, _Num_Color3] = useState("red");

  const [data_Kr9, _data_Kr9] = useState();
  const [data_Kr10, _data_Kr10] = useState();
  const [data_Kr11, _data_Kr11] = useState();

  const [goal_Kr9, _goal_Kr9] = useState();
  const [goal_Kr10, _goal_Kr10] = useState();
  const [goal_Kr11, _goal_Kr11] = useState();

  const [dataSum_Kr9, _dataSum_Kr9] = useState();
  const [dataSum_Kr10, _dataSum_Kr10] = useState();
  const [dataSum_Kr11, _dataSum_Kr11] = useState();

  const [dataBar1, _dataBar1] = useState([]);

  useEffect(() => {
    axios
      .get(xURL + "year_papers")
      .then(result => {
        //console.log(result.data);

        _xYeardh(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    Find_scopus("HE1", YearValue);
    Find_scopus("HE2", YearValue);
    Find_scopus("HE3", YearValue);

    Find_barData("HE1", YearValue);
  }, []);

  const handleChange = value => {
    // console.log(`selected ${value}`);
    Find_scopus("HE1", value);
    Find_scopus("HE2", value);
    Find_scopus("HE3", value);

    Find_barData("HE1", value);

    _YearValue(value);
  };

  const bClearclick = () => {
    form1.resetFields();

    findGoal();
  };

  const bSaveclick = () => {
    const f = form1.getFieldsValue();

    if (!f.ipKr9) {
      swal(
        "Save Error!",
        "Data entry fields KR9 Total of Service Recipt. (Goal)!",
        "warning"
      );
    } else if (!f.ipKr10) {
      swal(
        "Save Error!",
        "Data entry fields KR10 Total of Dust-free Rooms (Goal)!",
        "warning"
      );
    } else if (!f.ipKr11) {
      swal(
        "Save Error!",
        "Data entry fields KR11 TQA Score (Goal)!",
        "warning"
      );
    } else {
      swal({
        title: "Confirm Edit Goal",
        text: "Year " + YearValue,
        icon: "warning",
        buttons: ["Cancle", "Ok"]
      }).then(willOk => {
        if (willOk) {
          updatedata();
        }
      });
    }
  };

  const updatedata = () => {
    const f = form1.getFieldsValue();

    let xdata = [
      { name: "KR9 Total of Service Recipt.", goal: f.ipKr9, old: Kr9_old },
      { name: "KR10 Total of Dust-free Rooms", goal: f.ipKr10, old: Kr10_old },
      { name: "KR11 TQA Score", goal: f.ipKr11, old: Kr11_old }
    ];

    // console.log(emplydata);

    xdata.forEach((i, idx) => {
      if (!i.old) {
        axios
          .post(xURL + "insert_goal", {
            year: YearValue,
            name: i.name,
            goal: i.goal
          })
          .then(result => {
            swal("Save Complete!", "", "success");
            setModalOpen(false);

            // console.log(result.data);

            Find_scopus("HE1", YearValue);
            Find_scopus("HE2", YearValue);
            Find_scopus("HE3", YearValue);

            Find_barData("HE1", YearValue);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else {
        axios
          .post(xURL + "update_goal", {
            year: YearValue,
            name: i.name,
            goal: i.goal
          })
          .then(result => {
            swal("Save Complete!", "", "success");
            setModalOpen(false);

            // console.log(result.data);

            Find_scopus("HE1", YearValue);
            Find_scopus("HE2", YearValue);
            Find_scopus("HE3", YearValue);

            Find_barData("HE1", YearValue);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const findGoal = () => {
    _Kr9_old("");
    _Kr10_old("");
    _Kr11_old("");

    axios
      .post(xURL + "db_findGoal", { year: YearValue })
      .then(result => {
        // console.log("data", result.data);

        result.data.forEach((i, idx) => {
          if (i.topic == "KR9 Total of Service Recipt.") {
            form1.setFieldsValue({
              ipKr9: i.goal
            });

            _Kr9_old(i.goal);
          } else if (i.topic == "KR10 Total of Dust-free Rooms") {
            form1.setFieldsValue({
              ipKr10: i.goal
            });

            _Kr10_old(i.goal);
          } else if (i.topic == "KR11 TQA Score") {
            form1.setFieldsValue({
              ipKr11: i.goal
            });

            _Kr11_old(i.goal);
          }
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_scopus = (order, YearValue) => {
    let xcolorchk = 0,
      xcolorchk2 = 0;

    axios
      .post("https://www2.ntaqhi.info/api/Cleanroom_Research", {
        yearroom: YearValue
      })
      .then(result => {
        if (result.data[0]) {
          axios
            .post(xURL + "db_tabbox", {
              order: order,
              year: YearValue,
              year2: parseInt(YearValue) + 543
            })
            .then(result2 => {
              if (result2.data[0]) {
                if (order == "HE1") {
                  _goal_Kr9(result2.data[0].goal);

                  if (YearValue == "2025") {
                    _data_Kr9(8405);

                    xcolorchk = 8405;
                  } else if (YearValue == "2024") {
                    _data_Kr9(10702);

                    xcolorchk = 10702;
                  } else {
                    _data_Kr9(0);

                    xcolorchk = 0;
                  }

                  if (
                    parseFloat(xcolorchk) >= parseFloat(result2.data[0].goal)
                  ) {
                    _Num_Color1("green");
                  } else {
                    _Num_Color1("red");
                  }
                } else if (order == "HE2") {
                  _goal_Kr10(result2.data[0].goal);

                  if (YearValue == "2025") {
                    _data_Kr10(531);

                    xcolorchk = 531;
                  } else if (YearValue == "2024") {
                    _data_Kr10(556);

                    xcolorchk = 556;
                  } else {
                    _data_Kr10(result.data[0].room);

                    xcolorchk = result.data[0].room;
                  }

                  if (
                    parseFloat(xcolorchk) >= parseFloat(result2.data[0].goal)
                  ) {
                    _Num_Color2("green");
                  } else {
                    _Num_Color2("red");
                  }
                } else if (order == "HE3") {
                  _goal_Kr11(result2.data[0].goal);
                  _data_Kr11(0);

                  if (
                    parseFloat(result2.data[0].pubdata) >=
                    parseFloat(result2.data[0].goal)
                  ) {
                    _Num_Color3("green");
                  } else {
                    _Num_Color3("red");
                  }
                }
              }
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_barData = (order, YearValue) => {
    axios
      .post("https://www2.ntaqhi.info/api/Cleanroom_Research", {
        yearroom: YearValue
      })
      .then(result => {
        let Tb1 = [];
        if (result.data[0]) {
          if (order == "HE1") {
            if (YearValue == "2025") {
              Tb1.push({
                name: "Data",
                room: 531,
                teacher: 790,
                children: 7615
              });
            } else if (YearValue == "2024") {
              Tb1.push({
                name: "Data",
                room: 556,
                teacher: 947,
                children: 9755
              });
            } else {
              Tb1.push({
                name: "Data",
                room: result.data[0].room,
                children: result.data[0].children
              });
            }

            _dataBar1(Tb1);
          }
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <div>
        <h1 className="rise">HE</h1>
      </div>

      <Row>
        <div>
          <Form.Item label="Year" name="slyear">
            <Select
              defaultValue={YearNow}
              style={{
                width: 250,
                marginBottom: "20px"
              }}
              onChange={handleChange}
            >
              {xYeardh.map((i, idx) => {
                return (
                  <Option value={i.year}>
                    {i.year} ({i.name})
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div style={{ marginLeft: "25px" }}>
          <Button
            type="primary"
            onClick={() => {
              form1.resetFields();

              findGoal();

              setModalOpen(true);
            }}
          >
            Edit Goal
          </Button>
        </div>
      </Row>

      <Row gutter={3}>
        <Col xs={23} sm={12} md={12} lg={8} xl={8}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR9 Total of Service Recipt.
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: Num_Color1
                    }}
                  >
                    {data_Kr9}
                  </p>
                </Row>
              </Col>

              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_Kr9}
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={8}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR10 Total of Dust-free Rooms
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: Num_Color2
                    }}
                  >
                    {data_Kr10}
                  </p>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_Kr10}
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={8}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR11 TQA Score
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: Num_Color3
                    }}
                  >
                    {data_Kr11}
                  </p>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_Kr11}
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <div style={{ height: "10px" }} />

      <Row gutter={16}>
        <Col xs={23} sm={23} md={12} lg={12} xl={12}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              Total of Service Recipt. & Dust-free Rooms
            </p>
            <Barchart dataBar={dataBar1} />
          </Card>
        </Col>
        <Col xs={23} sm={23} md={12} lg={12} xl={12}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              TQA Score
            </p>
            {/* <Barchart dataBar={dataBar1} /> */}
          </Card>
        </Col>
      </Row>

      <Modal
        title="Edit Goal"
        centered
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={null}
        width={1000}
      >
        <p style={{ fontWeight: "bold", fontSize: "18px" }}>
          Year {YearValue}
        </p>
        <hr className="style-two" style={{ marginBottom: "20px" }} />

        <Form form={form1} initialValues={{}}>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR9 Total of Service Recipt.</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr9">
                  <InputNumber
                    autoFocus
                    min={1}
                    max={99999}
                    maxLength="5"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR10 Total of Dust-free Rooms</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr10">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR11 TQA Score</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr11">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>

        <hr className="style-two" style={{ marginBottom: "20px" }} />

        <Row justify="center">
          <Space>
            <Button
              type="primary"
              onClick={() => {
                bSaveclick();
              }}
            >
              <SaveOutlined /> Save
            </Button>

            <Button
              type="danger"
              onClick={() => {
                bClearclick();
              }}
            >
              <ClearOutlined /> Reset
            </Button>
          </Space>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default Dashboard_he;
