import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Layout,
  Table,
  Card,
  Divider,
  Tabs,
  Input,
  Space,
  BackTop,
  PageHeader,
  Spin,
  Select,
  Typography,
  Modal
} from "antd";

import Button from "antd-button-color";

import { createGlobalStyle } from "styled-components";

import {
  CalendarOutlined,
  TeamOutlined,
  SearchOutlined,
  FileTextOutlined,
  SolutionOutlined,
  SplitCellsOutlined,
  InteractionOutlined,
  FileExcelOutlined,
  PartitionOutlined,
  FundOutlined,
  CheckOutlined
} from "@ant-design/icons";

import moment from "moment";
//import "moment/locale/th";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import * as xlsx from "xlsx";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_scopus = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState([]);
  const [dataSource2, _dataSource2] = useState([]);
  const [dataSource3, _dataSource3] = useState([]);
  const [dataSource4, _dataSource4] = useState([]);
  const [dataSource5, _dataSource5] = useState([]);
  const [dataSource6, _dataSource6] = useState([]);
  const [dataSource7, _dataSource7] = useState([]);
  const [dataSource8, _dataSource8] = useState([]);

  const [dataSourceResearch, _dataSourceResearch] = useState([]);

  const [columns5, _column5] = useState([]);
  const [columns8, _column8] = useState([]);

  const [Sum_All, _Sum_All] = useState(0);
  const [Sum_Q1, _Sum_Q1] = useState(0);
  const [Sum_Q2, _Sum_Q2] = useState(0);
  const [Sum_Q3, _Sum_Q3] = useState(0);
  const [Sum_Q4, _Sum_Q4] = useState(0);
  const [Sum_NA, _Sum_NA] = useState(0);

  const [Sum_All_Been, _Sum_All_Been] = useState(0);
  const [Sum_Q1_Been, _Sum_Q1_Been] = useState(0);
  const [Sum_Q2_Been, _Sum_Q2_Been] = useState(0);
  const [Sum_Q3_Been, _Sum_Q3_Been] = useState(0);
  const [Sum_Q4_Been, _Sum_Q4_Been] = useState(0);
  const [Sum_NA_Been, _Sum_NA_Been] = useState(0);
  const [Sum_All_Been2, _Sum_All_Been2] = useState(0);

  const [loading, _loading] = useState(true);
  const [loading1, _loading1] = useState(true);

  const DatePicker = generatePicker(dayjsGenerateConfig);

  const [xValues, _xValues] = useState(5);

  const [keyTab, _keyTab] = useState("1");

  const [dataYear, _dataYear] = useState([]);

  const [xYearpapers, _xYearpapers] = useState([]);
  const [YearValue, _YearValue] = useState("all");
  const [YearValue2, _YearValue2] = useState("all");

  const [levelU, _levelU] = useState("");
  const [r_id, _r_id] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [xtitle, _xtitle] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const xresearch_id = localStorage.getItem("research_id");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");
          //count year papers

          axios
            .get(xURL + "year_papers")
            .then(result => {
              //console.log(result.data);

              _xYearpapers(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
          // console.log(xresearch_id);
          axios
            .post(xURL + "findlevel", { research_id: xresearch_id })
            .then(result => {
              if (result.data.length > 0) {
                if (result.data[0].user_level_id == 4) {
                  _levelU("research");
                } else {
                  _levelU("");
                }
              } else {
                _levelU("");
              }

              GetTable();
              GetTable2();
              /////////////////////////
              GetTable3();
              GetTable4();
              GetTable5();
              GetTable6();
              /////////////////////////
              GetTable7();
              GetTable8();
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetTable = () => {
    axios
      .get(xURL + "find_scopus")
      .then(result => {
        //console.log(result.data);
        Show_scopus(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable2 = () => {
    axios
      .post(xURL + "find_scopus2", {
        year: YearValue2
      })
      .then(result => {
        //console.log(result.data);
        Show_scopus2(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  ///////////////////////////////////////////////////////////////////////////////
  const GetTable3 = () => {
    axios
      .get(xURL + "find_scopus3")
      .then(result => {
        //console.log(result.data);
        Show_scopus3(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable4 = () => {
    axios
      .post(xURL + "find_scopus4", {
        year: YearValue
      })
      .then(result => {
        //console.log(result.data);
        Show_scopus4(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable5 = () => {
    getcolumn5(5);
  };

  const getcolumn5 = num => {
    axios
      .post(xURL + "find_scopus_cyear", {
        ldata: num
      })
      .then(result => {
        let cl = [],
          syear = [];
        let xyear = "";

        cl.push({
          title: "รายการข้อมูล",
          dataIndex: "listdata",
          key: "listdata",
          render: (text, record) => {
            return {
              props: {
                style: {
                  background: record.listdata == "รวมทั้งหมด" ? "#b5f5ec" : ""
                }
              },
              children: (
                <div>
                  {text}
                </div>
              )
            };
          }
        });

        result.data.forEach((i, idx) => {
          xyear = dayjs("01/01/" + i.year).format("BBBB");

          cl.push({
            title: xyear,
            dataIndex: "year" + idx,
            key: "year" + idx,
            align: "center",
            render: (text, record) => {
              return {
                props: {
                  style: {
                    background: record.listdata == "รวมทั้งหมด" ? "#b5f5ec" : ""
                  }
                },
                children: (
                  <a
                    onClick={e => {
                      if (idx == 0) {
                        //console.log(cl[idx+1].title)

                        Showlist(
                          cl[idx + 1].title,
                          record.listdata + " " + record.id,
                          record.year0,
                          ""
                        );
                      } else if (idx == 1) {
                        Showlist(
                          cl[idx + 1].title,
                          record.listdata + " " + record.id,
                          record.year1,
                          ""
                        );
                      } else if (idx == 2) {
                        Showlist(
                          cl[idx + 1].title,
                          record.listdata + " " + record.id,
                          record.year2,
                          ""
                        );
                      } else if (idx == 3) {
                        Showlist(
                          cl[idx + 1].title,
                          record.listdata + " " + record.id,
                          record.year3,
                          ""
                        );
                      } else if (idx == 4) {
                        Showlist(
                          cl[idx + 1].title,
                          record.listdata + " " + record.id,
                          record.year4,
                          ""
                        );
                      } else if (idx == 5) {
                        Showlist(
                          cl[idx + 1].title,
                          record.listdata + " " + record.id,
                          record.year5,
                          ""
                        );
                      } else if (idx == 6) {
                        Showlist(
                          cl[idx + 1].title,
                          record.listdata + " " + record.id,
                          record.year6,
                          ""
                        );
                      } else if (idx == 7) {
                        Showlist(
                          cl[idx + 1].title,
                          record.listdata + " " + record.id,
                          record.year7,
                          ""
                        );
                      } else if (idx == 8) {
                        Showlist(
                          cl[idx + 1].title,
                          record.listdata + " " + record.id,
                          record.year8,
                          ""
                        );
                      } else if (idx == 9) {
                        Showlist(
                          cl[idx + 1].title,
                          record.listdata + " " + record.id,
                          record.year9,
                          ""
                        );
                      }
                    }}
                  >
                    {text}
                  </a>
                )
              };
            }
          });

          syear.push({
            year: i.year
          });

          _dataYear(syear);
        });

        //console.log(cl);
        _column5(cl);

        /////////////////////////////////////////////////
        axios
          .post(xURL + "find_scopus5", {
            year: syear
          })
          .then(result => {
            //console.log(result.data);
            Show_scopus5(result.data, num);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable6 = () => {
    axios
      .get(xURL + "find_scopus6")
      .then(result => {
        // console.log(result.data);
        Show_scopus6(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable7 = () => {
    axios
      .get(xURL + "find_scopus7")
      .then(result => {
        // console.log(result.data);
        Show_scopus7(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable8 = () => {
    axios
      .get(xURL + "find_pub_year")
      .then(result => {
        let cl = [],
          syear = [];
        let xyear = "";

        cl.push({
          title: "ชื่อนักวิจัย",
          dataIndex: "researchname",
          key: "researchname",
          render: (text, record) => {
            return {
              props: {
                style: {
                  background:
                    record.researchname == "จำนวนนักวิจัย" ? "#b5f5ec" : ""
                }
              },
              children: (
                <div>
                  {text}
                </div>
              )
            };
          }
        });

        result.data.forEach((i, idx) => {
          cl.push({
            title: i.year,
            dataIndex: "year" + idx,
            key: "year" + idx,
            align: "center",
            width: 130,
            render: (text, record) => {
              if (text == "1") {
                return {
                  props: {
                    style: {
                      background:
                        record.researchname == "จำนวนนักวิจัย" ? "#b5f5ec" : ""
                    }
                  },
                  children: (
                    <div>
                      <CheckOutlined />
                    </div>
                  )
                };
              } else if (text == "0") {
                return {
                  props: {
                    style: {
                      background:
                        record.researchname == "จำนวนนักวิจัย" ? "#b5f5ec" : ""
                    }
                  },
                  children: <div />
                };
              } else {
                return {
                  props: {
                    style: {
                      background:
                        record.researchname == "จำนวนนักวิจัย" ? "#b5f5ec" : ""
                    }
                  },
                  children: (
                    <div>
                      {text}
                    </div>
                  )
                };
              }
            }
          });

          syear.push({
            year: i.year
          });
        });

        _column8(cl);

        /////////////////////////////////////////////////
        axios
          .post(xURL + "find_scopus8", {
            year: syear
          })
          .then(result => {
            //console.log(result.data);
            Show_scopus8(result.data);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Show_scopus = zdata => {
    const Tb1 = [];
    let xsumAll = 0,
      xsumQ1 = 0,
      xsumQ2 = 0,
      xsumQ3 = 0,
      xsumQ4 = 0,
      xsumNA = 0;

    zdata.forEach((i, idx) => {
      let xsdate = moment(i.allpp, "YYYY").add(543, "year").format("L");

      xsdate = moment(xsdate).format("YYYY");

      Tb1.push({
        key: idx,
        pp_year: xsdate,
        pp_all: i.C_allpp,
        q1: i.C_q1pp,
        q2: i.C_q2pp,
        q3: i.C_q3pp,
        q4: i.C_q4pp,
        q_other: i.C_napp,
        q_research: i.C_research,
        q_research2: i.C_research2,
        ppf: i.ppf
      });

      xsumAll = xsumAll + i.C_allpp;
      xsumQ1 = xsumQ1 + i.C_q1pp;
      xsumQ2 = xsumQ2 + i.C_q2pp;
      xsumQ3 = xsumQ3 + i.C_q3pp;
      xsumQ4 = xsumQ4 + i.C_q4pp;
      xsumNA = xsumNA + i.C_napp;
    });

    _dataSource1(Tb1);

    _Sum_All(xsumAll);
    _Sum_Q1(xsumQ1);
    _Sum_Q2(xsumQ2);
    _Sum_Q3(xsumQ3);
    _Sum_Q4(xsumQ4);
    _Sum_NA(xsumNA);

    _loading1(false);
  };

  const Show_scopus2 = zdata => {
    const Tb1 = [];

    zdata.forEach((i, idx) => {
      Tb1.push({
        key: idx,
        num: idx + 1,
        research: i.research,
        pp_all: i.C_allpp,
        pp_first: i.C_firstpp,
        q1: i.C_q1pp,
        q2: i.C_q2pp,
        q3: i.C_q3pp,
        q4: i.C_q4pp,
        q_other: i.C_napp,
        h_index: i.h_index_link
      });
    });

    _dataSource2(Tb1);
  };

  const Show_scopus3 = zdata => {
    const Tb1 = [];

    zdata.forEach((i, idx) => {
      let xmin = 0,
        xmax = 0;

      let xcitation = 0;

      if (i.Min_if) {
        xmin = i.Min_if;
      }

      if (i.Max_if) {
        xmax = i.Max_if;
      }

      if (i.citation > 0) {
        xcitation = i.citation;
      }

      Tb1.push({
        key: idx,
        num: idx + 1,
        researcher: i.research,
        hi_index: i.h_index,
        count: i.C_allpp,
        impact_factor:
          parseFloat(i.Sum_if / i.C_allpp).toFixed(3) +
          " (" +
          xmin +
          "-" +
          xmax +
          ")",
        fc_author: i.C_firstpp,
        co_author: i.C_copp,
        pfc_author: parseFloat(i.C_firstpp / i.C_allpp * 100).toFixed(2),
        q1: i.C_q1pp,
        pq1: parseFloat(i.C_q1pp / i.C_allpp * 100).toFixed(2),
        q2: i.C_q2pp,
        pq1q2: parseFloat((i.C_q1pp + i.C_q2pp) / i.C_allpp * 100).toFixed(2),
        q3: i.C_q3pp,
        q4: i.C_q4pp,
        noq: i.C_napp,
        citation: parseInt(xcitation).toLocaleString(),
        h_index_link: i.h_index_link
      });

      _loading(false);
    });

    _dataSource3(Tb1);
  };

  const Show_scopus4 = zdata => {
    //console.log(zdata);
    const Tb1 = [];

    zdata.forEach((i, idx) => {
      Tb1.push({
        key: idx,
        departs: i.name,
        count: i.C_allpp,
        if: parseFloat(i.Sum_if / i.C_allpp).toFixed(3),
        count2: i.C_firstpp,

        percent: parseFloat(i.C_q1pp / i.C_allpp * 100).toFixed(2),
        count3: i.C_q1pp,

        percent2: parseFloat(i.C_q2pp / i.C_allpp * 100).toFixed(2),
        count4: i.C_q2pp,

        q1q2: (parseFloat(i.C_q1pp / i.C_allpp * 100) +
          parseFloat(i.C_q2pp / i.C_allpp * 100)).toFixed(2),

        q3: i.C_q3pp,
        q4: i.C_q4pp,
        depart_id: i.id
      });
    });

    _dataSource4(Tb1);
  };

  const Show_scopus5 = (zdata, num) => {
    const Tb1 = [];

    let xsum1 = 0,
      xsum2 = 0,
      xsum3 = 0,
      xsum4 = 0,
      xsum5 = 0,
      xsum6 = 0,
      xsum7 = 0,
      xsum8 = 0,
      xsum9 = 0,
      xsum10 = 0;

    if (num == 5) {
      zdata.forEach((i, idx) => {
        Tb1.push({
          key: idx,
          listdata:
            "จำนวนผลงานตีพิมพ์ใน SCOPUS (ด้าน " + i.pub_type_short + ")",
          id: i.paper_type_cmu_id,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4
        });

        xsum1 = xsum1 + i.year0;
        xsum2 = xsum2 + i.year1;
        xsum3 = xsum3 + i.year2;
        xsum4 = xsum4 + i.year3;
        xsum5 = xsum5 + i.year4;
      });

      Tb1.push({
        key: 10,
        listdata: "รวมทั้งหมด",
        id: 0,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5
      });
    } else if (num == 10) {
      zdata.forEach((i, idx) => {
        Tb1.push({
          key: idx,
          listdata:
            "จำนวนผลงานตีพิมพ์ใน SCOPUS (ด้าน " + i.pub_type_short + ")",
          id: i.paper_type_cmu_id,
          year0: i.year0,
          year1: i.year1,
          year2: i.year2,
          year3: i.year3,
          year4: i.year4,
          year5: i.year5,
          year6: i.year6,
          year7: i.year7,
          year8: i.year8,
          year9: i.year9
        });

        xsum1 = xsum1 + i.year0;
        xsum2 = xsum2 + i.year1;
        xsum3 = xsum3 + i.year2;
        xsum4 = xsum4 + i.year3;
        xsum5 = xsum5 + i.year4;
        xsum6 = xsum6 + i.year5;
        xsum7 = xsum7 + i.year6;
        xsum8 = xsum8 + i.year7;
        xsum9 = xsum9 + i.year8;
        xsum10 = xsum10 + i.year9;
      });

      Tb1.push({
        key: 10,
        listdata: "รวมทั้งหมด",
        id: 0,
        year0: xsum1,
        year1: xsum2,
        year2: xsum3,
        year3: xsum4,
        year4: xsum5,
        year5: xsum6,
        year6: xsum7,
        year7: xsum8,
        year8: xsum9,
        year9: xsum10
      });
    }

    _dataSource5(Tb1);
  };

  const Show_scopus6 = zdata => {
    //console.log(zdata);
    const Tb1 = [];

    zdata.forEach((i, idx) => {
      let xsupport_amount1 = "",
        xsupport_amount2 = "",
        xsupport_amount3 = "",
        xsupport_amount4 = "",
        xsupport_amount5 = "",
        xsupport_amount6 = "",
        xsupport_amount7 = "",
        xsumsa89 = "";

      if (i.support_amount1 != null) {
        xsupport_amount1 = parseInt(i.support_amount1).toLocaleString();
      }
      if (i.support_amount2 != null) {
        xsupport_amount2 = parseInt(i.support_amount2).toLocaleString();
      }
      if (i.support_amount3 != null) {
        xsupport_amount3 = parseInt(i.support_amount3).toLocaleString();
      }
      if (i.support_amount4 != null) {
        xsupport_amount4 = parseInt(i.support_amount4).toLocaleString();
      }
      if (i.support_amount5 != null) {
        xsupport_amount5 = parseInt(i.support_amount5).toLocaleString();
      }
      if (i.support_amount6 != null) {
        xsupport_amount6 = parseInt(i.support_amount6).toLocaleString();
      }
      if (i.support_amount7 != null) {
        xsupport_amount7 = parseInt(i.support_amount7).toLocaleString();
      }
      if (
        parseInt(i.support_amount8 + i.support_amount9).toLocaleString() != 0
      ) {
        xsumsa89 = parseInt(
          i.support_amount8 + i.support_amount9
        ).toLocaleString();
      }

      Tb1.push({
        key: idx,
        num: idx + 1,
        title: i.title,
        journal_name: i.journal_name,
        q_sjr: i.sjr_q,
        research: i.research,

        ppts_11: (
          <Text style={{ fontWeight: "bold" }}>
            {xsupport_amount1}
          </Text>
        ),
        ppts_12: (
          <Text style={{ fontWeight: "bold" }}>
            {xsupport_amount2}
          </Text>
        ),
        ppts_13: (
          <Text style={{ fontWeight: "bold" }}>
            {xsupport_amount3}
          </Text>
        ),
        ppts_21: (
          <Text style={{ fontWeight: "bold" }}>
            {xsupport_amount4}
          </Text>
        ),
        page_charge: (
          <Text style={{ fontWeight: "bold" }}>
            {xsupport_amount5}
          </Text>
        ),
        topten: (
          <Text style={{ fontWeight: "bold" }}>
            {xsupport_amount6}
          </Text>
        ),
        nsl: (
          <Text style={{ fontWeight: "bold" }}>
            {xsupport_amount7}
          </Text>
        ),
        ppts_31_32: (
          <Text style={{ fontWeight: "bold" }}>
            {xsumsa89}
          </Text>
        ),
        link: i.link
      });
    });

    _dataSource6(Tb1);
  };

  const Show_scopus7 = zdata => {
    const Tb1 = [];
    let xsumAll = 0,
      xsumQ1 = 0,
      xsumQ2 = 0,
      xsumQ3 = 0,
      xsumQ4 = 0,
      xsumNA = 0,
      xsumAll2 = 0;

    zdata.forEach((i, idx) => {
      let xsdate = moment(i.allpp, "YYYY").add(543, "year").format("L");

      xsdate = moment(xsdate).format("YYYY");

      Tb1.push({
        key: idx,
        pp_year: xsdate,
        pp_all: i.C_allpp,
        q1: i.C_q1pp,
        q2: i.C_q2pp,
        q3: i.C_q3pp,
        q4: i.C_q4pp,
        q_other: i.C_napp,
        all_paper: i.allpp2,
        percent_been: i.perBeen
      });

      xsumAll = xsumAll + i.C_allpp;
      xsumQ1 = xsumQ1 + i.C_q1pp;
      xsumQ2 = xsumQ2 + i.C_q2pp;
      xsumQ3 = xsumQ3 + i.C_q3pp;
      xsumQ4 = xsumQ4 + i.C_q4pp;
      xsumNA = xsumNA + i.C_napp;
      xsumAll2 = xsumAll2 + i.allpp2;
    });

    _dataSource7(Tb1);

    _Sum_All_Been(xsumAll);
    _Sum_Q1_Been(xsumQ1);
    _Sum_Q2_Been(xsumQ2);
    _Sum_Q3_Been(xsumQ3);
    _Sum_Q4_Been(xsumQ4);
    _Sum_NA_Been(xsumNA);
    _Sum_All_Been2(xsumAll2);
  };

  const Show_scopus8 = zdata => {
    const Tb1 = [];
    let xidx = 0;

    let xsum1 = 0,
      xsum2 = 0,
      xsum3 = 0,
      xsum4 = 0,
      xsum5 = 0,
      xsum6 = 0,
      xsum7 = 0,
      xsum8 = 0,
      xsum9 = 0,
      xsum10 = 0;

    zdata.forEach((i, idx) => {
      Tb1.push({
        key: idx,
        researchname: i.researchname,
        year0: i.year0,
        year1: i.year1,
        year2: i.year2,
        year3: i.year3,
        year4: i.year4,
        year5: i.year5,
        year6: i.year6,
        year7: i.year7,
        year8: i.year8
      });

      xsum1 = xsum1 + i.year0;
      xsum2 = xsum2 + i.year1;
      xsum3 = xsum3 + i.year2;
      xsum4 = xsum4 + i.year3;
      xsum5 = xsum5 + i.year4;
      xsum6 = xsum6 + i.year5;
      xsum7 = xsum7 + i.year6;
      xsum8 = xsum8 + i.year7;
      xsum9 = xsum9 + i.year8;

      xidx = xidx + 1;
    });

    Tb1.push({
      key: xidx,
      researchname: "จำนวนนักวิจัย",
      year0: xsum1,
      year1: xsum2,
      year2: xsum3,
      year3: xsum4,
      year4: xsum5,
      year5: xsum6,
      year6: xsum7,
      year7: xsum8,
      year8: xsum9
    });

    // console.log(Tb1);

    _dataSource8(Tb1);
  };

  const Showlist = (zyear, zhead, zcount, ztype) => {
    //console.log(zyear, zhead, zcount);

    if (zcount > 0) {
      window.open(
        "/Research_scopus_list?year=" +
          zyear +
          "&head=" +
          zhead +
          "&count=" +
          zcount +
          "&type=" +
          ztype,
        "_blank"
      );
    } else {
      swal("แจ้งเตือน!", "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!", "warning");
    }
  };

  const Showlist2 = (zresearch, zhead, zcount) => {
    //console.log(zresearch, zhead, zcount);

    if (zcount > 0) {
      window.open(
        "/Research_scopus_list2?research=" +
          zresearch +
          "&head=" +
          zhead +
          "&count=" +
          zcount,
        "_blank"
      );
    } else {
      swal("แจ้งเตือน!", "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!", "warning");
    }
  };

  const ShowResearch = (zyear, zorder) => {
    _xtitle("นักวิจัยที่ตีพิมพ์ ปี " + zyear);

    let year1 = parseInt(zyear) - 543;

    axios
      .post(xURL + "find_research_scopus", { year: year1, order: zorder })
      .then(result => {
        // console.log(result.data);

        const Tb1 = [];

        result.data.forEach((i, idx) => {
          Tb1.push({
            key: idx,
            num: idx + 1,
            research: i.researchname
          });
        });

        _dataSourceResearch(Tb1);

        setIsModalVisible(true);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bExcelClick = () => {
    // console.log(keyTab);

    axios
      .post(xURL + "scopusEx", {
        keys: keyTab,
        c2: YearValue2,
        c4: YearValue,
        c5: dataYear
      })
      .then(result => {
        let xdata = [],
          xtabtitle = "",
          header = [],
          header2 = [];

        if (keyTab == "1") {
          xtabtitle = "ปีปฏิทิน";

          header.push(
            "รายการข้อมูล",
            "ทั้งหมด",
            "Q1",
            "Q2",
            "Q3",
            "Q4",
            "ไม่ระบุ",
            "จำนวนนักวิจัยที่ตีพิมพ์"
          );
        } else if (keyTab == "2") {
          xtabtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS";

          header.push(
            "ชื่อนักวิจัย",
            "จำนวนผลงานตีพิมพ์",
            "จำนวนผลงานที่เป็น First/Corresponding Author",
            "Q1",
            "Q2",
            "Q3",
            "Q4",
            "ไม่มี Q"
          );
        } else if (keyTab == "3") {
          xtabtitle = "สรุปผลงานนักวิจัย";

          header.push(
            "Researcher",
            "Hi-index",
            "จำนวน",
            "Impact factor, range",
            "First/Corresponding author",
            "Co-author",
            "%First/Corresponding author",
            "Q1",
            "%Q1",
            "Q2",
            "%Q1+Q2",
            "Q3",
            "Q4",
            "ไม่มี Q",
            "Citation"
          );
        } else if (keyTab == "4") {
          xtabtitle = "จำนวนผลงานตีพิมพ์ใน SCOPUS";

          header.push(
            "ศูนย์วิจัย",
            "จำนวน",
            "IF",
            "First/corresponding author",
            "Q1",
            "",
            "Q2",
            "",
            "%Q1+Q2",
            "Q3",
            "Q4"
          );

          header2.push("", "", "", "", "%", "จำนวน", "%", "จำนวน", "", "", "");
        } else if (keyTab == "5") {
          xtabtitle = "ทิศทางการวิจัย";
        } else if (keyTab == "6") {
          xtabtitle = "การสนับสนุนผลงานวิจัยตีพิมพ์";

          header.push(
            "",
            "",
            "",
            "",
            "สถาบันวิจัยวิทยาศาสตร์สุขภาพ",
            "",
            "",
            "มหาวิทยาลัยเชียงใหม่",
            "",
            "",
            "",
            "คณะแพทย์ มช."
          );

          header2.push(
            "เรื่อง",
            "วารสาร",
            "Q(SJR)",
            "นักวิจัย (Fist/Corresponding/Co-Corresponding)",
            "ค่าตอบแทน",
            "วงเงิน",
            "อื่นๆ",
            "ค่าตอบแทน",
            "Page Charge",
            "Top 1-10",
            "Nature, Sciences, Lancetฯ",
            "ค่าตอบแทน"
          );
        } else if (keyTab == "8") {
          xtabtitle = "BEEN";

          header.push(
            "รายการข้อมูล",
            "ทั้งหมด",
            "Q1",
            "Q2",
            "Q3",
            "Q4",
            "ไม่ระบุ",
            "ผลงานตีพิมพ์ทั้งหมด",
            "ร้อยละ BEEN"
          );
        }

        if (keyTab == "4") {
          xdata.push([xtabtitle]);

          if (YearValue != "all") {
            xdata.push([
              "แยกตามศูนย์วิจัย ปี พ.ศ. " +
                parseInt(YearValue + 543) +
                " (1 มกราคม " +
                parseInt(YearValue + 543) +
                " - 31 ธันวาคม " +
                parseInt(YearValue + 543) +
                ")"
            ]);
          } else {
            xdata.push(["แยกตามศูนย์วิจัย ปี พ.ศ. ทั้งหมด"]);
          }
        } else if (keyTab == "2") {
          xdata.push([xtabtitle]);

          if (YearValue2 != "all") {
            xdata.push([
              "แยกตามนักวิจัย ปี พ.ศ. " +
                parseInt(YearValue2 + 543) +
                " (1 มกราคม " +
                parseInt(YearValue2 + 543) +
                " - 31 ธันวาคม " +
                parseInt(YearValue2 + 543) +
                ")"
            ]);
          } else {
            xdata.push(["แยกตามนักวิจัย ปี พ.ศ. ทั้งหมด"]);
          }
        } else {
          xdata.push(["SCOPUS (" + xtabtitle + ")"]);
        }

        xdata.push([""]);

        if (keyTab != "5") {
          xdata.push(header);
        } else {
          let tb = [],
            xyear = "";

          tb.push("รายการข้อมูล");
          dataYear.forEach((i, idx) => {
            xyear = dayjs("01/01/" + i.year).format("BBBB");

            tb.push(xyear);
          });

          xdata.push(tb);
        }

        if (keyTab == "4" || keyTab == "6") {
          xdata.push(header2);
        }

        for (var row in result.data) {
          const xx = [];

          for (var col in result.data[row]) {
            xx.push(result.data[row][col]);
          }

          xdata.push(xx);
        }

        if (keyTab == "1" || keyTab == "5" || keyTab == "8") {
          axios
            .post(xURL + "sumscopusEx", { keys: keyTab, c5: dataYear })
            .then(result2 => {
              for (var row in result2.data) {
                const yy = [];
                for (var col in result2.data[row]) {
                  yy.push(result2.data[row][col]);
                }
                xdata.push(yy);
              }
              var wb = xlsx.utils.book_new(),
                ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

              xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
              xlsx.writeFile(wb, "Excel.xlsx");
            });
        } else {
          var wb = xlsx.utils.book_new(),
            ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

          xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
          xlsx.writeFile(wb, "Excel.xlsx");
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const columns1 = [
    {
      title: "รายการข้อมูล",
      dataIndex: "pp_year",
      key: "pp_year",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "All", record.pp_all, "");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_year - b.pp_year
    },
    {
      title: "ทั้งหมด",
      dataIndex: "pp_all",
      key: "pp_all",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "All", record.pp_all, "");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_all - b.pp_all
    },
    {
      title: "Q1",
      dataIndex: "q1",
      key: "q1",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q1", record.q1, "");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q1 - b.q1
    },
    {
      title: "Q2",
      dataIndex: "q2",
      key: "q2",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q2", record.q2, "");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q2 - b.q2
    },
    {
      title: "Q3",
      dataIndex: "q3",
      key: "q3",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q3", record.q3, "");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q3 - b.q3
    },
    {
      title: "Q4",
      dataIndex: "q4",
      key: "q4",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q4", record.q4, "");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q4 - b.q4
    },
    {
      title: "ไม่ระบุ",
      dataIndex: "q_other",
      key: "q_other",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "N/A", record.q_other, "");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q_other - b.q_other
    },
    {
      title: "จำนวนนักวิจัย",
      dataIndex: "q_research",
      key: "q_research",
      align: "center",
      width: 150,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                ShowResearch(record.pp_year, 1);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q_research - b.q_research
    },
    {
      title: "จำนวนนักวิจัยที่ตีพิมพ์",
      dataIndex: "q_research2",
      key: "q_research2",
      align: "center",
      width: 150,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                ShowResearch(record.pp_year, 2);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q_research2 - b.q_research2
    },
    {
      title: "Pub Per Faculty",
      dataIndex: "ppf",
      key: "ppf",
      align: "center",
      width: 150,
      render(text, record, index) {
        return {
          children: text
        };
      },
      sorter: (a, b) => a.ppf - b.ppf
    }
  ];

  const columns2 = [
    {
      title: "No.",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50,
      sorter: (a, b) => a.num - b.num,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "ชื่อนักวิจัย",
      dataIndex: "research",
      key: "research",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                // Showlist2(record.research, "All", record.pp_all);
                window.open(record.h_index, "_blank");
              }}
            >
              {text}
            </a>
          )
        };
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              allowClear
              placeholder="ค้นหา"
              value={selectedKeys[0]}
              onChange={e => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              style={{ marginBottom: 5 }}
            >
              {}
            </Input>

            <Space>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  confirm();
                }}
              >
                ค้นหา
              </Button>
              <Button
                type="danger"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  clearFilters();
                }}
              >
                เคลียร์
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.research.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.num - b.num
    },
    {
      title: "จำนวนผลงานตีพิมพ์",
      dataIndex: "pp_all",
      key: "pp_all",
      align: "center",
      width: 150,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "All", record.pp_all);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_all - b.pp_all
    },
    {
      title: "จำนวนผลงานที่เป็น First/Corresponding Author",
      dataIndex: "pp_first",
      key: "pp_first",
      align: "center",
      width: 310,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "First", record.pp_first);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_first - b.pp_first
    },
    {
      title: "Q1",
      dataIndex: "q1",
      key: "q1",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q1", record.q1);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q1 - b.q1
    },
    {
      title: "Q2",
      dataIndex: "q2",
      key: "q2",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q2", record.q2);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q2 - b.q2
    },
    {
      title: "Q3",
      dataIndex: "q3",
      key: "q3",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q3", record.q3);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q3 - b.q3
    },
    {
      title: "Q4",
      dataIndex: "q4",
      key: "q4",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "Q4", record.q4);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q4 - b.q4
    },
    {
      title: "ไม่มี Q",
      dataIndex: "q_other",
      key: "q_other",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.research, "N/A", record.q_other);
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q_other - b.q_other
    }
  ];

  const columns3 = [
    {
      title: "No.",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50,
      sorter: (a, b) => a.num - b.num,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "Researcher",
      dataIndex: "researcher",
      key: "researcher",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.researcher, "All", record.count);
              }}
            >
              {text}
            </a>
          )
        };
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              allowClear
              placeholder="ค้นหา"
              value={selectedKeys[0]}
              onChange={e => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              style={{ marginBottom: 5 }}
            >
              {}
            </Input>

            <Space>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  confirm();
                }}
              >
                ค้นหา
              </Button>
              <Button
                type="danger"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  clearFilters();
                }}
              >
                เคลียร์
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.researcher.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.num - b.num
    },
    {
      title: "Hi-index",
      dataIndex: "hi_index",
      key: "hi_index",
      align: "center",
      width: 100,
      sorter: (a, b) => a.hi_index - b.hi_index,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                window.open(record.h_index_link, "_blank");
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "จำนวน",
      dataIndex: "count",
      key: "count",
      align: "center",
      width: 100,
      sorter: (a, b) => a.count - b.count,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.researcher, "All", record.count);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "Impact factor, range",
      dataIndex: "impact_factor",
      key: "impact_factor",
      align: "center",
      width: 180,
      sorter: (a, b) => a.impact_factor - b.impact_factor
    },
    {
      title: "First/Corresponding author",
      dataIndex: "fc_author",
      key: "fc_author",
      align: "center",
      width: 80,
      sorter: (a, b) => a.fc_author - b.fc_author,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.researcher, "First", record.fc_author);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "Co-author",
      dataIndex: "co_author",
      key: "co_author",
      align: "center",
      width: 100,
      sorter: (a, b) => a.co_author - b.co_author,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.researcher, "Co", record.co_author);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "%First/Corresponding author",
      dataIndex: "pfc_author",
      key: "pfc_author",
      align: "center",
      width: 80,
      sorter: (a, b) => a.pfc_author - b.pfc_author
    },
    {
      title: "Q1",
      dataIndex: "q1",
      key: "q1",
      align: "center",
      width: 80,
      sorter: (a, b) => a.q1 - b.q1,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.researcher, "Q1", record.q1);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "%Q1",
      dataIndex: "pq1",
      key: "pq1",
      align: "center",
      width: 80,
      sorter: (a, b) => a.pq1 - b.pq1
    },
    {
      title: "Q2",
      dataIndex: "q2",
      key: "q2",
      align: "center",
      width: 80,
      sorter: (a, b) => a.q2 - b.q2,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.researcher, "Q2", record.q2);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "%Q1+Q2",
      dataIndex: "pq1q2",
      key: "pq1q2",
      align: "center",
      width: 80,
      sorter: (a, b) => a.pq1q2 - b.pq1q2
    },
    {
      title: "Q3",
      dataIndex: "q3",
      key: "q3",
      align: "center",
      width: 80,
      sorter: (a, b) => a.q3 - b.q3,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.researcher, "Q3", record.q3);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "Q4",
      dataIndex: "q4",
      key: "q4",
      align: "center",
      width: 80,
      sorter: (a, b) => a.q4 - b.q4,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.researcher, "Q4", record.q4);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "ไม่มี Q",
      dataIndex: "noq",
      key: "noq",
      align: "center",
      width: 80,
      sorter: (a, b) => a.noq - b.noq,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist2(record.researcher, "N/A", record.noq);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "Citation",
      dataIndex: "citation",
      key: "citation",
      align: "center",
      width: 80,
      sorter: (a, b) =>
        a.citation.replace(",", "") - b.citation.replace(",", "")
    }
  ];

  const columns4 = [
    {
      title: "ศูนย์วิจัย",
      dataIndex: "departs",
      key: "departs",
      sorter: (a, b) => {
        return a.departs.localeCompare(b.departs);
      },
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(
                  "All",
                  record.depart_id + " " + record.departs.trim() + " All",
                  record.count,
                  ""
                );
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "จำนวน",
      dataIndex: "count",
      key: "count",
      align: "center",
      width: 100,
      sorter: (a, b) => a.count - b.count,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(
                  "All",
                  record.depart_id + " " + record.departs + " All",
                  record.count,
                  ""
                );
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "IF",
      dataIndex: "if",
      key: "if",
      align: "center",
      width: 100,
      sorter: (a, b) => a.if - b.if
    },
    {
      title: "First/corresponding author",
      dataIndex: "count2",
      key: "count2",
      align: "center",
      width: 100,
      sorter: (a, b) => a.count2 - b.count2,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(
                  "All",
                  record.depart_id + " " + record.departs + " FC",
                  record.count2,
                  ""
                );
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "Q1",
      children: [
        {
          title: "%",
          dataIndex: "percent",
          key: "percent",
          align: "center",
          width: 150,
          sorter: (a, b) => a.percent - b.percent
        },
        {
          title: "จำนวน",
          dataIndex: "count3",
          key: "count3",
          align: "center",
          width: 150,
          sorter: (a, b) => a.count3 - b.count3,
          render(text, record, index) {
            return {
              children: (
                <a
                  onClick={e => {
                    //console.log("corresponding email is :", record.cmumis_id);
                    Showlist(
                      "All",
                      record.depart_id + " " + record.departs + " Q1",
                      record.count3,
                      ""
                    );
                  }}
                >
                  {text}
                </a>
              )
            };
          }
        }
      ]
    },
    {
      title: "Q2",
      children: [
        {
          title: "%",
          dataIndex: "percent2",
          key: "percent2",
          align: "center",
          width: 150,
          sorter: (a, b) => a.percent2 - b.percent2
        },
        {
          title: "จำนวน",
          dataIndex: "count4",
          key: "count4",
          align: "center",
          width: 150,
          sorter: (a, b) => a.count4 - b.count4,
          render(text, record, index) {
            return {
              children: (
                <a
                  onClick={e => {
                    //console.log("corresponding email is :", record.cmumis_id);
                    Showlist(
                      "All",
                      record.depart_id + " " + record.departs + " Q2",
                      record.count4,
                      ""
                    );
                  }}
                >
                  {text}
                </a>
              )
            };
          }
        }
      ]
    },
    {
      title: "%Q1+Q2",
      dataIndex: "q1q2",
      key: "q1q2",
      align: "center",
      width: 100,
      sorter: (a, b) => a.q1q2 - b.q1q2
    },
    {
      title: "Q3",
      dataIndex: "q3",
      key: "q3",
      align: "center",
      width: 100,
      sorter: (a, b) => a.q3 - b.q3,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(
                  "All",
                  record.depart_id + " " + record.departs + " Q3",
                  record.q3,
                  ""
                );
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "Q4",
      dataIndex: "q4",
      key: "q4",
      align: "center",
      width: 100,
      sorter: (a, b) => a.q4 - b.q4,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(
                  "All",
                  record.depart_id + " " + record.departs + " Q4",
                  record.q4,
                  ""
                );
              }}
            >
              {text}
            </a>
          )
        };
      }
    }
  ];

  const columns6 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50,
      sorter: (a, b) => a.num - b.num,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "เรื่อง",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.num - b.num,
      width: 450,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                window.open(record.link, "_blank");
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "วารสาร",
      dataIndex: "journal_name",
      key: "journal_name",
      width: 250,
      align: "center",
      sorter: (a, b) => {
        return a.journal_name.localeCompare(b.journal_name);
      }
    },
    {
      title: "Q(SJR)",
      dataIndex: "q_sjr",
      key: "q_sjr",
      align: "center",
      sorter: (a, b) => {
        return a.q_sjr.localeCompare(b.q_sjr);
      }
    },
    {
      title: "นักวิจัย (Fist/Corresponding/Co-Corresponding)",
      dataIndex: "research",
      key: "research",
      width: 200,
      align: "center",
      sorter: (a, b) => {
        return a.research.localeCompare(b.research);
      }
    },
    {
      title: "สถาบันวิจัยวิทยาศาสตร์สุขภาพ",
      align: "center",
      children: [
        {
          title: "ค่าตอบแทน",
          dataIndex: "ppts_11",
          key: "ppts_11",
          width: 80,
          align: "center"
        },
        {
          title: "วงเงิน",
          dataIndex: "ppts_12",
          key: "ppts_12",
          width: 80,
          align: "center"
        },
        {
          title: "อื่นๆ",
          dataIndex: "ppts_13",
          key: "ppts_13",
          width: 80,
          align: "center"
        }
      ]
    },
    {
      title: "มหาวิทยาลัยเชียงใหม่",
      align: "center",
      children: [
        {
          title: "ค่าตอบแทน",
          dataIndex: "ppts_21",
          key: "ppts_21",
          width: 80,
          align: "center"
        },
        {
          title: "Page Charge",
          dataIndex: "page_charge",
          key: "page_charge",
          width: 80,
          align: "center"
        },
        {
          title: "Top 1-10",
          dataIndex: "topten",
          key: "topten",
          width: 80,
          align: "center"
        },
        {
          title: "Nature, Sciences, Lancetฯ",
          dataIndex: "nsl",
          key: "nsl",
          width: 80,
          align: "center"
        }
      ]
    },
    {
      title: "คณะแพทย์ มช.",
      align: "center",
      children: [
        {
          title: "ค่าตอบแทน",
          dataIndex: "ppts_31_32",
          key: "ppts_31_32",
          width: 80,
          align: "center"
        }
      ]
    }
  ];

  const columns7 = [
    {
      title: "รายการข้อมูล",
      dataIndex: "pp_year",
      key: "pp_year",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "All", record.pp_all, "BEEN");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_year - b.pp_year
    },
    {
      title: "BEEN",
      dataIndex: "pp_all",
      key: "pp_all",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "All", record.pp_all, "BEEN");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.pp_all - b.pp_all
    },
    {
      title: "Q1",
      dataIndex: "q1",
      key: "q1",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q1", record.q1, "BEEN");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q1 - b.q1
    },
    {
      title: "Q2",
      dataIndex: "q2",
      key: "q2",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q2", record.q2, "BEEN");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q2 - b.q2
    },
    {
      title: "Q3",
      dataIndex: "q3",
      key: "q3",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q3", record.q3, "BEEN");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q3 - b.q3
    },
    {
      title: "Q4",
      dataIndex: "q4",
      key: "q4",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "Q4", record.q4, "BEEN");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q4 - b.q4
    },
    {
      title: "ไม่ระบุ",
      dataIndex: "q_other",
      key: "q_other",
      align: "center",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showlist(record.pp_year, "N/A", record.q_other, "BEEN");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => a.q_other - b.q_other
    },
    {
      title: "ผลงานตีพิมพ์ทั้งหมด",
      dataIndex: "all_paper",
      key: "all_paper",
      align: "center",
      width: 160,
      sorter: (a, b) => a.all_paper - b.all_paper
    },
    {
      title: "ร้อยละ BEEN",
      dataIndex: "percent_been",
      key: "percent_been",
      align: "center",
      width: 160,
      sorter: (a, b) => a.percent_been - b.percent_been
    }
  ];

  const columnsResearch = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 100,
      sorter: (a, b) => a.num - b.num
    },
    {
      title: "ชื่อนักวิจัย",
      dataIndex: "research",
      key: "research",
      sorter: (a, b) => {
        return a.research.localeCompare(b.research);
      }
    }
  ];

  const handleChange = value => {
    //console.log(`selected ${value}`);

    if (value == 0) {
      getcolumn5(5);
      _xValues(5);
    } else if (value == 1) {
      getcolumn5(10);
      _xValues(10);
    }
  };

  const handleChange2 = value => {
    //console.log(`selected ${value}`);

    _YearValue(value);

    axios
      .post(xURL + "find_scopus4", {
        year: value
      })
      .then(result => {
        //console.log(result.data);
        Show_scopus4(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const handleChange3 = value => {
    //console.log(`selected ${value}`);

    _YearValue2(value);

    axios
      .post(xURL + "find_scopus2", {
        year: value
      })
      .then(result => {
        //console.log(result.data);
        Show_scopus2(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const TabonChange = e => {
    //console.log(e);
    _keyTab(e);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader className="site-page-header" title="SCOPUS" />
            <Divider />

            <Card
              title={
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    color: "white"
                  }}
                >
                  จำนวนผลงานตีพิมพ์ใน SCOPUS
                </div>
              }
              headStyle={{
                backgroundColor: "#00474f"
              }}
              style={{
                //margin: "10px",
                borderRadius: "15px",
                overflow: "hidden"
              }}
            >
              <Row>
                <Space>
                  <Button
                    ghost
                    type="dark"
                    onClick={() => {
                      Showlist("All", "All", Sum_All, "");
                    }}
                  >
                    <FileTextOutlined /> ผลงานตีพิมพ์ทั้งหมด
                  </Button>

                  <Button ghost type="success" onClick={bExcelClick}>
                    <FileExcelOutlined /> Excel
                  </Button>

                  <Button
                    ghost
                    type="dark"
                    onClick={() => {
                      window.open("/Research_citation_count", "_blank");
                    }}
                  >
                    <FileTextOutlined /> Citation Count
                  </Button>
                </Space>
              </Row>

              <Divider />

              <Tabs defaultActiveKey="1" size={"small"} onChange={TabonChange}>
                <TabPane
                  tab={
                    <span>
                      <CalendarOutlined />
                      ปีปฏิทิน
                    </span>
                  }
                  key="1"
                >
                  <Spin tip="Loading..." spinning={loading1} size="large">
                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource1}
                      columns={columns1}
                      size="small"
                      pagination={false}
                      summary={() =>
                        <Table.Summary fixed>
                          <Table.Summary.Row
                            style={{
                              textAlign: "center",
                              backgroundColor: "#b5f5ec"
                            }}
                          >
                            <Table.Summary.Cell>รวมทั้งหมด</Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <a
                                onClick={() => {
                                  Showlist("All", "All", Sum_All, "");
                                }}
                              >
                                {Sum_All}
                              </a>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <a
                                onClick={() => {
                                  Showlist("All", "Q1", Sum_Q1, "");
                                }}
                              >
                                {Sum_Q1}
                              </a>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <a
                                onClick={() => {
                                  Showlist("All", "Q2", Sum_Q2, "");
                                }}
                              >
                                {Sum_Q2}
                              </a>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <a
                                onClick={() => {
                                  Showlist("All", "Q3", Sum_Q3, "");
                                }}
                              >
                                {Sum_Q3}
                              </a>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <a
                                onClick={() => {
                                  Showlist("All", "Q4", Sum_Q4, "");
                                }}
                              >
                                {Sum_Q4}
                              </a>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <a
                                onClick={() => {
                                  Showlist("All", "N/A", Sum_NA, "");
                                }}
                              >
                                {Sum_NA}
                              </a>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                          </Table.Summary.Row>
                        </Table.Summary>}
                    />
                  </Spin>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <TeamOutlined />
                      นักวิจัย
                    </span>
                  }
                  key="2"
                >
                  <Spin tip="Loading..." spinning={loading} size="large">
                    <Row>
                      <Space>
                        <p>แสดงตามปี :</p>
                        <Select
                          defaultValue="all"
                          style={{ width: 100 }}
                          onChange={handleChange3}
                        >
                          <Option value="all">ทั้งหมด</Option>

                          {xYearpapers.map((i, idx) => {
                            return (
                              <Option value={i.year}>
                                {i.year}
                              </Option>
                            );
                          })}
                        </Select>
                      </Space>
                    </Row>

                    <br />

                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource2}
                      columns={columns2}
                      size="small"
                      pagination={false}
                    />
                  </Spin>
                </TabPane>

                <TabPane
                  tab={
                    <span>
                      <SolutionOutlined />
                      สรุปผลงานนักวิจัย
                    </span>
                  }
                  key="3"
                >
                  <Spin tip="Loading..." spinning={loading} size="large">
                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource3}
                      columns={columns3}
                      size="small"
                      pagination={false}
                    />
                  </Spin>
                </TabPane>

                <TabPane
                  tab={
                    <span>
                      <SplitCellsOutlined />
                      สรุปผลงานตีพิมพ์แยกศูนย์วิจัย
                    </span>
                  }
                  key="4"
                >
                  <Row>
                    <Space>
                      <p>แสดงตามปี :</p>
                      <Select
                        defaultValue="all"
                        style={{ width: 100 }}
                        onChange={handleChange2}
                      >
                        <Option value="all">ทั้งหมด</Option>

                        {xYearpapers.map((i, idx) => {
                          return (
                            <Option value={i.year}>
                              {i.year}
                            </Option>
                          );
                        })}
                      </Select>
                    </Space>
                  </Row>

                  <br />

                  <Table
                    bordered
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource4}
                    columns={columns4}
                    size="small"
                    pagination={false}
                  />
                </TabPane>

                <TabPane
                  tab={
                    <span>
                      <InteractionOutlined />
                      ทิศทางการวิจัย
                    </span>
                  }
                  key="5"
                >
                  <Table
                    bordered
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource5}
                    columns={columns5}
                    size="small"
                    pagination={false}
                  />
                  <Divider />

                  <Row style={{ float: "right" }}>
                    <Space>
                      <p>แสดงรายการ :</p>
                      <Select
                        defaultValue="0"
                        style={{ width: 120 }}
                        onChange={handleChange}
                      >
                        <Option value="0">5 ปี</Option>
                        <Option value="1">10 ปี</Option>
                      </Select>
                    </Space>
                  </Row>
                </TabPane>

                {levelU !== "research"
                  ? <TabPane
                      tab={
                        <span>
                          <PartitionOutlined />
                          การสนับสนุนผลงานวิจัยตีพิมพ์
                        </span>
                      }
                      key="6"
                    >
                      <Table
                        bordered
                        scroll={{ x: "max-content" }}
                        dataSource={dataSource6}
                        columns={columns6}
                        size="small"
                        pagination={false}
                      />
                    </TabPane>
                  : <div />}

                <TabPane
                  tab={
                    <span
                      onClick={() => {
                        window.open("/Research_manage_scopus", "_self");
                      }}
                    >
                      <SearchOutlined />
                      ค้นหาผลงาน
                    </span>
                  }
                  key="7"
                />
                <TabPane
                  tab={
                    <span>
                      <FundOutlined />
                      BEEN
                    </span>
                  }
                  key="8"
                >
                  <Table
                    bordered
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource7}
                    columns={columns7}
                    size="small"
                    pagination={false}
                    summary={() =>
                      <Table.Summary fixed>
                        <Table.Summary.Row
                          style={{
                            textAlign: "center",
                            backgroundColor: "#b5f5ec"
                          }}
                        >
                          <Table.Summary.Cell>รวมทั้งหมด</Table.Summary.Cell>

                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "All", Sum_All_Been, "BEEN");
                              }}
                            >
                              {Sum_All_Been}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "Q1", Sum_Q1_Been, "BEEN");
                              }}
                            >
                              {Sum_Q1_Been}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "Q2", Sum_Q2_Been, "BEEN");
                              }}
                            >
                              {Sum_Q2_Been}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "Q3", Sum_Q3_Been, "BEEN");
                              }}
                            >
                              {Sum_Q3_Been}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "Q4", Sum_Q4_Been, "BEEN");
                              }}
                            >
                              {Sum_Q4_Been}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <a
                              onClick={() => {
                                Showlist("All", "N/A", Sum_NA_Been, "BEEN");
                              }}
                            >
                              {Sum_NA_Been}
                            </a>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {Sum_All_Been2}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      </Table.Summary>}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <TeamOutlined />
                      จำนวนนักวิจัย
                    </span>
                  }
                  key="9"
                >
                  <Table
                    bordered
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource8}
                    columns={columns8}
                    size="small"
                    pagination={false}
                  />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>

        <br />
      </Content>

      <div style={{ height: "100px" }} />

      <Footer_last />

      <Modal
        title={xtitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Card size="small">
          <Table
            bordered
            scroll={{ x: "max-content" }}
            dataSource={dataSourceResearch}
            columns={columnsResearch}
            size="small"
            pagination={false}
          />
        </Card>
      </Modal>

      <BackTop />
    </React.Fragment>
  );
};

export default Research_scopus;
