import React, { useEffect, useState } from "react";
import axios from "axios";

import "./css/dashboard.css";

import {
  Row,
  Col,
  Layout,
  Card,
  Divider,
  Space,
  Select,
  Button,
  Form,
  Modal,
  InputNumber
} from "antd";

import moment from "moment";

import { SaveOutlined, ClearOutlined } from "@ant-design/icons";

import swal from "sweetalert";

import Hightchart2 from "./Highchart2";
import Piechart2 from "./Piechart2";

const { Option } = Select;

const Dashboard_i = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  let YearNow = moment().format("YYYY");

  const [YearValue, _YearValue] = useState(YearNow);
  const [xYeardh, _xYeardh] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);

  const [Kr6_old, _Kr6_old] = useState("");
  const [Kr7_old, _Kr7_old] = useState("");
  const [Kr8_old, _Kr8_old] = useState("");
  const [Stu_old, _Stu_old] = useState("");
  const [PubStu_old, _PubStu_old] = useState("");
  const [GStu_old, _GStu_old] = useState("");

  const [Num_Color1, _Num_Color1] = useState("red");
  const [Num_Color2, _Num_Color2] = useState("red");
  const [Num_Color3, _Num_Color3] = useState("red");
  const [Num_Color4, _Num_Color4] = useState("red");
  const [Num_Color5, _Num_Color5] = useState("red");
  const [Num_Color6, _Num_Color6] = useState("red");

  const [data_Kr6, _data_Kr6] = useState();
  const [data_Kr7, _data_Kr7] = useState();
  const [data_Kr8, _data_Kr8] = useState();
  const [data_NoSTU, _data_NoSTU] = useState();
  const [data_PubSTU, _data_PubSTU] = useState();
  const [data_GradSTU, _data_GradSTU] = useState();

  const [goal_Kr6, _goal_Kr6] = useState();
  const [goal_Kr7, _goal_Kr7] = useState();
  const [goal_Kr8, _goal_Kr8] = useState();
  const [goal_NoSTU, _goal_NoSTU] = useState();
  const [goal_PubSTU, _goal_PubSTU] = useState();
  const [goal_GradSTU, _goal_GradSTU] = useState();

  const [dataSum_Kr6, _dataSum_Kr6] = useState();
  const [dataSum_Kr7, _dataSum_Kr7] = useState();
  const [dataSum_Kr8, _dataSum_Kr8] = useState();
  // const [dataSum_NoSTU, _dataSum_NoSTU] = useState();
  const [dataSum_PubSTU, _dataSum_PubSTU] = useState();
  // const [dataSum_GradSTU, _dataSum_GradSTU] = useState();

  const [dataHigh4, _dataHigh4] = useState([]);

  const [dataPie1, _dataPie1] = useState([]);

  useEffect(() => {
    axios
      .get(xURL + "year_papers")
      .then(result => {
        //console.log(result.data);

        _xYeardh(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    Find_scopus("I1", YearValue);
    Find_scopus("I2", YearValue);
    Find_scopus("I3", YearValue);
    Find_scopus("I4", YearValue);
    Find_scopus("I5", YearValue);
    Find_scopus("I6", YearValue);

    Find_highData("I3", YearValue);

    Find_PieData("I1", YearValue);
  }, []);

  const handleChange = value => {
    // console.log(`selected ${value}`);
    Find_scopus("I1", value);
    Find_scopus("I2", value);
    Find_scopus("I3", value);
    Find_scopus("I4", value);
    Find_scopus("I5", value);
    Find_scopus("I6", value);

    Find_highData("I3", value);

    Find_PieData("I1", value);

    _YearValue(value);
  };

  const bClearclick = () => {
    form1.resetFields();

    findGoal();
  };

  const bSaveclick = () => {
    const f = form1.getFieldsValue();

    if (!f.ipKr6) {
      swal(
        "Save Error!",
        "Data entry fields KR6 %Pub in Q1(STU) (Goal)!",
        "warning"
      );
    } else if (!f.ipKr7) {
      // else if (!f.ipKr7) {
      //   swal("Save Error!", "Data entry fields KR7 %Int. STU (Goal)!", "warning");
      // }
      swal(
        "Save Error!",
        "Data entry fields KR7 %On- time Graduate (Goal)!",
        "warning"
      );
    } else if (f.ipKr8 < 0) {
      swal(
        "Save Error!",
        "Data entry fields KR8 New Program (Goal)!",
        "warning"
      );
    } else if (!f.ipStu) {
      swal("Save Error!", "Data entry fields No. of STU (Goal)!", "warning");
    } else if (!f.ipPubStu) {
      swal(
        "Save Error!",
        "Data entry fields Pub in SCOPUS(STU) (Goal)!",
        "warning"
      );
    } else if (!f.ipGStu) {
      swal(
        "Save Error!",
        "Data entry fields Total Graduate STU (Goal)!",
        "warning"
      );
    } else {
      swal({
        title: "Confirm Edit Goal",
        text: "Year " + YearValue,
        icon: "warning",
        buttons: ["Cancle", "Ok"]
      }).then(willOk => {
        if (willOk) {
          updatedata();
        }
      });
    }
  };

  const updatedata = () => {
    const f = form1.getFieldsValue();

    let xdata = [
      { name: "KR6 %Pub in Q1(STU)", goal: f.ipKr6, old: Kr6_old },
      // { name: "KR7 %Int. STU", goal: f.ipKr7, old: Kr7_old },
      { name: "KR7 %On- time Graduate", goal: f.ipKr7, old: Kr7_old },
      { name: "KR8 New Program", goal: f.ipKr8, old: Kr8_old },
      { name: "No. of STU", goal: f.ipStu, old: Stu_old },
      { name: "Pub in SCOPUS(STU)", goal: f.ipPubStu, old: PubStu_old },
      { name: "Total Graduate STU", goal: f.ipGStu, old: GStu_old }
    ];

    // console.log(emplydata);

    xdata.forEach((i, idx) => {
      if (!i.old) {
        axios
          .post(xURL + "insert_goal", {
            year: YearValue,
            name: i.name,
            goal: i.goal
          })
          .then(result => {
            swal("Save Complete!", "", "success");
            setModalOpen(false);

            // console.log(result.data);

            Find_scopus("I1", YearValue);
            Find_scopus("I2", YearValue);
            Find_scopus("I3", YearValue);
            Find_scopus("I4", YearValue);
            Find_scopus("I5", YearValue);
            Find_scopus("I6", YearValue);

            Find_highData("I3", YearValue);

            Find_PieData("I1", YearValue);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else {
        axios
          .post(xURL + "update_goal", {
            year: YearValue,
            name: i.name,
            goal: i.goal
          })
          .then(result => {
            swal("Save Complete!", "", "success");
            setModalOpen(false);

            // console.log(result.data);

            Find_scopus("I1", YearValue);
            Find_scopus("I2", YearValue);
            Find_scopus("I3", YearValue);
            Find_scopus("I4", YearValue);
            Find_scopus("I5", YearValue);
            Find_scopus("I6", YearValue);

            Find_highData("I3", YearValue);

            Find_PieData("I1", YearValue);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const findGoal = () => {
    _Kr6_old("");
    _Kr7_old("");
    _Kr8_old("");
    _Stu_old("");
    _PubStu_old("");
    _GStu_old("");

    axios
      .post(xURL + "db_findGoal", { year: YearValue })
      .then(result => {
        // console.log("data", result.data);

        result.data.forEach((i, idx) => {
          if (i.topic == "KR6 %Pub in Q1(STU)") {
            form1.setFieldsValue({
              ipKr6: i.goal
            });

            _Kr6_old(i.goal);
          } else if (i.topic == "KR7 %On- time Graduate") {
            // else if (i.topic == "KR7 %Int. STU") {
            //   form1.setFieldsValue({
            //     ipKr7: i.goal
            //   });

            //   _Kr7_old(i.goal);
            // }
            form1.setFieldsValue({
              ipKr7: i.goal
            });

            _Kr7_old(i.goal);
          } else if (i.topic == "KR8 New Program") {
            form1.setFieldsValue({
              ipKr8: i.goal
            });

            _Kr8_old(i.goal);
          } else if (i.topic == "No. of STU") {
            form1.setFieldsValue({
              ipStu: i.goal
            });

            _Stu_old(i.goal);
          } else if (i.topic == "Pub in SCOPUS(STU)") {
            form1.setFieldsValue({
              ipPubStu: i.goal
            });

            _PubStu_old(i.goal);
          } else if (i.topic == "Total Graduate STU") {
            form1.setFieldsValue({
              ipGStu: i.goal
            });

            _GStu_old(i.goal);
          }
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_scopus = (order, YearValue) => {
    axios
      .post(xURL + "db_tabbox", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        if (result.data[0]) {
          if (order == "I1") {
            _data_Kr6(result.data[0].pub_data);
            _goal_Kr6(result.data[0].goal);
            _dataSum_Kr6(
              result.data[0].countkr6 + "/" + result.data[0].countkr6a
            );

            if (
              parseFloat(result.data[0].pub_data) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color1("green");
            } else {
              _Num_Color1("red");
            }
          } else if (order == "I2") {
            _data_Kr7(result.data[0].pub_data);
            _goal_Kr7(result.data[0].goal);
            _dataSum_Kr7(result.data[0].count1 + "/" + result.data[0].count2);

            if (
              parseFloat(result.data[0].pub_data) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color2("green");
            } else {
              _Num_Color2("red");
            }
          } else if (order == "I3") {
            _data_Kr8(0);
            _dataSum_Kr8(result.data[0].pub_data);
            _goal_Kr8(result.data[0].goal);

            if (
              parseFloat(result.data[0].pub_data) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color3("green");
            } else {
              _Num_Color3("red");
            }
          } else if (order == "I4") {
            _data_NoSTU(result.data[0].pub_data);
            _goal_NoSTU(result.data[0].goal);
            // _dataSum_NoSTU(result.data[0].countNostu);

            if (
              parseFloat(result.data[0].pub_data) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color4("green");
            } else {
              _Num_Color4("red");
            }
          } else if (order == "I5") {
            _data_PubSTU(result.data[0].pub_data);
            _goal_PubSTU(result.data[0].goal);
            _dataSum_PubSTU(result.data[0].countPubStu);

            if (
              parseFloat(result.data[0].pub_data) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color5("green");
            } else {
              _Num_Color5("red");
            }
          } else if (order == "I6") {
            _data_GradSTU(result.data[0].pub_data);
            _goal_GradSTU(result.data[0].goal);
            // _dataSum_GradSTU(result.data[0].countGradStu);

            if (
              parseFloat(result.data[0].pub_data) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color6("green");
            } else {
              _Num_Color6("red");
            }
          }
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_highData = (order, YearValue) => {
    axios
      .post(xURL + "db_Highdata2", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        let Tb1 = [];

        let xsum = 0;

        let xgoal12 = 0,
          xgoal11 = 0,
          xgoal10 = 0,
          xgoal9 = 0,
          xgoal8 = 0,
          xgoal7 = 0,
          xgoal6 = 0,
          xgoal5 = 0,
          xgoal4 = 0,
          xgoal3 = 0,
          xgoal2 = 0,
          xgoal1 = 0;

        xgoal12 = parseFloat(result.data[0].goal).toFixed(1);
        xgoal11 = parseFloat(xgoal12 - xgoal12 / 12).toFixed(1);
        xgoal10 = parseFloat(xgoal11 - xgoal12 / 12).toFixed(1);
        xgoal9 = parseFloat(xgoal10 - xgoal12 / 12).toFixed(1);
        xgoal8 = parseFloat(xgoal9 - xgoal12 / 12).toFixed(1);
        xgoal7 = parseFloat(xgoal8 - xgoal12 / 12).toFixed(1);
        xgoal6 = parseFloat(xgoal7 - xgoal12 / 12).toFixed(1);
        xgoal5 = parseFloat(xgoal6 - xgoal12 / 12).toFixed(1);
        xgoal4 = parseFloat(xgoal5 - xgoal12 / 12).toFixed(1);
        xgoal3 = parseFloat(xgoal4 - xgoal12 / 12).toFixed(1);
        xgoal2 = parseFloat(xgoal3 - xgoal12 / 12).toFixed(1);
        xgoal1 = parseFloat(xgoal2 - xgoal12 / 12).toFixed(1);

        if (result.data[0]) {
          xsum = result.data[0].m1;
          Tb1.push({
            name: "1",
            data_in_Month: result.data[0].m1,
            data: xsum,
            goal: xgoal1
          });

          xsum = xsum + result.data[0].m2;
          Tb1.push({
            name: "2",
            data_in_Month: result.data[0].m2,
            data: xsum,
            goal: xgoal2
          });

          xsum = xsum + result.data[0].m3;
          Tb1.push({
            name: "3",
            data_in_Month: result.data[0].m3,
            data: xsum,
            goal: xgoal3
          });

          xsum = xsum + result.data[0].m4;
          Tb1.push({
            name: "4",
            data_in_Month: result.data[0].m4,
            data: xsum,
            goal: xgoal4
          });

          xsum = xsum + result.data[0].m5;
          Tb1.push({
            name: "5",
            data_in_Month: result.data[0].m5,
            data: xsum,
            goal: xgoal5
          });

          xsum = xsum + result.data[0].m6;
          Tb1.push({
            name: "6",
            data_in_Month: result.data[0].m6,
            data: xsum,
            goal: xgoal6
          });

          xsum = xsum + result.data[0].m7;
          Tb1.push({
            name: "7",
            data_in_Month: result.data[0].m7,
            data: xsum,
            goal: xgoal7
          });

          xsum = xsum + result.data[0].m8;
          Tb1.push({
            name: "8",
            data_in_Month: result.data[0].m8,
            data: xsum,
            goal: xgoal8
          });

          xsum = xsum + result.data[0].m9;
          Tb1.push({
            name: "9",
            data_in_Month: result.data[0].m9,
            data: xsum,
            goal: xgoal9
          });

          xsum = xsum + result.data[0].m10;
          Tb1.push({
            name: "10",
            data_in_Month: result.data[0].m10,
            data: xsum,
            goal: xgoal10
          });

          xsum = xsum + result.data[0].m11;
          Tb1.push({
            name: "11",
            data_in_Month: result.data[0].m11,
            data: xsum,
            goal: xgoal11
          });

          xsum = xsum + result.data[0].m12;
          Tb1.push({
            name: "12",
            data_in_Month: result.data[0].m12,
            data: xsum,
            goal: result.data[0].goal
          });
        }

        if (order == "I3") {
          _dataHigh4(Tb1);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_PieData = (order, YearValue) => {
    axios
      .post(xURL + "db_Piedata2", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        let Tb1 = [];
        if (result.data[0]) {
          if (order == "I1") {
            Tb1.push({
              name: "Q1",
              value: result.data[0].q1,
              value2: result.data[0].q1Count
            });
            Tb1.push({
              name: "Q2",
              value: result.data[0].q2,
              value2: result.data[0].q2Count
            });
            Tb1.push({
              name: "Q3",
              value: result.data[0].q3,
              value2: result.data[0].q3Count
            });
            Tb1.push({
              name: "Q4",
              value: result.data[0].q4,
              value2: result.data[0].q4Count
            });
            Tb1.push({
              name: "N/A",
              value: result.data[0].na,
              value2: result.data[0].naCount
            });

            _dataPie1(Tb1);
          }
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <div>
        <h1 className="rise">I</h1>
      </div>

      <Row>
        <div>
          <Form.Item label="Year" name="slyear">
            <Select
              defaultValue={YearNow}
              style={{
                width: 250,
                marginBottom: "20px"
              }}
              onChange={handleChange}
            >
              {xYeardh.map((i, idx) => {
                return (
                  <Option value={i.year}>
                    {i.year} ({i.name})
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div style={{ marginLeft: "25px" }}>
          <Button
            type="primary"
            onClick={() => {
              form1.resetFields();

              findGoal();

              setModalOpen(true);
            }}
          >
            Edit Goal
          </Button>
        </div>
      </Row>

      <Row gutter={3}>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR6 %Pub in Q1(STU)
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color1
                      }}
                    >
                      {data_Kr6}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_Kr6} %
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_Kr6})
              </p>
            </Row>
          </Card>
        </Col>
        {/* <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR7 %Int. STU
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color2
                      }}
                    >
                      {dataSum_Kr7}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_Kr7} %
                </p>
              </Col>
            </Row>

            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_Kr7})
              </p>
            </Row>
          </Card>
        </Col> */}
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "11px" }}>
                  KR7 %On- time Graduate
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color2
                      }}
                    >
                      {data_Kr7}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_Kr7} %
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_Kr7})
              </p>
            </Row>
          </Card>
        </Col>

        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR8 New Program
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />

                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: Num_Color3
                    }}
                  >
                    {data_Kr8}
                  </p>
                </Row>
              </Col>

              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_Kr8}
                </p>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  No. of STU
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: Num_Color4
                    }}
                  >
                    {data_NoSTU}
                  </p>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_NoSTU}
                </p>
              </Col>
            </Row>
            {/* <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_NoSTU})
              </p>
            </Row> */}
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  Pub in SCOPUS(STU)
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color5
                      }}
                    >
                      {data_PubSTU}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_PubSTU}
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_PubSTU})
              </p>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  Total Graduate STU
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: Num_Color6
                    }}
                  >
                    {data_GradSTU}
                  </p>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_GradSTU}
                </p>
              </Col>
            </Row>
            {/* <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_GradSTU})
              </p>
            </Row> */}
          </Card>
        </Col>
      </Row>

      <div style={{ height: "10px" }} />

      <Row gutter={16}>
        <Col xs={23} sm={23} md={12} lg={8} xl={8}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              KR7 %On- time Graduate
            </p>
            {/* <Hightchart2 dataHigh={dataHigh3} order={1} /> */}
          </Card>
        </Col>
        <Col xs={23} sm={23} md={12} lg={8} xl={8}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              KR8 New Program
            </p>
            {/* <Hightchart2 dataHigh={dataHigh2} order={1} /> */}
          </Card>
        </Col>
        <Col xs={23} sm={23} md={12} lg={8} xl={8}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              No. of STU
            </p>
            {/* <Hightchart2 dataHigh={dataHigh6} order={1} /> */}
          </Card>
        </Col>
      </Row>

      <div style={{ height: "10px" }} />

      <Row gutter={16}>
        <Col xs={23} sm={23} md={12} lg={12} xl={12}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              Pub in SCOPUS(STU)
            </p>
            <Hightchart2 dataHigh={dataHigh4} order={1} />
          </Card>
        </Col>
        <Col xs={23} sm={23} md={12} lg={12} xl={12}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              Total Graduate STU
            </p>
            {/* <Hightchart2 dataHigh={dataHigh4} order={1} /> */}
          </Card>
        </Col>
      </Row>

      <div style={{ height: "10px" }} />

      <Row gutter={16}>
        <Col xs={23} sm={23} md={24} lg={24} xl={24}>
          <Card style={{ height: 320 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              %Quarties(STU)
            </p>

            <Piechart2 dataPie={dataPie1} />
          </Card>
        </Col>
      </Row>

      <div style={{ height: "50px" }} />

      <Modal
        title="Edit Goal"
        centered
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={null}
        width={1000}
      >
        <p style={{ fontWeight: "bold", fontSize: "18px" }}>
          Year {YearValue}
        </p>
        <hr className="style-two" style={{ marginBottom: "20px" }} />

        <Form form={form1} initialValues={{}}>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR6 %Pub in Q1(STU)</p>
            </Col>
            <Col span={3}>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr6">
                  <InputNumber
                    autoFocus
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              <p> %</p>
            </Col>
          </Row>
          {/* <Row>
            <Col span={4}>
              <p className="t-goal">KR7 %Int. STU</p>
            </Col>
            <Col span={3}>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr7">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              <p> %</p>
            </Col>
          </Row> */}
          <Row>
            <Col span={4}>
              <p className="t-goal">KR7 %On- time Graduate</p>
            </Col>
            <Col span={3}>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr7">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              <p> %</p>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR8 New Program</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr8">
                  <InputNumber
                    min={0}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">No. of STU</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipStu">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">Pub in SCOPUS(STU)</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipPubStu">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">Total Graduate STU</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipGStu">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>

        <hr className="style-two" style={{ marginBottom: "20px" }} />

        <Row justify="center">
          <Space>
            <Button
              type="primary"
              onClick={() => {
                bSaveclick();
              }}
            >
              <SaveOutlined /> Save
            </Button>

            <Button
              type="danger"
              onClick={() => {
                bClearclick();
              }}
            >
              <ClearOutlined /> Reset
            </Button>
          </Space>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default Dashboard_i;
