import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";

import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  Form,
  Row,
  Col,
  Layout,
  Table,
  Card,
  Divider,
  Tabs,
  Input,
  Space,
  BackTop,
  PageHeader,
  Spin,
  Select,
  Typography,
  Modal,
  Button,
  InputNumber
} from "antd";

import moment from "moment";
import "moment/locale/th";

import { createGlobalStyle } from "styled-components";

import {
  ProfileOutlined,
  SaveOutlined,
  ClearOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
};

const Research_academin_set = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState([]);

  const [xstudent_id, _xstudent_id] = useState("");
  const [xstudent_idE, _xstudent_idE] = useState("");
  const [xoldstudent_id, _xoldstudent_id] = useState("");

  const [xNameSearch, _xNameSearch] = useState("");
  const [xStudentValue, _xStudentValue] = useState("0");
  const [xStudentValueE, _xStudentValueE] = useState("0");
  const [xTeacherValue, _xTeacherValue] = useState("0");
  const [xTeacherValueE, _xTeacherValueE] = useState("0");

  const [xDegree_course, _xDegree_course] = useState("");
  const [xDegree_courseE, _xDegree_courseE] = useState("");
  const [xDegree_courseValue, _xDegree_courseValue] = useState("0");
  const [xDegree_courseValueE, _xDegree_courseValueE] = useState("0");

  const [xYear, _xYear] = useState("");
  const [xYear2, _xYear2] = useState("");
  const [xYearValue, _xYearValue] = useState("0");
  const [xYearValue2, _xYearValue2] = useState("0");
  const [xYearValue3, _xYearValue3] = useState("0");
  const [xYearValueE, _xYearValueE] = useState("0");
  const [xYearValueE2, _xYearValueE2] = useState("0");
  const [xYearValueE3, _xYearValueE3] = useState("0");

  const xStatus = [
    { id: 1, name: "กำลังศึกษา" },
    { id: 2, name: "สำเร็จการศึกษา" }
  ];
  const xStatus2 = [
    { id: "", name: "-" },
    { id: "Y", name: "จบตามกำหนดเวลา" },
    { id: "N", name: "ไม่จบตามกำหนดเวลา" }
  ];
  const [xStatusValue, _xStatusValue] = useState("0");
  const [xStatusValue2, _xStatusValue2] = useState("0");
  const [xStatusValueE, _xStatusValueE] = useState("0");
  const [xStatusValueE2, _xStatusValueE2] = useState("0");

  const [keyTab, _keyTab] = useState("1");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [zstudent_id, _zstudent_id] = useState("");
  const [zstudent, _zstudent] = useState("");
  const [zteacher, _zteacher] = useState("");
  const [zdegree_course, _zdegree_course] = useState("");
  const [zyear, _zyear] = useState("");
  const [zstatus, _zstatus] = useState("");
  const [zstatus2, _zstatus2] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");
          //ค้นหา ชื่อ นศ./อจ.
          axios
            .get(xURL + "Academic_name")
            .then(result => {
              //console.log(result.data);

              _xNameSearch(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหา หลักสูตร
          axios
            .get(xURL + "Degree_course")
            .then(result => {
              //console.log(result.data);

              _xDegree_course(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          setYear();

          GetTable();
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const setYear = () => {
    let yearNow = moment().format("YYYY");
    let yearNow2 = moment().format("YYYY");

    yearNow = parseInt(yearNow) + 543;
    yearNow2 = parseInt(yearNow2) + 547;
    // console.log(yearNow);

    let Tb = [],
      Tb2 = [];

    for (let i = 1; i <= 5; i++) {
      Tb.push({ year: yearNow });

      yearNow = parseInt(yearNow) - 1;
    }

    for (let i = 1; i <= 5; i++) {
      Tb2.push({ year: yearNow2 });

      yearNow2 = parseInt(yearNow2) - 1;
    }

    // console.log(Tb);
    _xYear(Tb);
    _xYear2(Tb2);
  };

  const bClear = () => {
    form1.resetFields();

    _xstudent_id("");
    _xStatusValue("0");
    _xTeacherValue("0");
    _xDegree_courseValue("0");
    _xYearValue("0");
    _xYearValue2("0");
    _xYearValue3("0");
    _xStatusValue("0");
    _xStatusValue2("0");
  };

  const bSave = () => {
    if (xstudent_id.toString().length < 9 || xstudent_id == "") {
      swal("บันทึก ไม่ได้!", "คีย์รหัสนักศึกษาไม่ถูกต้อง", "warning");
    } else if (xStudentValue == "0") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ชื่อนักศึกษา", "warning");
    } else if (xTeacherValue == "0") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ชื่ออาจารย์ที่ปรึกษา", "warning");
    } else if (xDegree_courseValue == "0") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก หลักสูตร", "warning");
    } else if (xYearValue == "0") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ปีที่เข้าศึกษา", "warning");
    } else if (xStatusValue == "0") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก สถานะ", "warning");
    } else if (xYearValue2 == "0") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ปีการศึกษาสิ้นสุด", "warning");
    } else {
      insertdata();
    }
  };

  const insertdata = () => {
    const f = form1.getFieldValue();

    let xstatus2 = "",
      xremark = "";

    if (xStatusValue2 == "0") {
      xstatus2 = "";
    } else {
      xstatus2 = xStatusValue2;
    }

    if (!f.edremark) {
      xremark = "";
    } else {
      xremark = f.edremark;
    }

    swal({
      title: "ยืนยันเพิ่มข้อมูล",
      text: ":-)",
      icon: "warning",
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willOk => {
      if (willOk) {
        axios
          .post(xURL + "insert_advisor", {
            student_id: xstudent_id,
            student: xStudentValue,
            teacher: xTeacherValue,
            degree: xDegree_courseValue,
            year: xYearValue - 543,
            year2: xYearValue2 - 543,
            year3: xYearValue3 - 543,
            status1: xStatusValue,
            status2: xstatus2,
            remark: xremark
          })
          .then(result => {
            //console.log(result.data);

            bClear();
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const GetTable = () => {
    axios
      .get(xURL + "find_academic1")
      .then(result => {
        //console.log(result.data);
        Show_academic(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Show_academic = zdata => {
    const Tb1 = [];

    let xstudent_name = "",
      xstatus = "",
      xyear = 0,
      xyear2 = 0,
      xyear3 = 0,
      xgraduate_ontime = "";

    zdata.forEach((i, idx) => {
      if (i.student_name) {
        xstudent_name = i.student_name;
      } else {
        xstudent_name = "";
      }

      if (i.status == 1) {
        xstatus = "กำลังศึกษา";
      } else if (i.status == 2) {
        xstatus = "สำเร็จการศึกษา";
      } else if (i.status == 3) {
        xstatus = "ลาออก";
      } else if (i.status == 4) {
        xstatus = "พ้นสภาพ";
      }

      if (i.graduate_ontime == "Y") {
        xgraduate_ontime = "จบตามกำหนดเวลา";
      } else if (i.graduate_ontime == "N") {
        xgraduate_ontime = "ไม่จบตามกำหนดเวลา";
      }

      xyear = parseInt(i.year) + 543;
      xyear2 = parseInt(i.year_end) + 543;

      if (i.year_graduate == null) {
        xyear3 = "";
      } else {
        xyear3 = parseInt(i.year_graduate) + 543;
      }

      Tb1.push({
        key: idx,
        num: idx + 1,
        student_id: i.student_id_cmu,
        student: xstudent_name,
        advisor: i.advisor_name,
        degree_course: i.degree_short,
        year: xyear,
        year2: xyear2,
        year3: xyear3,
        status: xstatus,
        status2: xgraduate_ontime,
        graduate_ontime: i.graduate_ontime
      });
    });

    _dataSource1(Tb1);
  };

  const bEditclick = zdata => {
    // console.log(zdata);

    axios
      .post(xURL + "Findacademic", {
        student_id: zdata.student_id
      })
      .then(result => {
        let xstatus = "",
          xgraduate_ontime = "",
          xyear3 = "";

        if (result.data[0].status == 1) {
          xstatus = "กำลังศึกษา";
        } else if (result.data[0].status == 2) {
          xstatus = "สำเร็จการศึกษา";
        } else if (result.data[0].status == 3) {
          xstatus = "ลาออก";
        } else if (result.data[0].status == 4) {
          xstatus = "พ้นสภาพ";
        }

        if (result.data[0].graduate_ontime == "Y") {
          xgraduate_ontime = "จบตามกำหนดเวลา";
        } else if (result.data[0].graduate_ontime == "N") {
          xgraduate_ontime = "ไม่จบตามกำหนดเวลา";
        }

        if (result.data[0].year_graduate == null) {
          xyear3 = "";
        } else {
          xyear3 = parseInt(result.data[0].year_graduate) + 543;
        }

        form2.setFieldsValue({
          student_id: result.data[0].student_id_cmu,
          slstudent: result.data[0].student_name,
          slteacher: result.data[0].advisor_name,
          sldegree_course:
            "(" +
            result.data[0].degree_short +
            ")" +
            result.data[0].degree_name_th,
          slyear: result.data[0].year + 543,
          slyear2: result.data[0].year_end + 543,
          slyear3: xyear3,
          slstatus: xstatus,
          slstatus2: xgraduate_ontime
        });

        _xstudent_idE(result.data[0].student_id_cmu);
        _xStudentValueE(result.data[0].student_id);
        _xTeacherValueE(result.data[0].advisor_id);
        _xDegree_courseValueE(result.data[0].degree_id);
        _xYearValueE(result.data[0].year + 543);
        _xYearValueE2(result.data[0].year_end + 543);
        _xYearValueE3(xyear3);
        _xStatusValueE(result.data[0].status);
        _xStatusValueE2(result.data[0].graduate_ontime);

        _xoldstudent_id(result.data[0].student_id_cmu);

        setIsModalVisible(true);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bEdit = () => {
    if (xstudent_idE.toString().length < 9 || xstudent_idE == "") {
      swal("แก้ไข ไม่ได้!", "คีย์รหัสนักศึกษาไม่ถูกต้อง", "warning");
    } else if (xStudentValueE == "0") {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้เลือก ชื่อนักศึกษา", "warning");
    } else if (xTeacherValueE == "0") {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้เลือก ชื่ออาจารย์ที่ปรึกษา", "warning");
    } else if (xDegree_courseValueE == "0") {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้เลือก หลักสูตร", "warning");
    } else if (xYearValueE == "0") {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้เลือก ปีการศึกษาเริ่มต้น", "warning");
    } else if (xStatusValueE == "0") {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้เลือก สถานะ", "warning");
    } else if (xYearValueE2 == "0") {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้เลือก ปีการศึกษาสิ้นสุด", "warning");
    }else {
      updatedata();
    }
  };

  const updatedata = () => {
    // console.log(
    //   xstudent_idE,
    //   xStudentValueE,
    //   xTeacherValueE,
    //   xDegree_courseValueE,
    //   xYearValueE,
    //   xStatusValueE,
    //   xStatusValueE2
    // );
    // console.log(xoldstudent_id);
    let xstatus2 = "";

    if (xStatusValueE2 == "0") {
      xstatus2 = "";
    } else {
      xstatus2 = xStatusValueE2;
    }

    swal({
      title: "ยืนยันแก้ไขข้อมูล",
      text: ":-)",
      icon: "warning",
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willOk => {
      if (willOk) {
        axios
          .post(xURL + "update_advisor", {
            student_id: xstudent_idE,
            student: xStudentValueE,
            teacher: xTeacherValueE,
            degree: xDegree_courseValueE,
            year: xYearValueE - 543,
            year2: xYearValueE2 - 543,
            year3: xYearValueE3 - 543,
            status1: xStatusValueE,
            status2: xstatus2,
            oldstudent_id: xoldstudent_id
          })
          .then(result => {
            //console.log(result.data);
            setIsModalVisible(false);
            GetTable();
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const bDeleteclick = student_id => {
    swal({
      title: "ยืนยัน ลบข้อมูลนักศึกษา",
      text: "รหัส นักศึกษา: " + student_id,
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        axios
          .post(xURL + "academic_delete", {
            student_id: student_id
          })
          .then(result => {
            swal("แจ้งเตือน!", "ลบโครงการวิจัยสำเร็จ!", "success").then(() => {
              GetTable();
            });
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        //bClearclick();
      }
    });
  };

  const columns1 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "รหัสนักศึกษา",
      dataIndex: "student_id",
      key: "student_id",
      align: "center",
      width: 120,
      sorter: (a, b) => a.num - b.num
    },
    {
      title: "นักศึกษา",
      dataIndex: "student",
      key: "student",
      align: "center",
      width: 200,
      sorter: (a, b) => {
        return a.student.localeCompare(b.student);
      },
      render: (text, record) => __TBShowText(record.student, 0, 1)
    },
    {
      title: "อาจารย์ที่ปรึกษา",
      dataIndex: "advisor",
      key: "advisor",
      align: "center",
      width: 200,
      sorter: (a, b) => {
        return a.advisor.localeCompare(b.advisor);
      },
      render: (text, record) => __TBShowText(record.advisor, 0, 1)
    },
    {
      title: "หลักสูตร",
      dataIndex: "degree_course",
      key: "degree_course",
      align: "center",
      width: 150,
      sorter: (a, b) => {
        return a.degree_course.localeCompare(b.degree_course);
      }
    },
    {
      title: "ปีการศึกษาเริ่มต้น",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 80,
      sorter: (a, b) => a.year - b.year
    },
    {
      title: "ปีการศึกษาสิ้นสุด",
      dataIndex: "year2",
      key: "year2",
      align: "center",
      width: 80,
      sorter: (a, b) => a.year2 - b.year2
    },
    {
      title: "ปีการศึกษาที่จบ",
      dataIndex: "year3",
      key: "year3",
      align: "center",
      width: 80,
      sorter: (a, b) => a.year3 - b.year3
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 130,
      sorter: (a, b) => {
        return a.status.localeCompare(b.status);
      },
      render(text, record, index) {
        if (text == "สำเร็จการศึกษา") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "#06D001" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        } else if (text == "กำลังศึกษา") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "#667BC6" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        } else if (text == "ลาออก") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "#ff0000" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        } else if (text == "พ้นสภาพ") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "#000000" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        }
      }
    },
    {
      title: "สำเร็จการศึกษาตามกำหนดเวลา",
      dataIndex: "status2",
      key: "status2",
      align: "center",
      width: 150,
      render(text, record, index) {
        if (record.graduate_ontime == "Y") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "green" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        } else if (record.graduate_ontime == "N") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "red" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        }
      }
    },
    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                //console.log("corresponding email is :", record.i_sum3);
                bEditclick(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                // console.log(record.student_id);
                bDeleteclick(record.student_id);
              }}
            />
          )
        };
      },
      fixed: "right"
    }
  ];

  const TabonChange = e => {
    //console.log(e);
    GetTable();
    _keyTab(e);
  };

  const onChangestudent_id = e => {
    _xstudent_id(e);
  };

  const onChangestudent_id2 = e => {
    _xstudent_idE(e);
  };

  function handleChange(value) {
    _xStudentValue(value);
  }

  function handleChange2(value) {
    _xTeacherValue(value);
  }

  function handleChangeE(value) {
    _xStudentValueE(value);
  }

  function handleChangeE2(value) {
    _xTeacherValueE(value);
  }

  function handleChange3(value) {
    _xDegree_courseValue(value);
  }

  function handleChange4(value) {
    _xYearValue(value);
  }

  function handleChangeE3(value) {
    _xDegree_courseValueE(value);
  }

  function handleChangeE4(value) {
    _xYearValueE(value);
  }

  function handleChange5(value) {
    _xStatusValue(value);
  }

  function handleChange6(value) {
    _xStatusValue2(value);
  }

  function handleChangeE5(value) {
    _xStatusValueE(value);
  }

  function handleChangeE6(value) {
    _xStatusValueE2(value);
  }

  function handleChange7(value) {
    _xYearValue2(value);
  }

  function handleChange8(value) {
    _xYearValue3(value);
  }

  function handleChangeE7(value) {
    _xYearValueE2(value);
  }

  function handleChangeE8(value) {
    _xYearValueE3(value);
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="สำนักวิชาการวิจัยวิทยาศาสตร์สุขภาพ"
            />
            <h4 style={{ marginLeft: "25px", marginTop: "-20px" }}>
              (จัดการข้อมูลรายชื่อ อาจารย์ที่ปรึกษาและนักศึกษา)
            </h4>
            <Divider />
            <Row justify="center">
              <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                <Tabs
                  defaultActiveKey="1"
                  size={"small"}
                  onChange={TabonChange}
                >
                  <TabPane
                    tab={
                      <span>
                        <PlusOutlined />
                        เพิ่ม
                      </span>
                    }
                    key="1"
                  >
                    <Row justify="center">
                      <Col xs={24} sm={24} md={18} lg={16} xl={12}>
                        <Card title="">
                          <Form form={form1} initialValues={{}} {...layout}>
                            <Form.Item
                              label="รหัสนักศึกษา"
                              name="student_id"
                              rules={[
                                {
                                  required: true,
                                  message: "กรุณากรอก รหัสนักศึกษา"
                                }
                              ]}
                            >
                              <InputNumber
                                autoFocus
                                placeholder="รหัสนักศึกษา"
                                style={{ width: "100%" }}
                                min="0"
                                max="999999999"
                                maxLength="9"
                                onChange={e => onChangestudent_id(e)}
                              />
                            </Form.Item>

                            <Form.Item
                              label="ชื่อนักศึกษา"
                              name="slstudent"
                              rules={[{ required: true }]}
                            >
                              <Select
                                showSearch
                                placeholder="ชื่อนักศึกษา"
                                onChange={handleChange}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())}
                                style={{ width: "100%" }}
                              >
                                {xNameSearch.length > 0
                                  ? xNameSearch.map((i, idx) => {
                                      return (
                                        <Option value={i.id}>
                                          {i.name}
                                        </Option>
                                      );
                                    })
                                  : <Option value="0">
                                      {}
                                    </Option>}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label="ชื่ออาจารย์ที่ปรึกษา"
                              name="slteacher"
                              rules={[{ required: true }]}
                            >
                              <Select
                                showSearch
                                placeholder="ชื่ออาจารย์ที่ปรึกษา"
                                onChange={handleChange2}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())}
                                style={{ width: "100%" }}
                              >
                                {xNameSearch.length > 0
                                  ? xNameSearch.map((i, idx) => {
                                      return (
                                        <Option value={i.id}>
                                          {i.name}
                                        </Option>
                                      );
                                    })
                                  : <Option value="0">
                                      {}
                                    </Option>}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label="หลักสูตร"
                              name="sldegree_course"
                              rules={[{ required: true }]}
                            >
                              <Select
                                placeholder="หลักสูตร"
                                onChange={handleChange3}
                                style={{ width: "100%" }}
                              >
                                {xDegree_course.length > 0
                                  ? xDegree_course.map((i, idx) => {
                                      return (
                                        <Option value={i.degree_id}>
                                          ({i.degree_short}) {i.degree_name_th}
                                        </Option>
                                      );
                                    })
                                  : <Option value="0">
                                      {}
                                    </Option>}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label="ปีที่เข้าศึกษา"
                              name="slyear"
                              rules={[{ required: true }]}
                            >
                              <Select
                                placeholder="ปีที่เข้าศึกษา"
                                onChange={handleChange4}
                                style={{ width: "100%" }}
                              >
                                {xYear.length > 0
                                  ? xYear.map((i, idx) => {
                                      return (
                                        <Option value={i.year}>
                                          {i.year}
                                        </Option>
                                      );
                                    })
                                  : <Option value="0">
                                      {}
                                    </Option>}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label="สถานะ"
                              name="slstutus"
                              rules={[{ required: true }]}
                            >
                              <Select
                                placeholder="สถานะ"
                                onChange={handleChange5}
                                style={{ width: "100%" }}
                              >
                                {xStatus.length > 0
                                  ? xStatus.map((i, idx) => {
                                      return (
                                        <Option value={i.id}>
                                          {i.name}
                                        </Option>
                                      );
                                    })
                                  : <Option value="0">
                                      {}
                                    </Option>}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label="ปีการศึกษาสิ้นสุด"
                              name="slyear2"
                              rules={[{ required: true }]}
                            >
                              <Select
                                placeholder="ปีการศึกษาสิ้นสุด"
                                onChange={handleChange7}
                                style={{ width: "100%" }}
                              >
                                {xYear2.length > 0
                                  ? xYear2.map((i, idx) => {
                                      return (
                                        <Option value={i.year}>
                                          {i.year}
                                        </Option>
                                      );
                                    })
                                  : <Option value="0">
                                      {}
                                    </Option>}
                              </Select>
                            </Form.Item>

                            <Form.Item label="ปีการศึกษาที่จบ" name="slyear3">
                              <Select
                                placeholder="ปีการศึกษาที่จบ"
                                onChange={handleChange8}
                                style={{ width: "100%" }}
                              >
                                {xYear2.length > 0
                                  ? xYear2.map((i, idx) => {
                                      return (
                                        <Option value={i.year}>
                                          {i.year}
                                        </Option>
                                      );
                                    })
                                  : <Option value="0">
                                      {}
                                    </Option>}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label="การสำเร็จการศึกษา"
                              name="slstutus2"
                            >
                              <Select
                                placeholder="การสำเร็จการศึกษา"
                                onChange={handleChange6}
                                style={{ width: "100%" }}
                              >
                                {xStatus2.length > 0
                                  ? xStatus2.map((i, idx) => {
                                      return (
                                        <Option value={i.id}>
                                          {i.name}
                                        </Option>
                                      );
                                    })
                                  : <Option value="0">
                                      {}
                                    </Option>}
                              </Select>
                            </Form.Item>

                            <Form.Item label="หมายเหตุ" name="edremark">
                              <Input
                                allowClear
                                placeholder="หมายเหตุ"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>

                            <br />
                            <br />
                            <Row align="center">
                              <Space>
                                <Button type="primary" onClick={bSave}>
                                  <SaveOutlined /> บันทึก
                                </Button>
                                <Button type="danger" onClick={bClear}>
                                  <ClearOutlined /> เคลียร์
                                </Button>
                              </Space>
                            </Row>
                          </Form>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <EditOutlined />
                        แก้ไข
                      </span>
                    }
                    key="2"
                  >
                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource1}
                      columns={columns1}
                      size="small"
                      pagination={false}
                    />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      <div style={{ height: "200px" }} />

      <Footer_last />

      <Modal
        title="แก้ไขข้อมูล"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={16} xl={16}>
            <Card size="small">
              <Form form={form2} initialValues={{}} {...layout}>
                <Form.Item
                  label="รหัสนักศึกษา"
                  name="student_id"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอก รหัสนักศึกษา"
                    }
                  ]}
                >
                  <InputNumber
                    autoFocus
                    placeholder="รหัสนักศึกษา"
                    style={{ width: "100%" }}
                    min="0"
                    max="999999999"
                    maxLength="9"
                    //   value={zstudent_id}
                    onChange={e => onChangestudent_id2(e)}
                  />
                </Form.Item>
                <Form.Item
                  label="ชื่อนักศึกษา"
                  name="slstudent"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    placeholder="ชื่อนักศึกษา"
                    onChange={handleChangeE}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())}
                    style={{ width: "100%" }}
                    //   value={zstudent}
                  >
                    {xNameSearch.length > 0
                      ? xNameSearch.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="ชื่ออาจารย์ที่ปรึกษา"
                  name="slteacher"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    placeholder="ชื่ออาจารย์ที่ปรึกษา"
                    onChange={handleChangeE2}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())}
                    style={{ width: "100%" }}
                    //   value={zteacher}
                  >
                    {xNameSearch.length > 0
                      ? xNameSearch.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="หลักสูตร"
                  name="sldegree_course"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="หลักสูตร"
                    onChange={handleChangeE3}
                    style={{ width: "100%" }}
                    //   value={zdegree_course}
                  >
                    {xDegree_course.length > 0
                      ? xDegree_course.map((i, idx) => {
                          return (
                            <Option value={i.degree_id}>
                              ({i.degree_short}) {i.degree_name_th}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="ปีที่เข้าศึกษา"
                  name="slyear"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="ปีที่เข้าศึกษา"
                    onChange={handleChangeE4}
                    style={{ width: "100%" }}
                    //   value={zyear}
                  >
                    {xYear.length > 0
                      ? xYear.map((i, idx) => {
                          return (
                            <Option value={i.year}>
                              {i.year}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="สถานะ"
                  name="slstatus"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="สถานะ"
                    onChange={handleChangeE5}
                    style={{ width: "100%" }}
                    //   value={zstatus}
                  >
                    {xStatus.length > 0
                      ? xStatus.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="ปีการศึกษาสิ้นสุด"
                  name="slyear2"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="ปีการศึกษาสิ้นสุด"
                    onChange={handleChangeE7}
                    style={{ width: "100%" }}
                  >
                    {xYear2.length > 0
                      ? xYear2.map((i, idx) => {
                          return (
                            <Option value={i.year}>
                              {i.year}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <Form.Item label="ปีการศึกษาที่จบ" name="slyear3">
                  <Select
                    placeholder="ปีการศึกษาที่จบ"
                    onChange={handleChangeE8}
                    style={{ width: "100%" }}
                  >
                    {xYear2.length > 0
                      ? xYear2.map((i, idx) => {
                          return (
                            <Option value={i.year}>
                              {i.year}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>
                <Form.Item label="การสำเร็จการศึกษา" name="slstatus2">
                  <Select
                    placeholder="การสำเร็จการศึกษา"
                    onChange={handleChangeE6}
                    style={{ width: "100%" }}
                    //   value={zstatus2}
                  >
                    {xStatus2.length > 0
                      ? xStatus2.map((i, idx) => {
                          return (
                            <Option value={i.id}>
                              {i.name}
                            </Option>
                          );
                        })
                      : <Option value="0">
                          {}
                        </Option>}
                  </Select>
                </Form.Item>

                <br />
                <br />

                <Row align="center">
                  <Button type="primary" onClick={bEdit}>
                    <EditOutlined />แก้ไข
                  </Button>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Modal>

      <BackTop />
    </React.Fragment>
  );
};

export default Research_academin_set;
