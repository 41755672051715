import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";

import { createGlobalStyle } from "styled-components";
import { FileExcelOutlined } from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Button,
  Table,
  Typography,
  Select,
  Spin
} from "antd";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import moment from "moment";
import "moment/locale/th";

import * as xlsx from "xlsx";

dayjs.locale("th");
dayjs.extend(buddhistEra);

const { Content } = Layout;
const { Text } = Typography;


const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Research_report_received1 = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [columns1, _columns1] = useState();
  const [dataSource1, _dataSource1] = useState([]);
  const [dataSourceEx, _dataSourceEx] = useState();

  const [dataYear, _dataYear] = useState([]);

  const [loading, _loading] = useState(true);

  const [paginationInfo] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const xresearch_id = localStorage.getItem("research_id");

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          findcolumn();
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GoPage = cmumis_id => {
    window.open("/Research_main?cmumis_id=" + cmumis_id, "_blank");
  };

  const findcolumn = () => {
    axios
      .get(xURL + "column_report_receive")
      .then(result => {
        let Tb = [],
          TbYear = [];

        Tb.push(
          {
            title: "ที่",
            dataIndex: "no",
            key: "no",
            align: "center",
            width: 50
          },
          {
            title: "รหัสโครงการ",
            dataIndex: "cmumis_id",
            key: "cmumis_id",
            align: "center",
            width: 120,
            render(text, record, index) {
              return {
                children: (
                  <a
                    onClick={e => {
                      GoPage(text);
                    }}
                  >
                    {text}
                  </a>
                )
              };
            }
          },
          {
            title: "ชื่อโครงการวิจัย",
            dataIndex: "name_P",
            key: "name_P",
            width: 550,
            render(text, record, index) {
              return {
                children: (
                  <a
                    onClick={e => {
                      GoPage(record.cmumis_id);
                    }}
                  >
                    {text}
                  </a>
                )
              };
            }
          },
          {
            title: "ชื่อย่อโครงการ",
            dataIndex: "name_shortP",
            key: "name_shortP",
            width: 200
          },
          {
            title: "ชื่อหัวหน้าโครงการวิจัย",
            dataIndex: "reseatcher_name",
            key: "reseatcher_name",
            width: 250
          },
          {
            title: "วันที่เริ่มต้น",
            dataIndex: "start_date",
            key: "start_date",
            align: "center",
            width: 100
          },
          {
            title: "วันที่สิ้นสุด",
            dataIndex: "end_date",
            key: "end_date",
            align: "center",
            width: 100
          },
          {
            title: "งบประมาณรวมทั้งโครงการ",
            dataIndex: "Sum_budget",
            key: "Sum_budget",
            align: "center",
            width: 200,
            render: (text, record) => __TBShowText(record.Sum_budget, 1, 0)
          },
          {
            title: "งบประมาณ",
            dataIndex: "budget",
            key: "budget",
            align: "center",
            width: 200,
            render: (text, record) => __TBShowText(record.budget, 1, 0)
          },
          {
            title: "สถานะโครงการวิจัย",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 150
          },
          {
            title: "จำนวนเงินที่ได้รับทั้งหมด",
            dataIndex: "all_receive",
            key: "all_receive",
            align: "center",
            width: 200,
            render: (text, record) => __TBShowText(record.all_receive, 1, 0)
          }
        );

        result.data.forEach((i, idx) => {
          Tb.push({
            title: parseInt(i.date_receive) + 543,
            dataIndex: "year" + idx,
            key: "year",
            align: "right",
            width: 150,
            render(text, record, index) {
              if (text) {
                return {
                  children: text.toLocaleString(undefined, {
                    minimumFractionDigits: 2
                  })
                };
              } else {
                return {
                  children: ""
                };
              }
            }
          });

          TbYear.push({
            year: parseInt(i.date_receive)
          });
        });

        Tb.push(
          {
            title: "ประเภทโครงการวิจัย",
            dataIndex: "type_project",
            key: "type_project",
            align: "center",
            width: 200
          },
          {
            title: "แหล่งทุน",
            dataIndex: "agency",
            key: "agency",
            width: 250
          },
          {
            title: "ประเภทแหล่งทุน1",
            dataIndex: "agency_type1",
            key: "agency_type1",
            align: "center",
            width: 150
          },
          {
            title: "ประเภทแหล่งทุน2",
            dataIndex: "agency_type2",
            key: "agency_type2",
            align: "center",
            width: 150
          },
          {
            title: "สังกัด",
            dataIndex: "agency_type3",
            key: "agency_type3",
            align: "center",
            width: 150
          },
          {
            title: "นำส่งใบเสร็จให้แหล่งทุน",
            dataIndex: "receipt",
            key: "receipt",
            align: "center",
            width: 180
          }
        );

        _dataYear(TbYear);
        _columns1(Tb);

        finddata(TbYear);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const finddata = zyear => {
    // console.log(dataYear);
    const Tb1 = [],
      TbEx = [];
    let xnum = 0,
      xcmumis_id = "";

    axios
      .post(xURL + "find_report_receive", { dataYear: zyear })
      .then(result => {
        // console.log(result.data);
        let xreceipt = "",
          xbudget1 = 0,
          xbudget2 = 0,
          xtotal_receive = 0;

        result.data.forEach((i, idx) => {
          if (i.receipt == "Y") {
            xreceipt = "ส่งใบเสร็จ";
          } else if (i.receipt == "N") {
            xreceipt = "ไม่ส่งใบเสร็จ";
          } else {
            xreceipt = "";
          }

          let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
          let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

          if (i.budget1 > 0) {
            xbudget1 = i.budget1.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.budget2 > 0) {
            xbudget2 = i.budget2.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.total_receive > 0) {
            xtotal_receive = i.total_receive.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.cmumis_id !== xcmumis_id) {
            xnum = xnum + 1;
          }

          Tb1.push({
            key: idx,
            no: xnum,
            cmumis_id: i.cmumis_id,
            name_P: i.name_p,
            name_shortP: i.short_name,
            reseatcher_name: i.researname,
            start_date: xsdate,
            end_date: xedate,
            Sum_budget: xbudget1,
            budget: xbudget2,
            status: i.status_id,
            all_receive: xtotal_receive,
            year0: i.year0,
            year1: i.year1,
            year2: i.year2,
            year3: i.year3,
            year4: i.year4,
            year5: i.year5,
            year6: i.year6,
            year7: i.year7,
            year8: i.year8,
            year9: i.year9,
            year10: i.year10,
            type_project: i.project_type_name,
            agency: i.agency_name,
            agency_type1: i.agency_type1,
            agency_type2: i.agency_type2,
            agency_type3: i.agency_type3,
            receipt: xreceipt
          });

          TbEx.push({
            cmumis_id: i.cmumis_id,
            name_P: i.name_p,
            name_shortP: i.short_name,
            reseatcher_name: i.researname,
            start_date: xsdate,
            end_date: xedate,
            Sum_budget: i.budget1,
            budget: i.budget2,
            status: i.status_id,
            all_receive: i.total_receive,
            year0: i.year0,
            year1: i.year1,
            year2: i.year2,
            year3: i.year3,
            year4: i.year4,
            year5: i.year5,
            year6: i.year6,
            year7: i.year7,
            year8: i.year8,
            year9: i.year9,
            year10: i.year10,
            type_project: i.project_type_name,
            agency: i.agency_name,
            agency_type1: i.agency_type1,
            agency_type2: i.agency_type2,
            agency_type3: i.agency_type3,
            receipt: xreceipt
          });

          xcmumis_id = i.cmumis_id;
        });

        _dataSource1(Tb1);
        _dataSourceEx(TbEx);

        _loading(false);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bExcelClick = () => {
    let xdata = [],
      xtabtitle = "",
      header = [];

    xtabtitle = "รายงานเงินทุนโครงการวิจัยที่ได้รับ ตามปีปฏิทิน (1ม.ค.-31ธ.ค.)";

    header.push(
      "รหัสโครงการ",
      "ชื่อโครงการวิจัย",
      "ชื่อย่อโครงการ",
      "ชื่อหัวหน้าโครงการวิจัย",
      "วันที่เริ่มต้น",
      "วันที่สิ้นสุด",
      "งบประมาณรวมทั้งโครงการ",
      "งบประมาณ",
      "สถานะโครงการวิจัย",
      "จำนวนเงินที่ได้รับทั้งหมด",
      "2558",
      "2559",
      "2560",
      "2561",
      "2562",
      "2563",
      "2564",
      "2565",
      "2566",
      "2567",
      "2568",
      "ประเภทโครงการวิจัย",
      "แหล่งทุน",
      "ประเภทแหล่งทุน1",
      "ประเภทแหล่งทุน2",
      "สังกัด",
      "นำส่งใบเสร็จให้แหล่งทุน"
    );

    xdata.push([xtabtitle]);
    xdata.push([""]);
    xdata.push(header);

    let excmumis_id = "",
      exnameP = "",
      exname_shortP = "",
      exreseatcher_name = "",
      exstart_date = "",
      exend_date = "",
      exSum_budget = "",
      exstatus = "";

    for (var row in dataSourceEx) {
      const xx = [];

      let xchk = 0;

      for (var col in dataSourceEx[row]) {
        if (col == "cmumis_id") {
          if (excmumis_id == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (dataSourceEx[row]["cmumis_id"] == excmumis_id) {
              xx.push("");

              xchk = 1;
            } else {
              xx.push(dataSourceEx[row][col]);

              xchk = 0;
            }
          }

          excmumis_id = dataSourceEx[row]["cmumis_id"];
        } else if (col == "name_P") {
          if (exnameP == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exnameP = dataSourceEx[row]["name_P"];
        } else if (col == "name_shortP") {
          if (exname_shortP == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exname_shortP = dataSourceEx[row]["name_shortP"];
        } else if (col == "reseatcher_name") {
          if (exreseatcher_name == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exreseatcher_name = dataSourceEx[row]["reseatcher_name"];
        } else if (col == "start_date") {
          if (exstart_date == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exstart_date = dataSourceEx[row]["start_date"];
        } else if (col == "end_date") {
          if (exend_date == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exend_date = dataSourceEx[row]["end_date"];
        } else if (col == "Sum_budget") {
          if (exSum_budget == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exSum_budget = dataSourceEx[row]["Sum_budget"];
        } else if (col == "status") {
          if (exstatus == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exstatus = dataSourceEx[row]["status"];
        } else {
          xx.push(dataSourceEx[row][col]);
        }
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, xtabtitle + ".xlsx");
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />
      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="รายงานเงินทุนโครงการวิจัยที่ได้รับ ตามปีปฏิทิน (1ม.ค.-31ธ.ค.)"
            />
            <Divider />
          </Col>
        </Row>

        <Spin tip="Loading..." spinning={loading} size="large">
          <Row justify="center" style={{ marginBottom: 10 }}>
            <Col span={23}>
              <div style={{ float: "right", marginBottom: "15px" }}>
                <Button ghost type="success" onClick={bExcelClick}>
                  <FileExcelOutlined /> Excel
                </Button>
              </div>

              <Table
                bordered
                scroll={{ x: "max-content" }}
                dataSource={dataSource1}
                columns={columns1}
                size="small"
                pagination={false}
              />
            </Col>
          </Row>
        </Spin>
      </Content>

      <div style={{ height: "80px" }} />

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_report_received1;
