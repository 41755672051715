import React, { useState } from "react";
import "antd/dist/antd.min.css";
import {
  Row,
  Form,
  Menu,
  Affix,
  Button,
  Space,
  Drawer,
  Card,
  Input,
  Divider,
  Avatar,
  Select
} from "antd";

const Header_patent = () => {
  return (
    <Affix offsetTop={0} className="app__affix-header">
      <nav className="navbar">
        <div
          className="brand-title"
          onClick={() => {
            window.location = "/Research_patent";
          }}
        >
          <Space>
            <img width={45} src="./RIHES_Logo-White.png" />

            <a
              style={{
                fontWeight: "bold",
                color: "white"
              }}
            >
              ระบบบริหารงานวิจัย
            </a>
          </Space>
        </div>
      </nav>
    </Affix>
  );
};
export default Header_patent;
