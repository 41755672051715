import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import axios from "axios";

import { createGlobalStyle } from "styled-components";
import { PaperClipOutlined, FileExcelOutlined } from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  PageHeader,
  Divider,
  Table,
  Typography,
  Button
} from "antd";

import Header from "./Header_patent";
import Footer2 from "./Footer";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import moment from "moment";
import "moment/locale/th";

import * as xlsx from "xlsx";

dayjs.locale("th");
dayjs.extend(buddhistEra);

const { Content } = Layout;
const { Text } = Typography;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Research_patent = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState();
  const [dataSourceEx, _dataSourceEx] = useState();

  const [paginationInfo] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    Patent_Data();
  }, []);

  const Patent_Data = () => {
    axios
      .get(xURL + "data_patent")
      .then(result => {
        const Tb1 = [],
          Tb2 = [];

        let xnum = 0,
          xpatent_name = "";

        result.data.forEach((i, idx) => {
          let xtype = "",
            xrole = "";

          if (i.patent_type == 1) {
            xtype = "อนุสิทธิบัตร";
          } else if (i.patent_type == 2) {
            xtype = "สิทธิบัตร";
          }

          if (i.patent_role == 1) {
            xrole = "ผู้ประดิษฐ์หลัก";
          } else if (i.patent_role == 2) {
            xrole = "ผู้ประดิษฐ์ร่วม";
          }

          let xdate1 = "";

          if (!i.date_input) {
            xdate1 = "";
          } else {
            xdate1 = dayjs(i.date_input).format("DD/MM/BBBB");
          }

          if (i.patent_name !== xpatent_name) {
            xnum = xnum + 1;
          }

          Tb1.push({
            key: idx,
            num: xnum,
            patent_name: i.patent_name,
            patent_type: xtype,
            patent_researchers: i.patent_researchers,
            patent_role: xrole,
            date_input: xdate1,
            patent_request_number: i.patent_request_number
          });

          Tb2.push({
            num: idx + 1,
            patent_name: i.patent_name,
            patent_name_eng: i.patent_name_eng,
            patent_type: xtype,
            patent_researchers: i.patent_researchers,
            patent_role: xrole,
            date_input: xdate1,
            patent_request_number: i.patent_request_number
          });

          xpatent_name = i.patent_name;
        });

        _dataSource1(Tb1);
        _dataSourceEx(Tb2);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bExcel = () => {
    let xdata = [],
      xtabtitle = "",
      header = [];

    xtabtitle = "ทรัพย์สินทางปัญญา";

    header.push(
      "ที่",
      "ชื่อที่แสดงถึงการประดิษฐ์/การออกแบบผลิตภัณฑ์",
      "(eng) ชื่อที่แสดงถึงการประดิษฐ์/การออกแบบผลิตภัณฑ์",
      "ประเภท",
      "ผู้ประดิษฐ์",
      "สถานะผู้ประดิษฐ์",
      "วันที่ยื่นจด",
      "เลขที่คำขอ"
    );

    xdata.push([xtabtitle]);
    xdata.push([""]);
    xdata.push(header);

    for (var row in dataSourceEx) {
      const xx = [];
      for (var col in dataSourceEx[row]) {
        xx.push(dataSourceEx[row][col]);
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "ทรัพย์สินทางปัญญา.xlsx");
  };

  const columns1 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อที่แสดงถึงการประดิษฐ์/การออกแบบผลิตภัณฑ์",
      dataIndex: "patent_name",
      key: "patent_name",
      width: 300,
      // sorter: (a, b) => {
      //   return a.patent_name.localeCompare(b.patent_name);
      // },
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <p style={{ fontWeight: "bold" }}>
              {text}
            </p>
          ),
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ประเภท",
      dataIndex: "patent_type",
      key: "patent_type",
      align: "center",
      width: 100,
      // sorter: (a, b) => {
      //   return a.patent_type.localeCompare(b.patent_type);
      // }
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ผู้ประดิษฐ์",
      dataIndex: "patent_researchers",
      key: "patent_researchers",
      width: 200
      // sorter: (a, b) => {
      //   return a.patent_researchers.localeCompare(b.patent_researchers);
      // }
    },
    {
      title: "สถานะผู้ประดิษฐ์",
      dataIndex: "patent_role",
      key: "patent_role",
      align: "center",
      width: 100
      // sorter: (a, b) => {
      //   return a.patent_role.localeCompare(b.patent_role);
      // }
    },
    {
      title: "วันที่ยื่นจด",
      dataIndex: "date_input",
      key: "date_input",
      align: "center",
      width: 100,
      // sorter: (a, b) => {
      //   return a.date_input.localeCompare(b.date_input);
      // }
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "เลขที่คำขอ",
      dataIndex: "patent_request_number",
      key: "patent_request_number",
      align: "center",
      width: 100,
      // sorter: (a, b) => a.patent_request_number - b.patent_request_number
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.patent_name === dataSource1[trueIndex - 1].patent_name
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.patent_name === dataSource1[trueIndex + i].patent_name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    }
  ];

  return (
    <React.Fragment>
      <StyledForm />
      <Header />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="ทรัพย์สินทางปัญญา"
            />
            <Divider />
          </Col>
        </Row>

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            <Button ghost type="success" onClick={bExcel}>
              <FileExcelOutlined /> Excel
            </Button>
          </Col>
        </Row>

        <Row justify="center" style={{ marginBottom: 10 }}>
          <Col span={23}>
            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={false}
            />
          </Col>
        </Row>
      </Content>

      <div style={{ height: "80px" }} />
      <Footer2 />
    </React.Fragment>
  );
};

export default Research_patent;
