import React, { useEffect, useState } from "react";
import axios from "axios";

import "./css/dashboard.css";

import {
  Row,
  Col,
  Layout,
  Card,
  Divider,
  Space,
  Select,
  Button,
  Form,
  Modal,
  InputNumber
} from "antd";

import moment from "moment";

import { SaveOutlined, ClearOutlined } from "@ant-design/icons";

import swal from "sweetalert";

import Hightchart2 from "./Highchart2";
import Piechart2 from "./Piechart2";

const { Option } = Select;

const Dashboard_r = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  let YearNow = moment().format("YYYY");

  const [YearValue, _YearValue] = useState(YearNow);
  const [xYeardh, _xYeardh] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);

  const [Kr1_old, _Kr1_old] = useState("");
  const [Kr2_old, _Kr2_old] = useState("");
  const [Kr3_old, _Kr3_old] = useState("");
  const [Kr4_old, _Kr4_old] = useState("");
  const [Kr5_old, _Kr5_old] = useState("");
  const [Pub_old, _Pub_old] = useState("");

  const [Num_Color1, _Num_Color1] = useState("red");
  const [Num_Color2, _Num_Color2] = useState("red");
  const [Num_Color3, _Num_Color3] = useState("red");
  const [Num_Color4, _Num_Color4] = useState("red");
  const [Num_Color5, _Num_Color5] = useState("red");
  const [Num_Color6, _Num_Color6] = useState("red");

  const [data_Kr1, _data_Kr1] = useState();
  const [data_Kr2, _data_Kr2] = useState();
  const [dataBeen_scopus, _dataBeen_scopus] = useState();
  const [dataPubPer, _dataPubPer] = useState();
  const [dataLicense, _dataLicense] = useState();
  const [dataPub_scopus, _dataPub_scopus] = useState();

  const [goal_Kr1, _goal_Kr1] = useState();
  const [goal_Kr2, _goal_Kr2] = useState();
  const [goalBeen_scopus, _goalBeen_scopus] = useState();
  const [goalPubPer, _goalPubPer] = useState();
  const [goalLicense, _goalLicense] = useState();
  const [goalPub_scopus, _goalPub_scopus] = useState();

  const [dataSum_Kr1, _dataSum_Kr1] = useState();
  const [dataSum_Kr2, _dataSum_Kr2] = useState();
  const [dataSum_Been_scopus, _dataSum_Been_scopus] = useState();
  const [dataSum_PubPer, _dataSum_PubPer] = useState();
  const [dataSum_Licence, _dataSum_Licence] = useState();
  const [dataSum_Pub_scopus, _dataSum_Pub_scopus] = useState();

  const [dataHigh3, _dataHigh3] = useState([]);
  const [dataHigh5, _dataHigh5] = useState([]);
  const [dataHigh6, _dataHigh6] = useState([]);

  const [dataPie2, _dataPie2] = useState([]);

  useEffect(() => {
    axios
      .get(xURL + "year_papers")
      .then(result => {
        //console.log(result.data);

        _xYeardh(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    Find_scopus("R1", YearValue);
    Find_scopus("R2", YearValue);
    Find_scopus("R3", YearValue);
    Find_scopus("R4", YearValue);
    Find_scopus("R5", YearValue);
    Find_scopus("R6", YearValue);

    Find_highData("R3", YearValue);
    Find_highData("R5", YearValue);
    Find_highData("R6", YearValue);

    Find_PieData("R2", YearValue);
  }, []);

  const handleChange = value => {
    // console.log(`selected ${value}`);
    Find_scopus("R1", value);
    Find_scopus("R2", value);
    Find_scopus("R3", value);
    Find_scopus("R4", value);
    Find_scopus("R5", value);
    Find_scopus("R6", value);

    Find_highData("R3", value);
    Find_highData("R5", value);
    Find_highData("R6", value);

    Find_PieData("R2", value);

    _YearValue(value);
  };

  const bClearclick = () => {
    form1.resetFields();

    findGoal();
  };

  const bSaveclick = () => {
    const f = form1.getFieldsValue();

    if (!f.ipKr1) {
      swal("Save Error!", "Data entry fields KR1 CTU (Goal)!", "warning");
    } else if (!f.ipKr2) {
      swal("Save Error!", "Data entry fields KR2 %Q1 (Goal)!", "warning");
    } else if (!f.ipKr3) {
      swal(
        "Save Error!",
        "Data entry fields KR3 BEEN Pub in SCOPUS (Goal)!",
        "warning"
      );
    } else if (!f.ipKr4) {
      swal(
        "Save Error!",
        "Data entry fields KR4 Pub Per Faculty (Goal)!",
        "warning"
      );
    } else if (!f.ipKr5) {
      swal(
        "Save Error!",
        "Data entry fields KR5 Prototype/License (Goal)!",
        "warning"
      );
    } else if (!f.ipscopus) {
      swal("Save Error!", "Data entry fields Pub in SCOPUS (Goal)!", "warning");
    } else {
      swal({
        title: "Confirm Edit Goal",
        text: "Year " + YearValue,
        icon: "warning",
        buttons: ["Cancle", "Ok"]
      }).then(willOk => {
        if (willOk) {
          updatedata();
        }
      });
    }
  };

  const updatedata = () => {
    const f = form1.getFieldsValue();

    let xdata = [
      { name: "KR1 CTU", goal: f.ipKr1, old: Kr1_old },
      { name: "KR2 %Q1", goal: f.ipKr2, old: Kr2_old },
      { name: "KR3 BEEN Pub in SCOPUS", goal: f.ipKr3, old: Kr3_old },
      { name: "KR4 Pub Per Faculty", goal: f.ipKr4, old: Kr4_old },
      { name: "KR5 Prototype/License", goal: f.ipKr5, old: Kr5_old },
      { name: "Pub in SCOPUS", goal: f.ipscopus, old: Pub_old }
    ];

    // console.log(emplydata);

    xdata.forEach((i, idx) => {
      if (!i.old) {
        axios
          .post(xURL + "insert_goal", {
            year: YearValue,
            name: i.name,
            goal: i.goal
          })
          .then(result => {
            swal("Save Complete!", "", "success");
            setModalOpen(false);

            // console.log(result.data);

            Find_scopus("R1", YearValue);
            Find_scopus("R2", YearValue);
            Find_scopus("R3", YearValue);
            Find_scopus("R4", YearValue);
            Find_scopus("R5", YearValue);
            Find_scopus("R6", YearValue);

            Find_highData("R3", YearValue);
            Find_highData("R5", YearValue);
            Find_highData("R6", YearValue);

            Find_PieData("R2", YearValue);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else {
        axios
          .post(xURL + "update_goal", {
            year: YearValue,
            name: i.name,
            goal: i.goal
          })
          .then(result => {
            swal("Save Complete!", "", "success");
            setModalOpen(false);

            // console.log(result.data);

            Find_scopus("R1", YearValue);
            Find_scopus("R2", YearValue);
            Find_scopus("R3", YearValue);
            Find_scopus("R4", YearValue);
            Find_scopus("R5", YearValue);
            Find_scopus("R6", YearValue);

            Find_highData("R3", YearValue);
            Find_highData("R5", YearValue);
            Find_highData("R6", YearValue);

            Find_PieData("R2", YearValue);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const findGoal = () => {
    _Kr1_old("");
    _Kr2_old("");
    _Kr3_old("");
    _Kr4_old("");
    _Kr5_old("");
    _Pub_old("");

    axios
      .post(xURL + "db_findGoal", { year: YearValue })
      .then(result => {
        // console.log("data", result.data);

        result.data.forEach((i, idx) => {
          if (i.topic == "KR1 CTU") {
            form1.setFieldsValue({
              ipKr1: i.goal
            });

            _Kr1_old(i.goal);
          } else if (i.topic == "KR2 %Q1") {
            form1.setFieldsValue({
              ipKr2: i.goal
            });

            _Kr2_old(i.goal);
          } else if (i.topic == "KR3 BEEN Pub in SCOPUS") {
            form1.setFieldsValue({
              ipKr3: i.goal
            });

            _Kr3_old(i.goal);
          } else if (i.topic == "KR4 Pub Per Faculty") {
            form1.setFieldsValue({
              ipKr4: i.goal
            });

            _Kr4_old(i.goal);
          } else if (i.topic == "KR5 Prototype/License") {
            form1.setFieldsValue({
              ipKr5: i.goal
            });

            _Kr5_old(i.goal);
          } else if (i.topic == "Pub in SCOPUS") {
            form1.setFieldsValue({
              ipscopus: i.goal
            });

            _Pub_old(i.goal);
          }
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_scopus = (order, YearValue) => {
    axios
      .post(xURL + "db_tabbox", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        if (result.data[0]) {
          if (order == "R1") {
            _data_Kr1(0);
            _goal_Kr1(result.data[0].goal);
          } else if (order == "R2") {
            _data_Kr2(result.data[0].pub_data);
            _goal_Kr2(result.data[0].goal);
            _dataSum_Kr2(
              result.data[0].countq1 + "/" + result.data[0].countq1a
            );

            if (
              parseFloat(result.data[0].pub_data) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color2("green");
            } else {
              _Num_Color2("red");
            }
          } else if (order == "R3") {
            _dataBeen_scopus(result.data[0].pub_data);
            _goalBeen_scopus(result.data[0].goal);
            _dataSum_Been_scopus(
              result.data[0].pub_data2 + "/" + result.data[0].pub_data2a
            );

            if (
              parseFloat(result.data[0].pub_data) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color3("green");
            } else {
              _Num_Color3("red");
            }
          } else if (order == "R4") {
            _dataPubPer(result.data[0].pub_data);
            _goalPubPer(result.data[0].goal);
            _dataSum_PubPer(
              result.data[0].countppf + "/" + result.data[0].countppfa
            );

            if (
              parseFloat(result.data[0].pub_data) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color4("green");
            } else {
              _Num_Color4("red");
            }
          } else if (order == "R5") {
            _dataLicense(result.data[0].pub_data);
            _goalLicense(result.data[0].goal);
            _dataSum_Licence(result.data[0].countlicense);

            if (
              parseFloat(result.data[0].pub_data) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color5("green");
            } else {
              _Num_Color5("red");
            }
          } else if (order == "R6") {
            _dataPub_scopus(result.data[0].pub_data);
            _goalPub_scopus(result.data[0].goal);
            _dataSum_Pub_scopus(result.data[0].pub_data2);

            if (
              parseFloat(result.data[0].pub_data2) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color6("green");
            } else {
              _Num_Color6("red");
            }
          }
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_highData = (order, YearValue) => {
    axios
      .post(xURL + "db_Highdata2", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        let Tb1 = [];

        let xsum = 0;

        let xgoal12 = 0,
          xgoal11 = 0,
          xgoal10 = 0,
          xgoal9 = 0,
          xgoal8 = 0,
          xgoal7 = 0,
          xgoal6 = 0,
          xgoal5 = 0,
          xgoal4 = 0,
          xgoal3 = 0,
          xgoal2 = 0,
          xgoal1 = 0;

        xgoal12 = parseFloat(result.data[0].goal).toFixed(1);
        xgoal11 = parseFloat(xgoal12 - xgoal12 / 12).toFixed(1);
        xgoal10 = parseFloat(xgoal11 - xgoal12 / 12).toFixed(1);
        xgoal9 = parseFloat(xgoal10 - xgoal12 / 12).toFixed(1);
        xgoal8 = parseFloat(xgoal9 - xgoal12 / 12).toFixed(1);
        xgoal7 = parseFloat(xgoal8 - xgoal12 / 12).toFixed(1);
        xgoal6 = parseFloat(xgoal7 - xgoal12 / 12).toFixed(1);
        xgoal5 = parseFloat(xgoal6 - xgoal12 / 12).toFixed(1);
        xgoal4 = parseFloat(xgoal5 - xgoal12 / 12).toFixed(1);
        xgoal3 = parseFloat(xgoal4 - xgoal12 / 12).toFixed(1);
        xgoal2 = parseFloat(xgoal3 - xgoal12 / 12).toFixed(1);
        xgoal1 = parseFloat(xgoal2 - xgoal12 / 12).toFixed(1);

        if (result.data[0]) {
          xsum = result.data[0].m1;
          Tb1.push({
            name: "1",
            data_in_Month: result.data[0].m1,
            data: xsum,
            goal: xgoal1
          });

          xsum = xsum + result.data[0].m2;
          Tb1.push({
            name: "2",
            data_in_Month: result.data[0].m2,
            data: xsum,
            goal: xgoal2
          });

          xsum = xsum + result.data[0].m3;
          Tb1.push({
            name: "3",
            data_in_Month: result.data[0].m3,
            data: xsum,
            goal: xgoal3
          });

          xsum = xsum + result.data[0].m4;
          Tb1.push({
            name: "4",
            data_in_Month: result.data[0].m4,
            data: xsum,
            goal: xgoal4
          });

          xsum = xsum + result.data[0].m5;
          Tb1.push({
            name: "5",
            data_in_Month: result.data[0].m5,
            data: xsum,
            goal: xgoal5
          });

          xsum = xsum + result.data[0].m6;
          Tb1.push({
            name: "6",
            data_in_Month: result.data[0].m6,
            data: xsum,
            goal: xgoal6
          });

          xsum = xsum + result.data[0].m7;
          Tb1.push({
            name: "7",
            data_in_Month: result.data[0].m7,
            data: xsum,
            goal: xgoal7
          });

          xsum = xsum + result.data[0].m8;
          Tb1.push({
            name: "8",
            data_in_Month: result.data[0].m8,
            data: xsum,
            goal: xgoal8
          });

          xsum = xsum + result.data[0].m9;
          Tb1.push({
            name: "9",
            data_in_Month: result.data[0].m9,
            data: xsum,
            goal: xgoal9
          });

          xsum = xsum + result.data[0].m10;
          Tb1.push({
            name: "10",
            data_in_Month: result.data[0].m10,
            data: xsum,
            goal: xgoal10
          });

          xsum = xsum + result.data[0].m11;
          Tb1.push({
            name: "11",
            data_in_Month: result.data[0].m11,
            data: xsum,
            goal: xgoal11
          });

          xsum = xsum + result.data[0].m12;
          Tb1.push({
            name: "12",
            data_in_Month: result.data[0].m12,
            data: xsum,
            goal: result.data[0].goal
          });
        }

        if (order == "R3") {
          _dataHigh3(Tb1);
        } else if (order == "R5") {
          _dataHigh5(Tb1);
        } else if (order == "R6") {
          _dataHigh6(Tb1);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_PieData = (order, YearValue) => {
    axios
      .post(xURL + "db_Piedata2", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        let Tb1 = [];
        if (result.data[0]) {
          if (order == "R2") {
            Tb1.push({
              name: "Q1",
              value: result.data[0].q1,
              value2: result.data[0].q1Count
            });
            Tb1.push({
              name: "Q2",
              value: result.data[0].q2,
              value2: result.data[0].q2Count
            });
            Tb1.push({
              name: "Q3",
              value: result.data[0].q3,
              value2: result.data[0].q3Count
            });
            Tb1.push({
              name: "Q4",
              value: result.data[0].q4,
              value2: result.data[0].q4Count
            });
            Tb1.push({
              name: "N/A",
              value: result.data[0].na,
              value2: result.data[0].naCount
            });

            _dataPie2(Tb1);
          }
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <div>
        <h1 className="rise">R</h1>
      </div>

      <Row>
        <div>
          <Form.Item label="Year" name="slyear">
            <Select
              defaultValue={YearNow}
              style={{
                width: 250,
                marginBottom: "20px"
              }}
              onChange={handleChange}
            >
              {xYeardh.map((i, idx) => {
                return (
                  <Option value={i.year}>
                    {i.year} ({i.name})
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>

        <div style={{ marginLeft: "25px" }}>
          <Button
            type="primary"
            onClick={() => {
              form1.resetFields();

              findGoal();

              setModalOpen(true);
            }}
          >
            Edit Goal
          </Button>
        </div>
      </Row>

      <Row gutter={3}>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>KR1 CTU</p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: Num_Color1
                    }}
                  >
                    {data_Kr1}
                  </p>
                </Row>
              </Col>

              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_Kr1}
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>KR2 %Q1</p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />

                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color2
                      }}
                    >
                      {data_Kr2}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_Kr2} %
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_Kr2})
              </p>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "10px" }}>
                  KR3 BEEN Pub in SCOPUS
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color3
                      }}
                    >
                      {dataBeen_scopus}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goalBeen_scopus} %
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_Been_scopus})
              </p>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR4 Pub Per Faculty
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: Num_Color4
                    }}
                  >
                    {dataPubPer}
                  </p>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goalPubPer}
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_PubPer})
              </p>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR5 Prototype/License
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: "20px",
                      marginTop: "12px",
                      fontSize: "40px",
                      color: Num_Color5
                    }}
                  >
                    {dataLicense}
                  </p>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goalLicense}
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_Licence})
              </p>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  Pub in SCOPUS
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color6
                      }}
                    >
                      {dataPub_scopus}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goalPub_scopus}
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_Pub_scopus})
              </p>
            </Row>
          </Card>
        </Col>
      </Row>

      <div style={{ height: "10px" }} />

      <Row gutter={16}>
        <Col xs={23} sm={23} md={12} lg={8} xl={8}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              BEEN Pub in SCOPUS
            </p>
            <Hightchart2 dataHigh={dataHigh3} order={1} />
          </Card>
        </Col>
        <Col xs={23} sm={23} md={12} lg={8} xl={8}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              Prototype/License
            </p>
            <Hightchart2 dataHigh={dataHigh5} order={1} />
          </Card>
        </Col>
        <Col xs={23} sm={23} md={12} lg={8} xl={8}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              Pub in SCOPUS
            </p>
            <Hightchart2 dataHigh={dataHigh6} order={1} />
          </Card>
        </Col>
      </Row>

      <div style={{ height: "10px" }} />

      <Row gutter={16}>
        <Col xs={23} sm={23} md={24} lg={24} xl={24}>
          <Card style={{ height: 320 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              %Quarties
            </p>

            <Piechart2 dataPie={dataPie2} />
          </Card>
        </Col>
      </Row>

      <div style={{ height: "50px" }} />

      <Modal
        title="Edit Goal"
        centered
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={null}
        width={1000}
      >
        <p style={{ fontWeight: "bold", fontSize: "18px" }}>
          Year {YearValue}
        </p>
        <hr className="style-two" style={{ marginBottom: "20px" }} />

        <Form form={form1} initialValues={{}}>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR1 CTU</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr1">
                  <InputNumber
                    autoFocus
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR2 %Q1</p>
            </Col>
            <Col span={3}>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr2">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              <p> %</p>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR3 BEEN Pub in SCOPUS</p>
            </Col>
            <Col span={3}>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr3">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col>
              <p> %</p>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR4 Pub Per Faculty</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr4">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR5 Prototype/License</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr5">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">Pub in SCOPUS</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipscopus">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>

        <hr className="style-two" style={{ marginBottom: "20px" }} />

        <Row justify="center">
          <Space>
            <Button
              type="primary"
              onClick={() => {
                bSaveclick();
              }}
            >
              <SaveOutlined /> Save
            </Button>

            <Button
              type="danger"
              onClick={() => {
                bClearclick();
              }}
            >
              <ClearOutlined /> Reset
            </Button>
          </Space>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default Dashboard_r;
