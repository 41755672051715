import React, { useState, useEffect } from "react";
import axios from "axios";

import Header2 from "../pagenoaut/Header2";
import Footer_last from "../controls/Footer_last";

import {
  Layout,
  Row,
  Col,
  Divider,
  PageHeader,
  Card,
  Typography,
  Table,
  Input
} from "antd";

import { createGlobalStyle } from "styled-components";

const { Content } = Layout;
const { Text } = Typography;
const { Search } = Input;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Research_manage_scopus_noaut = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xtitle, _xtitle] = useState("");

  const [dataSource1, _dataSource1] = useState([]);

  const [pageTable, _pageTable] = useState(1);
  const [pageSizeTable, _pageSizeTable] = useState(20);

  const [xHead, _xHead] = useState("");
  const [xLng, _xLng] = useState();

  useEffect(() => {
    var url = new URL(window.location.href);

    if (url.searchParams.get("pages") == "IDSU") {
      _xHead("ศูนย์วิจัยโรคติดเชื้อและสารเสพติด");
      _xLng("IDSU");
    } else if (url.searchParams.get("pages") == "CMBI") {
      _xHead("ศูนย์วิจัยชีวโมเลกุลและเซลล์วิทยา");
      _xLng("CMBI");
    } else if (url.searchParams.get("pages") == "NINE") {
      _xHead("ศูนย์วิจัยโรคไม่ติดเชื้อและอนามัยสิ่งแวดล้อม");
      _xLng("NINE");
    } else if (url.searchParams.get("pages") == "SHSR") {
      _xHead("สำนักวิชาการวิจัยวิทยาศาสตร์สุขภาพ");
      _xLng("SHSR");
    }

    getData(url.searchParams.get("pages"));
  }, []);

  const getData = pages => {
    axios
      .post(xURL + "postAll_scopus", { center: pages })
      .then(result => {
        const Tb1 = [];
        let sumscopus = 0;

        result.data.forEach((i, idx) => {
          Tb1.push({
            key: idx,
            no: idx + 1,
            title: i.title,
            year: i.year,
            source: i.journal_name,
            link: i.link
          });

          sumscopus = sumscopus + 1;
        });

        _dataSource1(Tb1);

        _xtitle(
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "15px",
              color: "white"
            }}
          >
            {sumscopus} documents found
          </Text>
        );
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const columns1 = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 800,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                window.open(record.link, "_blank");
              }}
            >
              {text}
            </a>
          )
        };
      },
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      }
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 200,
      sorter: (a, b) => a.year - b.year
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: 400,
      align: "center",
      render: (text, record) => __TBShowText(record.source, 0, 1),
      sorter: (a, b) => {
        return a.source.localeCompare(b.source);
      }
    }
  ];

  const onSearch = value => {
    // console.log(value);
    if (value == "") {
      getData(xLng);
    } else {
      axios
        .post(xURL + "postSearch_scopus", { textsearch: value, center: xLng })
        .then(result => {
          const Tb1 = [];
          let sumscopus = 0;

          result.data.forEach((i, idx) => {
            Tb1.push({
              key: idx,
              no: idx + 1,
              title: i.title,
              year: i.year,
              source: i.journal_name,
              link: i.link
            });

            sumscopus = sumscopus + 1;
          });

          _dataSource1(Tb1);

          _xtitle(
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                color: "white"
              }}
            >
              {sumscopus} documents found
            </Text>
          );
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const pageOnChange = (zpage, zpageSize) => {
    _pageTable(zpage);
    _pageSizeTable(zpageSize);
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />

      <Header2 />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            ผลงานวิจัยตีพิมพ์ {xHead}
          </p>
        </Row>
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <Search
              placeholder="input search text"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={onSearch}
              style={{ marginBottom: "25px", width: "400px" }}
            />

            <Card
              title={xtitle}
              headStyle={{
                backgroundColor: "#00474f"
              }}
              style={{
                //margin: "10px",
                borderRadius: "15px",
                overflow: "hidden"
              }}
            >
              <Table
                bordered
                scroll={{ x: "max-content" }}
                dataSource={dataSource1}
                columns={columns1}
                size="small"
                pagination={{
                  onChange: (zpage, zpageSize) => {
                    pageOnChange(zpage, zpageSize);
                  },
                  current: pageTable,
                  pageSize: pageSizeTable
                }}
              />
            </Card>
          </Col>
        </Row>
      </Content>

      <div style={{ height: "200px" }} />

      <Footer_last />
    </React.Fragment>
  );
};

export default Research_manage_scopus_noaut;
