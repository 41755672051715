import React, { useEffect, useState } from "react";
import axios from "axios";

import "./css/dashboard.css";

import {
  Row,
  Col,
  Layout,
  Card,
  Divider,
  Space,
  Select,
  Button,
  Form,
  Modal,
  InputNumber
} from "antd";

import moment from "moment";

import { SaveOutlined, ClearOutlined } from "@ant-design/icons";

import swal from "sweetalert";

import Hightchart2 from "./Highchart2";
import Piechart from "./Piechart";

const { Option } = Select;

const Dashboard_s = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  let YearNow = moment().format("YYYY");

  const [YearValue, _YearValue] = useState(YearNow);
  const [xYeardh, _xYeardh] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);

  const [Kr12_old, _Kr12_old] = useState("");
  const [Kr13_old, _Kr13_old] = useState("");
  const [IntF_old, _IntF_old] = useState("");
  const [ThF_old, _ThF_old] = useState("");
  const [CmuF_old, _CmuF_old] = useState("");

  const [Num_Color1, _Num_Color1] = useState("red");
  const [Num_Color2, _Num_Color2] = useState("red");
  const [Num_Color3, _Num_Color3] = useState("red");
  const [Num_Color4, _Num_Color4] = useState("red");
  const [Num_Color5, _Num_Color5] = useState("red");

  const [data_Kr12, _data_Kr12] = useState();
  const [data_Kr13, _data_Kr13] = useState();
  const [data_IntF, _data_IntF] = useState();
  const [data_ThF, _data_ThF] = useState();
  const [data_CmuF, _data_CmuF] = useState();

  const [goal_Kr12, _goal_Kr12] = useState();
  const [goal_Kr13, _goal_Kr13] = useState();
  const [goal_IntF, _goal_IntF] = useState();
  const [goal_ThF, _goal_ThF] = useState();
  const [goal_CmuF, _goal_CmuF] = useState();

  const [dataSum_Kr12, _dataSum_Kr12] = useState();
  const [dataSum_Kr13, _dataSum_Kr13] = useState();
  const [dataSum_IntF, _dataSum_IntF] = useState();
  const [dataSum_ThF, _dataSum_ThF] = useState();
  const [dataSum_CmuF, _dataSum_CmuF] = useState();

  const [dataHigh1, _dataHigh1] = useState([]);
  const [dataHigh2, _dataHigh2] = useState([]);
  const [dataHigh3, _dataHigh3] = useState([]);
  const [dataHigh4, _dataHigh4] = useState([]);
  const [dataHigh5, _dataHigh5] = useState([]);

  const [dataPie1, _dataPie1] = useState([]);
  const [dataPie2, _dataPie2] = useState([]);

  useEffect(() => {
    axios
      .get(xURL + "year_papers")
      .then(result => {
        //console.log(result.data);

        _xYeardh(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    Find_scopus("S1", YearValue);
    Find_scopus("S2", YearValue);
    Find_scopus("S3", YearValue);
    Find_scopus("S4", YearValue);
    Find_scopus("S5", YearValue);

    Find_highData("S1", YearValue);
    Find_highData("S2", YearValue);
    Find_highData("S3", YearValue);
    Find_highData("S4", YearValue);
    Find_highData("S5", YearValue);

    Find_PieData("S1", YearValue);
    Find_PieData("S2", YearValue);
  }, []);

  const handleChange = value => {
    // console.log(`selected ${value}`);
    Find_scopus("S1", value);
    Find_scopus("S2", value);
    Find_scopus("S3", value);
    Find_scopus("S4", value);
    Find_scopus("S5", value);

    Find_highData("S1", value);
    Find_highData("S2", value);
    Find_highData("S3", value);
    Find_highData("S4", value);
    Find_highData("S5", value);

    Find_PieData("S1", value);
    Find_PieData("S2", value);

    _YearValue(value);
  };

  const bClearclick = () => {
    form1.resetFields();

    findGoal();
  };

  const bSaveclick = () => {
    const f = form1.getFieldsValue();

    if (!f.ipKr12) {
      swal(
        "Save Error!",
        "Data entry fields KR12 Total Research Funding (Goal)!",
        "warning"
      );
    } else if (!f.ipKr13) {
      swal(
        "Save Error!",
        "Data entry fields KR13 Other Income (Goal)!",
        "warning"
      );
    } else if (!f.ipIntF) {
      swal("Save Error!", "Data entry fields Int. funding (Goal)!", "warning");
    } else if (!f.ipThF) {
      swal("Save Error!", "Data entry fields Thai. funding (Goal)!", "warning");
    } else if (!f.ipCmuF) {
      swal("Save Error!", "Data entry fields CMU funding (Goal)!", "warning");
    } else {
      swal({
        title: "Confirm Edit Goal",
        text: "Year " + YearValue,
        icon: "warning",
        buttons: ["Cancle", "Ok"]
      }).then(willOk => {
        if (willOk) {
          updatedata();
        }
      });
    }
  };

  const updatedata = () => {
    const f = form1.getFieldsValue();

    let xdata = [
      { name: "KR12 Total Research Funding", goal: f.ipKr12, old: Kr12_old },
      { name: "KR13 Other Income", goal: f.ipKr13, old: Kr13_old },
      { name: "Int. funding", goal: f.ipIntF, old: IntF_old },
      { name: "Thai. funding", goal: f.ipThF, old: ThF_old },
      { name: "CMU funding", goal: f.ipCmuF, old: CmuF_old }
    ];

    // console.log(emplydata);

    xdata.forEach((i, idx) => {
      if (!i.old) {
        axios
          .post(xURL + "insert_goal", {
            year: YearValue,
            name: i.name,
            goal: i.goal
          })
          .then(result => {
            swal("Save Complete!", "", "success");
            setModalOpen(false);

            // console.log(result.data);

            Find_scopus("S1", YearValue);
            Find_scopus("S2", YearValue);
            Find_scopus("S3", YearValue);
            Find_scopus("S4", YearValue);
            Find_scopus("S5", YearValue);

            Find_highData("S1", YearValue);
            Find_highData("S2", YearValue);
            Find_highData("S3", YearValue);
            Find_highData("S4", YearValue);
            Find_highData("S5", YearValue);

            Find_PieData("S1", YearValue);
            Find_PieData("S2", YearValue);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      } else {
        axios
          .post(xURL + "update_goal", {
            year: YearValue,
            name: i.name,
            goal: i.goal
          })
          .then(result => {
            swal("Save Complete!", "", "success");
            setModalOpen(false);

            // console.log(result.data);

            Find_scopus("S1", YearValue);
            Find_scopus("S2", YearValue);
            Find_scopus("S3", YearValue);
            Find_scopus("S4", YearValue);
            Find_scopus("S5", YearValue);

            Find_highData("S1", YearValue);
            Find_highData("S2", YearValue);
            Find_highData("S3", YearValue);
            Find_highData("S4", YearValue);
            Find_highData("S5", YearValue);

            Find_PieData("S1", YearValue);
            Find_PieData("S2", YearValue);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const findGoal = () => {
    _Kr12_old("");
    _Kr13_old("");
    _IntF_old("");
    _ThF_old("");
    _CmuF_old("");

    axios
      .post(xURL + "db_findGoal", { year: YearValue })
      .then(result => {
        // console.log("data", result.data);

        result.data.forEach((i, idx) => {
          if (i.topic == "KR12 Total Research Funding") {
            form1.setFieldsValue({
              ipKr12: i.goal
            });

            _Kr12_old(i.goal);
          } else if (i.topic == "KR13 Other Income") {
            form1.setFieldsValue({
              ipKr13: i.goal
            });

            _Kr13_old(i.goal);
          } else if (i.topic == "Int. funding") {
            form1.setFieldsValue({
              ipIntF: i.goal
            });

            _IntF_old(i.goal);
          } else if (i.topic == "Thai. funding") {
            form1.setFieldsValue({
              ipThF: i.goal
            });

            _ThF_old(i.goal);
          } else if (i.topic == "CMU funding") {
            form1.setFieldsValue({
              ipCmuF: i.goal
            });

            _CmuF_old(i.goal);
          }
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_scopus = (order, YearValue) => {
    axios
      .post(xURL + "db_tabbox", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        if (result.data[0]) {
          if (order == "S1") {
            _data_Kr12(result.data[0].pub_data);
            _goal_Kr12(result.data[0].goal);
            _dataSum_Kr12(
              result.data[0].total_receive.toLocaleString(undefined, {
                minimumFractionDigits: 2
              })
            );

            if (
              parseFloat(result.data[0].pub_data2) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color1("green");
            } else {
              _Num_Color1("red");
            }
          } else if (order == "S2") {
            _data_Kr13(result.data[0].pub_data);
            _goal_Kr13(result.data[0].goal);
            _dataSum_Kr13(
              result.data[0].amount.toLocaleString(undefined, {
                minimumFractionDigits: 2
              })
            );

            if (
              parseFloat(result.data[0].pub_data2) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color2("green");
            } else {
              _Num_Color2("red");
            }
          } else if (order == "S3") {
            _data_IntF(result.data[0].pub_data);
            _goal_IntF(result.data[0].goal);
            _dataSum_IntF(
              result.data[0].total_receive.toLocaleString(undefined, {
                minimumFractionDigits: 2
              })
            );

            if (
              parseFloat(result.data[0].pub_data2) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color3("green");
            } else {
              _Num_Color3("red");
            }
          } else if (order == "S4") {
            _data_ThF(result.data[0].pub_data);
            _goal_ThF(result.data[0].goal);
            _dataSum_ThF(
              result.data[0].total_receive.toLocaleString(undefined, {
                minimumFractionDigits: 2
              })
            );

            if (
              parseFloat(result.data[0].pub_data2) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color4("green");
            } else {
              _Num_Color4("red");
            }
          } else if (order == "S5") {
            _data_CmuF(result.data[0].pub_data);
            _goal_CmuF(result.data[0].goal);
            _dataSum_CmuF(
              result.data[0].total_receive.toLocaleString(undefined, {
                minimumFractionDigits: 2
              })
            );

            if (
              parseFloat(result.data[0].pub_data2) >=
              parseFloat(result.data[0].goal)
            ) {
              _Num_Color5("green");
            } else {
              _Num_Color5("red");
            }
          }
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_highData = (order, YearValue) => {
    axios
      .post(xURL + "db_Highdata2", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        let Tb1 = [];

        let xsum = 0;

        let xgoal12 = 0,
          xgoal11 = 0,
          xgoal10 = 0,
          xgoal9 = 0,
          xgoal8 = 0,
          xgoal7 = 0,
          xgoal6 = 0,
          xgoal5 = 0,
          xgoal4 = 0,
          xgoal3 = 0,
          xgoal2 = 0,
          xgoal1 = 0;

        xgoal12 = parseFloat(result.data[0].goal).toFixed(1);
        xgoal11 = parseFloat(xgoal12 - xgoal12 / 12).toFixed(1);
        xgoal10 = parseFloat(xgoal11 - xgoal12 / 12).toFixed(1);
        xgoal9 = parseFloat(xgoal10 - xgoal12 / 12).toFixed(1);
        xgoal8 = parseFloat(xgoal9 - xgoal12 / 12).toFixed(1);
        xgoal7 = parseFloat(xgoal8 - xgoal12 / 12).toFixed(1);
        xgoal6 = parseFloat(xgoal7 - xgoal12 / 12).toFixed(1);
        xgoal5 = parseFloat(xgoal6 - xgoal12 / 12).toFixed(1);
        xgoal4 = parseFloat(xgoal5 - xgoal12 / 12).toFixed(1);
        xgoal3 = parseFloat(xgoal4 - xgoal12 / 12).toFixed(1);
        xgoal2 = parseFloat(xgoal3 - xgoal12 / 12).toFixed(1);
        xgoal1 = parseFloat(xgoal2 - xgoal12 / 12).toFixed(1);

        if (result.data[0]) {
          xsum = result.data[0].m1;
          Tb1.push({
            name: "1",
            data_in_Month: result.data[0].m1,
            data: xsum,
            goal: xgoal1
          });

          xsum = xsum + result.data[0].m2;
          Tb1.push({
            name: "2",
            data_in_Month: result.data[0].m2,
            data: xsum,
            goal: xgoal2
          });

          xsum = xsum + result.data[0].m3;
          Tb1.push({
            name: "3",
            data_in_Month: result.data[0].m3,
            data: xsum,
            goal: xgoal3
          });

          xsum = xsum + result.data[0].m4;
          Tb1.push({
            name: "4",
            data_in_Month: result.data[0].m4,
            data: xsum,
            goal: xgoal4
          });

          xsum = xsum + result.data[0].m5;
          Tb1.push({
            name: "5",
            data_in_Month: result.data[0].m5,
            data: xsum,
            goal: xgoal5
          });

          xsum = xsum + result.data[0].m6;
          Tb1.push({
            name: "6",
            data_in_Month: result.data[0].m6,
            data: xsum,
            goal: xgoal6
          });

          xsum = xsum + result.data[0].m7;
          Tb1.push({
            name: "7",
            data_in_Month: result.data[0].m7,
            data: xsum,
            goal: xgoal7
          });

          xsum = xsum + result.data[0].m8;
          Tb1.push({
            name: "8",
            data_in_Month: result.data[0].m8,
            data: xsum,
            goal: xgoal8
          });

          xsum = xsum + result.data[0].m9;
          Tb1.push({
            name: "9",
            data_in_Month: result.data[0].m9,
            data: xsum,
            goal: xgoal9
          });

          xsum = xsum + result.data[0].m10;
          Tb1.push({
            name: "10",
            data_in_Month: result.data[0].m10,
            data: xsum,
            goal: xgoal10
          });

          xsum = xsum + result.data[0].m11;
          Tb1.push({
            name: "11",
            data_in_Month: result.data[0].m11,
            data: xsum,
            goal: xgoal11
          });

          xsum = xsum + result.data[0].m12;
          Tb1.push({
            name: "12",
            data_in_Month: result.data[0].m12,
            data: xsum,
            goal: result.data[0].goal
          });
        }

        if (order == "S1") {
          _dataHigh1(Tb1);
        } else if (order == "S2") {
          _dataHigh2(Tb1);
        } else if (order == "S3") {
          _dataHigh3(Tb1);
        } else if (order == "S4") {
          _dataHigh4(Tb1);
        } else if (order == "S5") {
          _dataHigh5(Tb1);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Find_PieData = (order, YearValue) => {
    axios
      .post(xURL + "db_Piedata2", {
        order: order,
        year: YearValue,
        year2: parseInt(YearValue) + 543
      })
      .then(result => {
        let Tb1 = [];
        if (result.data[0]) {
          if (order == "S1") {
            Tb1.push({
              name: "Int. fund",
              value: result.data[0].int_fund,
              value2:
                (result.data[0].int_fundCount / 1000000).toFixed(2) + " MB"
            });
            Tb1.push({
              name: "CMU fund",
              value: result.data[0].cmu_fund,
              value2:
                (result.data[0].cmu_fundCount / 1000000).toFixed(2) + " MB"
            });
            Tb1.push({
              name: "Thai fund",
              value: result.data[0].thai_fund,
              value2:
                (result.data[0].thai_fundCount / 1000000).toFixed(2) + " MB"
            });

            _dataPie1(Tb1);
          } else if (order == "S2") {
            Tb1.push({
              name: "IDSU",
              value: result.data[0].idsu,
              value2: result.data[0].idsuCount + " MB"
            });
            Tb1.push({
              name: "NINE",
              value: result.data[0].nine,
              value2: result.data[0].nineCount + " MB"
            });
            Tb1.push({
              name: "CMBID",
              value: result.data[0].cmbid,
              value2: result.data[0].cmbidCount + " MB"
            });
            Tb1.push({
              name: "RSO",
              value: result.data[0].rso,
              value2: result.data[0].rsoCount + " MB"
            });
            Tb1.push({
              name: "SHSR",
              value: result.data[0].shsr,
              value2: result.data[0].shsrCount + " MB"
            });

            _dataPie2(Tb1);
          }
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <div>
        <h1 className="rise">S</h1>
      </div>

      <Row>
        <div>
          <Form.Item label="Year" name="slyear">
            <Select
              defaultValue={YearNow}
              style={{
                width: 250,
                marginBottom: "20px"
              }}
              onChange={handleChange}
            >
              {xYeardh.map((i, idx) => {
                return (
                  <Option value={i.year}>
                    {i.year} ({i.name})
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div style={{ marginLeft: "25px" }}>
          <Button
            type="primary"
            onClick={() => {
              form1.resetFields();

              findGoal();

              setModalOpen(true);
            }}
          >
            Edit Goal
          </Button>
        </div>
      </Row>

      <Row gutter={3}>
        <Col xs={23} sm={12} md={12} lg={8} xl={6}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR12 Total Funding
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color1
                      }}
                    >
                      {data_Kr12}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>

              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_Kr12}
                </p>
              </Col>
            </Row>

            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_Kr12})
              </p>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={6}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  KR13 Other Income
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color2
                      }}
                    >
                      {data_Kr13}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_Kr13}
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_Kr13})
              </p>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  Int. funding
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color3
                      }}
                    >
                      {data_IntF}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_IntF}
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_IntF})
              </p>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  Thai. funding
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color4
                      }}
                    >
                      {data_ThF}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_ThF}
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_ThF})
              </p>
            </Row>
          </Card>
        </Col>
        <Col xs={23} sm={12} md={12} lg={8} xl={4}>
          <Card style={{ height: 140, marginBottom: "3px" }}>
            <Row style={{ marginRight: "-15px", marginTop: "-15px" }}>
              <Col span={18}>
                <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                  CMU funding
                </p>
                <hr style={{ marginRight: "10px", marginTop: "-5px" }} />
                <Row>
                  <Col span={18}>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginRight: "20px",
                        marginTop: "12px",
                        fontSize: "40px",
                        color: Num_Color5
                      }}
                    >
                      {data_CmuF}
                    </p>
                  </Col>
                  <Col span={3}>
                    <p
                      style={{
                        fontWeight: "bold",
                        // marginRight: "20px",
                        marginLeft: "7px",
                        marginTop: "40px",
                        fontSize: "16px"
                      }}
                    >
                      %
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} style={{ background: "#1679AB", height: "120px" }}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "25px"
                  }}
                >
                  Goal
                </p>

                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold"
                  }}
                >
                  {goal_CmuF}
                </p>
              </Col>
            </Row>
            <Row>
              <p
                style={{
                  fontWeight: "bold",
                  marginTop: "-50px",
                  color: "#baa6a6"
                }}
              >
                ({dataSum_CmuF})
              </p>
            </Row>
          </Card>
        </Col>
      </Row>

      <div style={{ height: "10px" }} />

      <Row gutter={16}>
        <Col xs={23} sm={23} md={12} lg={12} xl={8}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              KR12 Total Research Funding
            </p>
            <Hightchart2 dataHigh={dataHigh1} order={1} />
          </Card>
        </Col>
        <Col xs={23} sm={23} md={12} lg={12} xl={8}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              KR13 Other Income
            </p>
            <Hightchart2 dataHigh={dataHigh2} order={1} />
          </Card>
        </Col>
        <Col xs={23} sm={23} md={12} lg={12} xl={8}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              Int. funding
            </p>
            <Hightchart2 dataHigh={dataHigh3} order={1} />
          </Card>
        </Col>
      </Row>

      <div style={{ height: "10px" }} />

      <Row gutter={16}>
        <Col xs={23} sm={23} md={12} lg={12} xl={12}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              Thai. funding
            </p>
            <Hightchart2 dataHigh={dataHigh4} order={1} />
          </Card>
        </Col>

        <Col xs={23} sm={23} md={12} lg={12} xl={12}>
          <Card style={{ height: 280 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              CMU funding
            </p>
            <Hightchart2 dataHigh={dataHigh5} order={1} />
          </Card>
        </Col>
      </Row>

      <div style={{ height: "10px" }} />

      <Row gutter={16}>
        <Col xs={23} sm={23} md={24} lg={12} xl={12}>
          <Card style={{ height: 320 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              %Funding
            </p>

            <Piechart dataPie={dataPie1} />
          </Card>
        </Col>
        <Col xs={23} sm={23} md={24} lg={12} xl={12}>
          <Card style={{ height: 320 }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold"
              }}
            >
              %Income
            </p>

            <Piechart dataPie={dataPie2} />
          </Card>
        </Col>
      </Row>

      <div style={{ height: "50px" }} />

      <Modal
        title="Edit Goal"
        centered
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={null}
        width={1000}
      >
        <p style={{ fontWeight: "bold", fontSize: "18px" }}>
          Year {YearValue}
        </p>
        <hr className="style-two" style={{ marginBottom: "20px" }} />

        <Form form={form1} initialValues={{}}>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR12 Total Research Funding</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr12">
                  <InputNumber
                    autoFocus
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">KR13 Other Income</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipKr13">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">Int. funding</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipIntF">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">Thai. funding</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipThF">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <p className="t-goal">CMU funding</p>
            </Col>
            <Col>
              <div style={{ marginTop: "-5px" }}>
                <Form.Item label="" name="ipCmuF">
                  <InputNumber
                    min={1}
                    max={1000}
                    maxLength="4"
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>

        <hr className="style-two" style={{ marginBottom: "20px" }} />

        <Row justify="center">
          <Space>
            <Button
              type="primary"
              onClick={() => {
                bSaveclick();
              }}
            >
              <SaveOutlined /> Save
            </Button>

            <Button
              type="danger"
              onClick={() => {
                bClearclick();
              }}
            >
              <ClearOutlined /> Reset
            </Button>
          </Space>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default Dashboard_s;
