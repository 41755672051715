import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Table,
  Modal,
  BackTop,
  PageHeader,
  Divider,
  Space,
  Form,
  Select
} from "antd";
import Button from "antd-button-color";

import { FileExcelOutlined } from "@ant-design/icons";

import { createGlobalStyle } from "styled-components";

import moment from "moment";
import "moment/locale/th";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import * as xlsx from "xlsx";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const Research_scopus_list = props => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xtitle, _xtitle] = useState("");
  const [xtitle2, _xtitle2] = useState("");
  const [xtitle3, _xtitle3] = useState("");

  const [dataSource1, _dataSource1] = useState([]);
  const [dataSource2, _dataSource2] = useState([]);
  const [dataSource3, _dataSource3] = useState([]);

  const [paginationInfo] = useState({
    current: 1,
    pageSize: 10
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const [xtitleex, _xtitleex] = useState("");

  const [xresearchex, _xresearchex] = useState("");
  const [xjournalex, _xjournalex] = useState("");

  const [xYear, _xYear] = useState("");
  const [xYearValue, _xYearValue] = useState("");

  const [zhead, _zhead] = useState("");
  const [ztype, _ztype] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          var url = new URL(window.location.href);

          let xhead = "";

          if (url.searchParams.get("head") === "All") {
            xhead = "ทั้งหมด";
          } else {
            xhead = url.searchParams.get("head");
          }

          if (url.searchParams.get("year") == "All") {
            if (url.searchParams.get("head") == "All") {
              _xtitle(
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white"
                  }}
                >
                  จำนวนผลงานตีพิมพ์ใน SCOPUS ({xhead}) จำนวน{" "}
                  {url.searchParams.get("count")} เรื่อง
                </Text>
              );

              _xtitleex(
                "จำนวนผลงานตีพิมพ์ใน SCOPUS (" +
                  xhead +
                  ") จำนวน " +
                  url.searchParams.get("count") +
                  " เรื่อง"
              );
            } else {
              let xnewhead = "";

              if (xhead.length > 5) {
                xnewhead = xhead.split(" ")[1];
                if (xhead.split(" ")[2] != "All") {
                  if (xhead.split(" ")[2] == "FC") {
                    xnewhead = xnewhead + "[First/corresponding author]";
                  } else {
                    xnewhead = xnewhead + "[" + xhead.split(" ")[2] + "]";
                  }
                }

                _zhead(xnewhead);
              } else {
                xnewhead = xhead;
              }

              _xtitle(
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white"
                  }}
                >
                  จำนวนผลงานตีพิมพ์ใน SCOPUS ({xnewhead}) ทั้งหมด จำนวน{" "}
                  {url.searchParams.get("count")} เรื่อง
                </Text>
              );

              _xtitleex(
                "จำนวนผลงานตีพิมพ์ใน SCOPUS (" +
                  xnewhead +
                  ") ทั้งหมด จำนวน " +
                  url.searchParams.get("count") +
                  " เรื่อง"
              );
            }
          } else {
            let xnewhead = "";

            if (xhead.length > 15) {
              xnewhead = xhead.split(" ")[2] + " " + xhead.split(" ")[3];
              xnewhead = xnewhead.split("(")[1];
              xnewhead = xnewhead.split(")")[0];
            } else {
              if (xhead.split(" ")[1] != "0") {
                xnewhead = xhead.split(" ")[0];
              } else {
                xnewhead = xhead.split(" ")[0] + "[ทิศทางการวิจัย]";
              }
            }

            _xtitle(
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white"
                }}
              >
                จำนวนผลงานตีพิมพ์ใน SCOPUS ({xnewhead}) ปี พ.ศ.{" "}
                {url.searchParams.get("year")} (1 มกราคม{" "}
                {url.searchParams.get("year")} - 31 ธันวาคม{" "}
                {url.searchParams.get("year")} ) จำนวน{" "}
                {url.searchParams.get("count")} เรื่อง
              </Text>
            );

            _xtitleex(
              "จำนวนผลงานตีพิมพ์ใน SCOPUS (" +
                xnewhead +
                ") ปี พ.ศ. " +
                url.searchParams.get("year") +
                "(1 มกราคม" +
                url.searchParams.get("year") +
                "- 31 ธันวาคม" +
                url.searchParams.get("year") +
                ") จำนวน " +
                url.searchParams.get("count") +
                " เรื่อง"
            );
          }

          if (url.searchParams.get("type") === "BEEN") {
            GetTable(
              url.searchParams.get("year"),
              url.searchParams.get("head"),
              url.searchParams.get("type")
            );
            GetYear(
              url.searchParams.get("year"),
              url.searchParams.get("head"),
              url.searchParams.get("type")
            );
          } else {
            GetTable(
              url.searchParams.get("year"),
              url.searchParams.get("head"),
              ""
            );
            GetYear(
              url.searchParams.get("year"),
              url.searchParams.get("head"),
              ""
            );
          }
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetTable = (year, head, type) => {
    let xyear = "";

    if (year == "All") {
      xyear = "All";
    } else {
      xyear = moment(year, "YYYY").add(-543, "year").add(1, "day").format("L");

      xyear = moment(xyear).format("YYYY");
    }

    axios
      .post(xURL + "find_scopus_list", {
        year: xyear,
        head: head,
        type: type
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];
        let xnum = 0,
          xtitle = "",
          xisi_q = "",
          zyear = "",
          xchkyear = 0;

        result.data.forEach((i, idx) => {
          if (i.title !== xtitle) {
            xnum = xnum + 1;
            xchkyear = xchkyear + 1;
          } else {
            if (i.year !== zyear) {
              xchkyear = xchkyear + 1;
            }

            if (i.isi_q !== xisi_q) {
              xchkyear = xchkyear + 1;
            }
          }

          Tb1.push({
            key: idx,
            num: xnum,
            title: i.title,
            authors: i.first_name,
            role: i.role_name,
            year: i.year,
            journal_name: i.journal_name,
            sjr_q: i.sjr_q,
            isi_q: i.isi_q,
            impact_factor: i.impact_factor,
            direction: i.pub_type_cmu_name,
            citation_count: i.citation_count,
            link: i.link,
            chkyear: xchkyear
          });

          xtitle = i.title;
          zyear = i.year;
          xisi_q = i.isi_q;
        });

        _dataSource1(Tb1);
        setdgdataSource(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable2 = (year, head) => {
    axios
      .post(xURL + "find_scopus_listsl", {
        year: year,
        head: head
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];
        let xnum = 0,
          xtitle = "",
          xisi_q = "",
          zyear = "",
          xchkyear = 0;

        result.data.forEach((i, idx) => {
          if (i.title !== xtitle) {
            xnum = xnum + 1;
            xchkyear = xchkyear + 1;
          } else {
            if (i.year !== zyear) {
              xchkyear = xchkyear + 1;
            }

            if (i.isi_q !== xisi_q) {
              xchkyear = xchkyear + 1;
            }
          }

          Tb1.push({
            key: idx,
            num: xnum,
            title: i.title,
            authors: i.first_name,
            role: i.role_name,
            year: i.year,
            journal_name: i.journal_name,
            sjr_q: i.sjr_q,
            isi_q: i.isi_q,
            impact_factor: i.impact_factor,
            direction: i.pub_type_cmu_name,
            citation_count: i.citation_count,
            link: i.link,
            chkyear: xchkyear
          });

          xtitle = i.title;
          zyear = i.year;
          xisi_q = i.isi_q;
        });

        _xtitle(
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "15px",
              color: "white"
            }}
          >
            จำนวนผลงานตีพิมพ์ใน SCOPUS ({head.split(" ")[1]}) ปี {year} จำนวน{" "}
            {xnum} เรื่อง
          </Text>
        );

        _xtitleex(
          "จำนวนผลงานตีพิมพ์ใน SCOPUS (" +
            head.split(" ")[1] +
            ") ปี " +
            year +
            " จำนวน " +
            xnum +
            " เรื่อง"
        );

        _dataSource1(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetYear = (year, head, type) => {
    let xyear = "";

    if (year == "All") {
      xyear = "All";
    } else {
      xyear = moment(year, "YYYY").add(-543, "year").add(1, "day").format("L");

      xyear = moment(xyear).format("YYYY");
    }

    axios
      .post(xURL + "scopus_slyear", {
        year: xyear,
        head: head,
        type: type
      })
      .then(result => {
        _xYear(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Showscopuslist = zdata => {
    _xresearchex(zdata.authors);

    axios
      .post(xURL + "find_scopus_research", {
        research: zdata.authors
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        let xcount = 0;
        let xisi_q = "";

        result.data.forEach((i, idx) => {
          if (!i.isi_q) {
            xisi_q = "";
          } else {
            xisi_q = i.isi_q;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            title: i.title,
            authors: i.first_name,
            role: i.role_name,
            year: i.year,
            journal_name: i.journal_name,
            sjr_q: i.sjr_q,
            isi_q: xisi_q,
            impact_factor: i.impact_factor,
            direction: i.pub_type_cmu_name,
            citation_count: i.citation_count,
            link: i.link
          });

          xcount = xcount + 1;
        });

        _dataSource2(Tb1);

        _xtitle2(
          "ผลงานตีพิมพ์ใน SCOPUS (ทั้งหมด) [" +
            zdata.authors +
            "] จำนวน " +
            xcount +
            " เรื่อง"
        );

        setIsModalVisible(true);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Showscopuslist2 = zdata => {
    _xjournalex(zdata.journal_name);

    axios
      .post(xURL + "find_scopus_journal", {
        journal: zdata.journal_name
      })
      .then(result => {
        //console.log(result);
        const Tb1 = [];

        let xnum = 0,
          xtitle = "",
          xyear = "";

        result.data.forEach((i, idx) => {
          if (i.title !== xtitle) {
            xnum = xnum + 1;
          } else {
            if (i.year !== xyear) {
              xnum = xnum + 1;
            }
          }

          Tb1.push({
            key: idx,
            num: xnum,
            title: i.title,
            authors: i.first_name,
            role: i.role_name,
            year: i.year,
            journal_name: i.journal_name,
            sjr_q: i.sjr_q,
            isi_q: i.isi_q,
            impact_factor: i.impact_factor,
            direction: i.pub_type_cmu_name,
            citation_count: i.citation_count,
            link: i.link
          });

          xtitle = i.title;
          xyear = i.year;
        });

        _dataSource3(Tb1);

        _xtitle3(
          "ผลงานตีพิมพ์ใน SCOPUS (ทั้งหมด) ของ วารสาร [" +
            zdata.journal_name +
            " ] จำนวน " +
            xnum +
            " เรื่อง"
        );
        setIsModalVisible2(true);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bExcelClick = () => {
    var url = new URL(window.location.href);

    let zyear = url.searchParams.get("year"),
      zhead = url.searchParams.get("head"),
      xyear = "";

    if (zyear == "All") {
      xyear = "All";
    } else {
      xyear = moment(zyear, "YYYY").add(-543, "year").add(1, "day").format("L");

      xyear = moment(xyear).format("YYYY");
    }

    if (xYearValue && xYearValue != "0") {
      axios
        .post(xURL + "scopuslistExyear", { year: xYearValue, head: zhead })
        .then(result => {
          let xdata = [],
            header = [];

          header.push(
            "ชื่อเรื่อง",
            "นักวิจัย",
            "บทบาท",
            "ปี",
            "ชื่อวารสาร",
            "SJR (Q)",
            "ISI (Q)",
            "impact factor",
            "ทิศทางการวิจัย",
            "Citation Count"
          );

          xdata.push([xtitleex]);
          xdata.push([""]);

          xdata.push(header);

          let extitle = "";

          for (var row in result.data) {
            const xx = [];

            // for (var col in result.data[row]) {
            //   xx.push(result.data[row][col]);
            // }
            for (var col in result.data[row]) {
              if (col == "title") {
                if (extitle == "") {
                  xx.push(result.data[row][col]);
                } else {
                  if (result.data[row]["title"] == extitle) {
                    xx.push("");
                  } else {
                    xx.push(result.data[row][col]);
                  }
                }

                extitle = result.data[row]["title"];
              } else {
                xx.push(result.data[row][col]);
              }
            }

            xdata.push(xx);
          }

          var wb = xlsx.utils.book_new(),
            ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

          xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
          xlsx.writeFile(wb, "Excel.xlsx");
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    } else {
      axios
        .post(xURL + "scopuslistEx", { year: xyear, head: zhead })
        .then(result => {
          //console.log(result.data)
          let xdata = [],
            header = [];

          header.push(
            "ชื่อเรื่อง",
            "นักวิจัย",
            "บทบาท",
            "ปี",
            "ชื่อวารสาร",
            "SJR (Q)",
            "ISI (Q)",
            "impact factor",
            "ทิศทางการวิจัย",
            "Citation Count"
          );

          xdata.push([xtitleex]);
          xdata.push([""]);

          xdata.push(header);

          let extitle = "";

          for (var row in result.data) {
            const xx = [];

            for (var col in result.data[row]) {
              if (col == "title") {
                if (extitle == "") {
                  xx.push(result.data[row][col]);
                } else {
                  if (result.data[row]["title"] == extitle) {
                    xx.push("");
                  } else {
                    xx.push(result.data[row][col]);
                  }
                }

                extitle = result.data[row]["title"];
              } else {
                xx.push(result.data[row][col]);
              }
            }

            xdata.push(xx);
          }

          var wb = xlsx.utils.book_new(),
            ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

          xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
          xlsx.writeFile(wb, "Excel.xlsx");
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const bExcelClick2 = () => {
    axios
      .post(xURL + "scopuslistEx2", { research: xresearchex })
      .then(result => {
        let xdata = [],
          header = [];

        header.push(
          "ชื่อเรื่อง",
          "นักวิจัย",
          "บทบาท",
          "ปี",
          "ชื่อวารสาร",
          "SJR (Q)",
          "ISI (Q)",
          "impact factor",
          "ทิศทางการวิจัย",
          "Citation Count"
        );

        xdata.push([xtitle2]);
        xdata.push([""]);

        xdata.push(header);

        let extitle = "";

        for (var row in result.data) {
          const xx = [];

          // for (var col in result.data[row]) {
          //   xx.push(result.data[row][col]);
          // }
          for (var col in result.data[row]) {
            if (col == "title") {
              if (extitle == "") {
                xx.push(result.data[row][col]);
              } else {
                if (result.data[row]["title"] == extitle) {
                  xx.push("");
                } else {
                  xx.push(result.data[row][col]);
                }
              }

              extitle = result.data[row]["title"];
            } else {
              xx.push(result.data[row][col]);
            }
          }

          xdata.push(xx);
        }

        var wb = xlsx.utils.book_new(),
          ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

        xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
        xlsx.writeFile(wb, "Excel.xlsx");
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bExcelClick3 = () => {
    axios
      .post(xURL + "scopuslistEx3", { journal: xjournalex })
      .then(result => {
        let xdata = [],
          header = [];

        header.push(
          "ชื่อเรื่อง",
          "นักวิจัย",
          "บทบาท",
          "ปี",
          "ชื่อวารสาร",
          "SJR (Q)",
          "ISI (Q)",
          "impact factor",
          "ทิศทางการวิจัย",
          "Citation Count"
        );

        xdata.push([xtitle3]);
        xdata.push([""]);

        xdata.push(header);

        let extitle = "";

        for (var row in result.data) {
          const xx = [];

          // for (var col in result.data[row]) {
          //   xx.push(result.data[row][col]);
          // }
          for (var col in result.data[row]) {
            if (col == "title") {
              if (extitle == "") {
                xx.push(result.data[row][col]);
              } else {
                if (result.data[row]["title"] == extitle) {
                  xx.push("");
                } else {
                  xx.push(result.data[row][col]);
                }
              }

              extitle = result.data[row]["title"];
            } else {
              xx.push(result.data[row][col]);
            }
          }

          xdata.push(xx);
        }

        var wb = xlsx.utils.book_new(),
          ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

        xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
        xlsx.writeFile(wb, "Excel.xlsx");
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const columns1 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 80,
      sortDirections: ["descend"],
      sorter: true,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.title === dataSource1[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.title === dataSource1[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "title",
      key: "title",
      sortDirections: ["descend"],
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      },
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                //console.log(record.link)
                window.open(record.link, "_blank");
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (index >= 1 && text === dataSource1[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            text === dataSource1[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      },
      width: 450
    },
    {
      title: "นักวิจัย",
      dataIndex: "authors",
      key: "authors",
      width: 200,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showscopuslist(record);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "บทบาท",
      dataIndex: "role",
      key: "role",
      align: "center",
      width: 150
    },
    {
      title: "ปี",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 80,
      sorter: (a, b) =>
        moment(moment(a.year, "YYYY")).unix() -
        moment(moment(b.year, "YYYY")).unix(),
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.chkyear === dataSource1[trueIndex - 1].chkyear
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.chkyear === dataSource1[trueIndex + i].chkyear;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อวารสาร",
      dataIndex: "journal_name",
      key: "journal_name",
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                Showscopuslist2(record);
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (index >= 1 && record.title === dataSource1[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.title === dataSource1[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      },
      width: 200
    },
    {
      title: "SJR (Q)",
      dataIndex: "sjr_q",
      key: "sjr_q",
      align: "center",
      width: 80,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.title === dataSource1[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.title === dataSource1[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ISI (Q)",
      dataIndex: "isi_q",
      key: "isi_q",
      align: "center",
      width: 80,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource1[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.num === dataSource1[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "impact factor",
      dataIndex: "impact_factor",
      key: "impact_factor",
      align: "center",
      width: 120,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.title === dataSource1[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.title === dataSource1[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ทิศทางการวิจัย",
      dataIndex: "direction",
      key: "direction",
      width: 180,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.title === dataSource1[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.title === dataSource1[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "Citation Count",
      dataIndex: "citation_count",
      key: "citation_count",
      align: "center",
      width: 120,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.title === dataSource1[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource1.length &&
            record.title === dataSource1[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    }
  ];

  const columns2 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 80,
      sorter: (a, b) => a.num - b.num,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      },
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                window.open(record.link, "_blank");
              }}
            >
              {text}
            </a>
          )
        };
      },
      width: 430
    },
    {
      title: "นักวิจัย",
      dataIndex: "authors",
      key: "authors",
      width: 200,
      sorter: (a, b) => {
        return a.authors.localeCompare(b.authors);
      }
    },
    {
      title: "บทบาท",
      dataIndex: "role",
      key: "role",
      align: "center",
      width: 150,
      sorter: (a, b) => {
        return a.role.localeCompare(b.role);
      }
    },
    {
      title: "ปี",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 80,
      sorter: (a, b) =>
        moment(moment(a.year, "YYYY")).unix() -
        moment(moment(b.year, "YYYY")).unix()
    },
    {
      title: "ชื่อวารสาร",
      dataIndex: "journal_name",
      key: "journal_name",
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                Showscopuslist2(record);
                setIsModalVisible(false);
              }}
            >
              {text}
            </a>
          )
        };
      },
      width: 200,
      sorter: (a, b) => {
        return a.journal_name.localeCompare(b.journal_name);
      }
    },
    {
      title: "SJR (Q)",
      dataIndex: "sjr_q",
      key: "sjr_q",
      align: "center",
      width: 80,
      sorter: (a, b) => {
        return a.sjr_q.localeCompare(b.sjr_q);
      }
    },
    {
      title: "ISI (Q)",
      dataIndex: "isi_q",
      key: "isi_q",
      align: "center",
      width: 80,
      sorter: (a, b) => {
        return a.isi_q.localeCompare(b.isi_q);
      }
    },
    {
      title: "impact factor",
      dataIndex: "impact_factor",
      key: "impact_factor",
      align: "center",
      width: 120,
      sorter: (a, b) => a.impact_factor - b.impact_factor
    },
    {
      title: "ทิศทางการวิจัย",
      dataIndex: "direction",
      key: "direction",
      align: "center",
      width: 180,
      sorter: (a, b) => {
        return a.direction.localeCompare(b.direction);
      }
    },
    {
      title: "Citation Count",
      dataIndex: "citation_count",
      key: "citation_count",
      align: "center",
      width: 120
    }
  ];

  const columns3 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 80,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource3[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource3.length &&
            record.num === dataSource3[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.num - b.num,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                window.open(record.link, "_blank");
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (index >= 1 && record.num === dataSource3[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource3.length &&
            record.num === dataSource3[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      },
      width: 430
    },
    {
      title: "นักวิจัย",
      dataIndex: "authors",
      key: "authors",
      width: 200,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                Showscopuslist(record);
                setIsModalVisible2(false);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "บทบาท",
      dataIndex: "role",
      key: "role",
      align: "center",
      width: 150
    },
    {
      title: "ปี",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 80,
      sorter: (a, b) =>
        moment(moment(a.year, "YYYY")).unix() -
        moment(moment(b.year, "YYYY")).unix(),
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource3[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource3.length &&
            record.num === dataSource3[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อวารสาร",
      dataIndex: "journal_name",
      key: "journal_name",
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                Showscopuslist2(record);
                setIsModalVisible(false);
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (index >= 1 && record.num === dataSource3[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource3.length &&
            record.num === dataSource3[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      },
      width: 200
    },
    {
      title: "SJR (Q)",
      dataIndex: "sjr_q",
      key: "sjr_q",
      align: "center",
      width: 80,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource3[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource3.length &&
            record.num === dataSource3[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ISI (Q)",
      dataIndex: "isi_q",
      key: "isi_q",
      align: "center",
      width: 80,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource3[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource3.length &&
            record.num === dataSource3[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "impact factor",
      dataIndex: "impact_factor",
      key: "impact_factor",
      align: "center",
      width: 120,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource3[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource3.length &&
            record.num === dataSource3[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ทิศทางการวิจัย",
      dataIndex: "direction",
      key: "direction",
      align: "center",
      width: 180,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource3[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource3.length &&
            record.num === dataSource3[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "Citation Count",
      dataIndex: "citation_count",
      key: "citation_count",
      align: "center",
      width: 120,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource3[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource3.length &&
            record.num === dataSource3[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    }
  ];

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk2 = () => {
    setIsModalVisible2(false);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };

  function handleChange(value) {
    //console.log(`selected ${value}`);

    _xYearValue(value);

    var url = new URL(window.location.href);

    if (value != 0) {
      GetTable2(value, url.searchParams.get("head"));
    } else {
      GetTable(url.searchParams.get("year"), url.searchParams.get("head"), "");

      _xtitle(
        <Text
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            color: "white"
          }}
        >
          จำนวนผลงานตีพิมพ์ใน SCOPUS ({
            url.searchParams.get("head").split(" ")[1]
          }) ทั้งหมด จำนวน {url.searchParams.get("count")} เรื่อง
        </Text>
      );

      _xtitleex(
        "จำนวนผลงานตีพิมพ์ใน SCOPUS (" +
          url.searchParams.get("head").split(" ")[1] +
          ") ทั้งหมด จำนวน " +
          url.searchParams.get("count") +
          " เรื่อง"
      );
    }
  }

  const dgcolumns = [
    {
      name: "num",
      header: "ลำดับที่",
      maxWidth: 90,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      sortable: false,
      rowspan: ({ value, dataSourceArray, rowIndex, column }) => {
        let rowspan = 1;

        const prevData = dataSourceArray[rowIndex - 1];
        if (prevData && prevData[column.name] === value) {
          return rowspan;
        }
        let currentRowIndex = rowIndex + 1;
        while (
          dataSourceArray[currentRowIndex] &&
          dataSourceArray[currentRowIndex][column.name] === value
        ) {
          rowspan++;
          currentRowIndex++;
          if (rowspan > 9) {
            break;
          }
        }
        return rowspan;
      }
    },
    {
      name: "title",
      header: "ชื่อเรื่อง",
      maxWidth: 600,
      defaultFlex: 2,
      headerAlign: "center",
      render: ({ value, cellProps }) =>
        <a
          className="text-warp-table"
          onClick={e => {
            //console.log(cellProps.data.link);
            window.open(cellProps.data.link, "_blank");
          }}
        >
          {value}
        </a>,
      rowspan: ({ value, dataSourceArray, rowIndex, column }) => {
        let rowspan = 1;

        const prevData = dataSourceArray[rowIndex - 1];
        if (prevData && prevData[column.name] === value) {
          return rowspan;
        }
        let currentRowIndex = rowIndex + 1;
        while (
          dataSourceArray[currentRowIndex] &&
          dataSourceArray[currentRowIndex][column.name] === value
        ) {
          rowspan++;
          currentRowIndex++;
          if (rowspan > 9) {
            break;
          }
        }
        return rowspan;
      }
    },
    {
      name: "authors",
      header: "นักวิจัย",
      sortable: false,
      maxWidth: 200,
      defaultFlex: 1,
      headerAlign: "center",
      render: ({ value, cellProps }) =>
        <a
          className="text-warp-table"
          onClick={e => {
            //console.log(value);
            Showscopuslist(cellProps.data);
          }}
        >
          {value}
        </a>
    },
    {
      name: "role",
      header: "บทบาท",
      sortable: false,
      maxWidth: 150,
      defaultFlex: 1,
      headerAlign: "center",
      render: ({ value }) =>
        <p className="text-warp-table">
          {value}
        </p>
    },
    {
      name: "year",
      header: "ปี",
      sortable: false,
      maxWidth: 80,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      rowspan: ({ value, dataSourceArray, rowIndex, column }) => {
        let rowspan = 1;

        const prevData = dataSourceArray[rowIndex - 1];

        if (
          prevData &&
          prevData[column.name] === value &&
          prevData.title === dataSourceArray[rowIndex].title
        ) {
          return rowspan;
        }

        let currentRowIndex = rowIndex + 1;

        while (
          dataSourceArray[currentRowIndex] &&
          dataSourceArray[currentRowIndex][column.name] === value &&
          dataSourceArray[currentRowIndex].title ===
            dataSourceArray[rowIndex].title
        ) {
          rowspan++;
          currentRowIndex++;
        }
        return rowspan;
      }
    },
    {
      name: "journal_name",
      header: "ชื่อวารสาร",
      sortable: false,
      maxWidth: 250,
      defaultFlex: 1,
      headerAlign: "center",
      render: ({ value, cellProps }) =>
        <a
          className="text-warp-table"
          onClick={e => {
            //console.log(value);
            Showscopuslist2(cellProps.data);
          }}
        >
          {value}
        </a>,
      rowspan: ({ value, dataSourceArray, rowIndex, column }) => {
        let rowspan = 1;

        const prevData = dataSourceArray[rowIndex - 1];
        if (prevData && prevData[column.name] === value) {
          return rowspan;
        }
        let currentRowIndex = rowIndex + 1;
        while (
          dataSourceArray[currentRowIndex] &&
          dataSourceArray[currentRowIndex][column.name] === value
        ) {
          rowspan++;
          currentRowIndex++;
          if (rowspan > 9) {
            break;
          }
        }
        return rowspan;
      }
    },
    {
      name: "sjr_q",
      header: "SJR (Q)",
      sortable: false,
      maxWidth: 80,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      rowspan: ({ value, dataSourceArray, rowIndex, column }) => {
        let rowspan = 1;

        const prevData = dataSourceArray[rowIndex - 1];

        if (
          prevData &&
          prevData[column.name] === value &&
          prevData.title === dataSourceArray[rowIndex].title
        ) {
          return rowspan;
        }

        let currentRowIndex = rowIndex + 1;

        while (
          dataSourceArray[currentRowIndex] &&
          dataSourceArray[currentRowIndex][column.name] === value &&
          dataSourceArray[currentRowIndex].title ===
            dataSourceArray[rowIndex].title
        ) {
          rowspan++;
          currentRowIndex++;
        }
        return rowspan;
      }
    },
    {
      name: "isi_q",
      header: "ISI (Q)",
      sortable: false,
      maxWidth: 80,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      rowspan: ({ value, dataSourceArray, rowIndex, column }) => {
        let rowspan = 1;

        const prevData = dataSourceArray[rowIndex - 1];

        if (
          prevData &&
          prevData[column.name] === value &&
          prevData.title === dataSourceArray[rowIndex].title
        ) {
          return rowspan;
        }

        let currentRowIndex = rowIndex + 1;

        while (
          dataSourceArray[currentRowIndex] &&
          dataSourceArray[currentRowIndex][column.name] === value &&
          dataSourceArray[currentRowIndex].title ===
            dataSourceArray[rowIndex].title
        ) {
          rowspan++;
          currentRowIndex++;
        }
        return rowspan;
      }
    },
    {
      name: "impact_factor",
      header: "impact factor",
      sortable: false,
      maxWidth: 120,
      defaultFlex: 1,
      headerAlign: "center",
      textAlign: "center",
      rowspan: ({ value, dataSourceArray, rowIndex, column }) => {
        let rowspan = 1;

        const prevData = dataSourceArray[rowIndex - 1];

        if (
          prevData &&
          prevData[column.name] === value &&
          prevData.title === dataSourceArray[rowIndex].title
        ) {
          return rowspan;
        }

        let currentRowIndex = rowIndex + 1;

        while (
          dataSourceArray[currentRowIndex] &&
          dataSourceArray[currentRowIndex][column.name] === value &&
          dataSourceArray[currentRowIndex].title ===
            dataSourceArray[rowIndex].title
        ) {
          rowspan++;
          currentRowIndex++;
        }
        return rowspan;
      }
    },
    {
      name: "direction",
      header: "ทิศทางการวิจัย",
      sortable: false,
      maxWidth: 240,
      defaultFlex: 1,
      headerAlign: "center",
      render: ({ value }) =>
        <p className="text-warp-table">
          {value}
        </p>,
      rowspan: ({ value, dataSourceArray, rowIndex, column }) => {
        let rowspan = 1;

        const prevData = dataSourceArray[rowIndex - 1];

        if (
          prevData &&
          prevData[column.name] === value &&
          prevData.title === dataSourceArray[rowIndex].title
        ) {
          return rowspan;
        }

        let currentRowIndex = rowIndex + 1;

        while (
          dataSourceArray[currentRowIndex] &&
          dataSourceArray[currentRowIndex][column.name] === value &&
          dataSourceArray[currentRowIndex].title ===
            dataSourceArray[rowIndex].title
        ) {
          rowspan++;
          currentRowIndex++;
        }
        return rowspan;
      }
    }
  ];

  const gridStyle = { minHeight: 850 };
  const headerProps = {
    style: {
      height: 120,
      background: "DarkCyan",
      color: "white"
    }
  };

  const [dgdataSource, setdgdataSource] = useState([]);

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{
          padding: "0 20px",
          marginTop: 64
        }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title={
                <a
                  style={{
                    color: "black"
                  }}
                  onClick={() => {
                    window.close();
                  }}
                >
                  SCOPUS Journal List
                </a>
              }
              onBack={() => window.close()}
            />
            <Divider />

            <Space style={{ float: "right" }}>
              {zhead.length > 5
                ? <React.Fragment>
                    <Form.Item label="ปีที่แสดง" name="slyear">
                      <Select
                        defaultValue="ทั้งหมด"
                        style={{ width: "100%" }}
                        onChange={handleChange}
                      >
                        <Option value="0">ทั้งหมด</Option>
                        {xYear.length > 0
                          ? xYear.map(i => {
                              return (
                                <Option value={i.year}>
                                  {i.year}
                                </Option>
                              );
                            })
                          : <div />}
                      </Select>
                    </Form.Item>

                    <Form.Item>
                      <Button ghost type="success" onClick={bExcelClick}>
                        <FileExcelOutlined /> Excel
                      </Button>
                    </Form.Item>
                  </React.Fragment>
                : <Form.Item>
                    <Button ghost type="success" onClick={bExcelClick}>
                      <FileExcelOutlined /> Excel
                    </Button>
                  </Form.Item>}
            </Space>
            <br />
            <br />

            <Card
              title={xtitle}
              headStyle={{
                backgroundColor: "#00474f"
              }}
              style={{
                //margin: "10px",
                borderRadius: "15px",
                overflow: "hidden"
              }}
            >
              {/* <ReactDataGrid
                idProperty="id"
                columns={dgcolumns}
                dataSource={dgdataSource}
                style={gridStyle}
                showZebraRows={false}
                rowHeight={70}
                headerProps={headerProps}
              /> */}

              <Table
                bordered
                scroll={{ x: "max-content" }}
                dataSource={dataSource1}
                columns={columns1}
                size="small"
                pagination={false}
              />
            </Card>
          </Col>
        </Row>

        <br />
      </Content>

      <Footer_last />

      <Modal
        title={xtitle2}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1800}
      >
        <Button
          ghost
          type="success"
          onClick={bExcelClick2}
          style={{ float: "right" }}
        >
          <FileExcelOutlined /> Excel
        </Button>
        <br />
        <br />

        <Card size="small">
          <Table
            bordered
            scroll={{ x: "max-content" }}
            dataSource={dataSource2}
            columns={columns2}
            size="small"
            pagination={false}
          />
        </Card>
      </Modal>

      <Modal
        title={xtitle3}
        visible={isModalVisible2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={null}
        width={1800}
      >
        <Button
          ghost
          type="success"
          onClick={bExcelClick3}
          style={{ float: "right" }}
        >
          <FileExcelOutlined /> Excel
        </Button>
        <br />
        <br />

        <Card size="small">
          <Table
            bordered
            scroll={{ x: "max-content" }}
            dataSource={dataSource3}
            columns={columns3}
            size="small"
            pagination={false}
          />
        </Card>
      </Modal>
      <BackTop />
    </React.Fragment>
  );
};
export default Research_scopus_list;
