import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";

import React, { useState } from "react";

import { Row, Col, Layout, Divider, Button, Menu } from "antd";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined
} from "@ant-design/icons";

import { createGlobalStyle } from "styled-components";

import Dashboard_r from "./Dashboard_r";
import Dashboard_i from "./Dashboard_i";
import Dashboard_he from "./Dashboard_he";
import Dashboard_s from "./Dashboard_s";

const { Content } = Layout;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;
const Dashboard_Home = () => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const [page, _page] = useState(1);

  const items = [
    getItem("R", "1", <PieChartOutlined />),
    getItem("I", "2", <PieChartOutlined />),
    getItem("HE", "3", <PieChartOutlined />),
    getItem("S", "4", <PieChartOutlined />)
  ];

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type
    };
  }

  const onClick = e => {
    // console.log("click ", e.key);
    _page(e.key);
  };

  return (
    <React.Fragment>
      <StyledForm />

      <Content className="site-layout" style={{ padding: "0 0px" }}>
        <Row justify="center" style={{ background: "#f3fbff" }}>
          <Col xs={23} sm={23} md={23} lg={23} xl={23}>
            <Row>
              <h2
                style={{
                  marginTop: "40px",
                  fontSize: "25px",
                  fontWeight: "bold"
                }}
              >
                Dashboard
              </h2>
            </Row>

            <Divider />

            <Row>
              <Col xs={24} sm={8} md={6} lg={4} xl={3}>
                <div
                  style={{
                    width: 150
                  }}
                >
                  <Button
                    type="primary"
                    onClick={toggleCollapsed}
                    style={{
                      marginBottom: 16
                    }}
                  >
                    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  </Button>
                  <Menu
                    defaultSelectedKeys={["1"]}
                    defaultOpenKeys={["sub1"]}
                    mode="inline"
                    theme="light"
                    inlineCollapsed={collapsed}
                    items={items}
                    onClick={onClick}
                  />
                </div>
              </Col>

              <Col xs={24} sm={16} md={18} lg={20} xl={21}>
                {page == 1 ? <Dashboard_r /> : <div />}
                {page == 2 ? <Dashboard_i /> : <div />}
                {page == 3 ? <Dashboard_he /> : <div />}
                {page == 4 ? <Dashboard_s /> : <div />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default Dashboard_Home;
