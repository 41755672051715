import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Layout,
  Table,
  Card,
  Divider,
  Tabs,
  Input,
  Space,
  BackTop,
  PageHeader,
  Spin,
  Select,
  Typography,
  Modal
} from "antd";

import Button from "antd-button-color";

import { createGlobalStyle } from "styled-components";

import {
  ProfileOutlined,
  ContainerOutlined,
  AlertOutlined,
  FileExcelOutlined,
  SettingOutlined
} from "@ant-design/icons";

import moment from "moment";
//import "moment/locale/th";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import * as xlsx from "xlsx";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Research_academic = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState([]);
  const [dataSource2, _dataSource2] = useState([]);
  const [dataSource3, _dataSource3] = useState([]);

  const [dataSourceEx, _dataSourceEx] = useState([]);

  const [keyTab, _keyTab] = useState("1");

  const [levelU, _levelU] = useState("");

  const [xtitle, _xtitle] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [loading, _loading] = useState(true);

  const [paginationInfo] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const xresearch_id = localStorage.getItem("research_id");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          axios
            .post(xURL + "findlevel", { research_id: xresearch_id })
            .then(result => {
              if (result.data.length > 0) {
                if (result.data[0].user_level_id == 4) {
                  _levelU("research");
                } else {
                  _levelU("");
                }
              } else {
                _levelU("");
              }

              GetTable();
              GetTable2();
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetTable = () => {
    axios
      .get(xURL + "find_academic1")
      .then(result => {
        //console.log(result.data);
        Show_academic(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const GetTable2 = () => {
    axios
      .get(xURL + "find_academic2")
      .then(result => {
        //console.log(result.data);
        Show_academic2(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Show_academic = zdata => {
    const Tb1 = [];

    let xstudent_name = "",
      xstatus = "",
      xyear = 0,
      xyear2 = 0,
      xyear3 = "",
      xgraduate_ontime = "";

    zdata.forEach((i, idx) => {
      if (i.student_name) {
        xstudent_name = i.student_name;
      } else {
        xstudent_name = "";
      }

      if (i.status == 1) {
        xstatus = "กำลังศึกษา";
      } else if (i.status == 2) {
        xstatus = "สำเร็จการศึกษา";
      } else if (i.status == 3) {
        xstatus = "ลาออก";
      } else if (i.status == 4) {
        xstatus = "พ้นสภาพ";
      }

      if (i.graduate_ontime == "Y") {
        xgraduate_ontime = "จบตามกำหนดเวลา";
      } else if (i.graduate_ontime == "N") {
        xgraduate_ontime = "ไม่จบตามกำหนดเวลา";
      }

      xyear = parseInt(i.year) + 543;
      xyear2 = parseInt(i.year_end) + 543;

      if (i.year_graduate == null) {
        xyear3 = "";
      } else {
        xyear3 = parseInt(i.year_graduate) + 543;
      }

      Tb1.push({
        key: idx,
        num: idx + 1,
        student_id: (
          <Text style={{ fontWeight: "bold" }}>
            {i.student_id_cmu}
          </Text>
        ),
        student: xstudent_name,
        advisor: i.advisor_name,
        degree_course: i.degree_short,
        year: xyear,
        year_end: xyear2,
        year_graduate: xyear3,
        status: xstatus,
        status2: xgraduate_ontime,
        graduate_ontime: i.graduate_ontime
      });
    });

    _dataSource1(Tb1);
  };

  const Show_academic2 = zdata => {
    const Tb1 = [],
      TbEx = [];

    let xnum = 0,
      xtitle = "",
      xisi_q = "",
      zyear = "",
      xchkyear = 0;

    zdata.forEach((i, idx) => {
      // console.log(zdata);
      if (i.title !== xtitle) {
        xnum = xnum + 1;
        xchkyear = xchkyear + 1;
      } else {
        if (i.year !== zyear) {
          xchkyear = xchkyear + 1;
        }

        if (i.isi_q !== xisi_q) {
          xchkyear = xchkyear + 1;
        }
      }

      Tb1.push({
        key: idx,
        num: xnum,
        title: i.title,
        authors: i.student_name,
        role: i.role_name,
        year: i.year,
        journal_name: i.journal_name,
        sjr_q: i.sjr_q,
        isi_q: i.isi_q,
        impact_factor: i.impact_factor,
        direction: i.pub_type_cmu_name,
        citation_count: i.citation_count,
        link: i.link,
        chkyear: xchkyear
      });

      TbEx.push({
        title: i.title,
        authors: i.student_name,
        role: i.role_name,
        year: i.year,
        journal_name: i.journal_name,
        sjr_q: i.sjr_q,
        isi_q: i.isi_q,
        impact_factor: i.impact_factor,
        direction: i.pub_type_cmu_name,
        citation_count: i.citation_count
      });

      xtitle = i.title;
      zyear = i.year;
      xisi_q = i.isi_q;

      _loading(false);
    });

    _dataSource2(Tb1);
    _dataSourceEx(TbEx);
  };

  const Showscopuslist = zdata => {
    axios
      .post(xURL + "find_academic3", {
        research: zdata.authors
      })
      .then(result => {
        const Tb1 = [];

        let xcount = 0;
        let xisi_q = "";

        result.data.forEach((i, idx) => {
          if (!i.isi_q) {
            xisi_q = "";
          } else {
            xisi_q = i.isi_q;
          }

          Tb1.push({
            key: idx,
            num: idx + 1,
            title: i.title,
            authors: i.student_name,
            role: i.role_name,
            year: i.year,
            journal_name: i.journal_name,
            sjr_q: i.sjr_q,
            isi_q: xisi_q,
            impact_factor: i.impact_factor,
            direction: i.pub_type_cmu_name,
            citation_count: i.citation_count,
            link: i.link
          });

          xcount = xcount + 1;
        });

        _dataSource3(Tb1);

        _xtitle(
          "ผลงานตีพิมพ์ใน SCOPUS (ทั้งหมด) [" +
            zdata.authors +
            "] จำนวน " +
            xcount +
            " เรื่อง"
        );

        setIsModalVisible(true);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bExcelClick = () => {
    let xdata = [],
      xtabtitle = "",
      header = [];

    xtabtitle = "รายงานผลงานตีพิมพ์ของนักศึกษา";

    header.push(
      "ชื่อเรื่อง",
      "นักวิจัย",
      "บทบาท",
      "ปี",
      "ชื่อวารสาร",
      "SJR(Q)",
      "ISI(Q)",
      "Impact Factor",
      "ทิศทางการวิจัย",
      "Citation Count"
    );

    xdata.push([xtabtitle]);

    xdata.push([""]);
    xdata.push(header);

    let extitle = "",
      exyear = "",
      exjournal_name = "",
      exsjr_q = "",
      exisi_q = "",
      eximpact_factor = "",
      exdirection = "",
      excitation_count = "";

    for (var row in dataSourceEx) {
      const xx = [];

      let xchk = 0;

      for (var col in dataSourceEx[row]) {
        if (col == "title") {
          if (extitle == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (dataSourceEx[row]["title"] == extitle) {
              xx.push("");

              xchk = 1;
            } else {
              xx.push(dataSourceEx[row][col]);

              xchk = 0;
            }
          }

          extitle = dataSourceEx[row]["title"];
        } else if (col == "year") {
          if (exyear == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exyear = dataSourceEx[row]["year"];
        } else if (col == "journal_name") {
          if (exjournal_name == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exjournal_name = dataSourceEx[row]["journal_name"];
        } else if (col == "sjr_q") {
          if (exsjr_q == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exsjr_q = dataSourceEx[row]["sjr_q"];
        } else if (col == "isi_q") {
          if (exisi_q == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exisi_q = dataSourceEx[row]["isi_q"];
        } else if (col == "impact_factor") {
          if (eximpact_factor == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          eximpact_factor = dataSourceEx[row]["impact_factor"];
        } else if (col == "direction") {
          if (exdirection == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          exdirection = dataSourceEx[row]["direction"];
        } else if (col == "citation_count") {
          if (excitation_count == "") {
            xx.push(dataSourceEx[row][col]);
          } else {
            if (xchk == 1) {
              xx.push("");
            } else {
              xx.push(dataSourceEx[row][col]);
            }
          }

          excitation_count = dataSourceEx[row]["citation_count"];
        } else {
          xx.push(dataSourceEx[row][col]);
        }
      }

      xdata.push(xx);
    }

    var wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(xdata, { skipHeader: true });

    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "รายงานผลงานตีพิมพ์ของนักศึกษา.xlsx");
  };

  const columns1 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 50,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "รหัสนักศึกษา",
      dataIndex: "student_id",
      key: "student_id",
      align: "center",
      width: 120,
      sorter: (a, b) => a.num - b.num
    },
    {
      title: "นักศึกษา",
      dataIndex: "student",
      key: "student",
      align: "center",
      width: 200,
      sorter: (a, b) => {
        return a.student.localeCompare(b.student);
      },
      render: (text, record) => __TBShowText(record.student, 0, 1)
    },
    {
      title: "อาจารย์ที่ปรึกษา",
      dataIndex: "advisor",
      key: "advisor",
      align: "center",
      width: 200,
      sorter: (a, b) => {
        return a.advisor.localeCompare(b.advisor);
      },
      render: (text, record) => __TBShowText(record.advisor, 0, 1)
    },
    {
      title: "หลักสูตร",
      dataIndex: "degree_course",
      key: "degree_course",
      align: "center",
      width: 150,
      sorter: (a, b) => {
        return a.degree_course.localeCompare(b.degree_course);
      }
    },
    {
      title: "ปีการศึกษาเริ่มต้น",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 80,
      sorter: (a, b) => a.year - b.year
    },
    {
      title: "ปีการศึกษาสิ้นสุด",
      dataIndex: "year_end",
      key: "year_end",
      align: "center",
      width: 80,
      sorter: (a, b) => a.year_end - b.year_end
    },
    {
      title: "ปีการศึกษาที่จบ",
      dataIndex: "year_graduate",
      key: "year_graduate",
      align: "center",
      width: 80,
      sorter: (a, b) => a.year_graduate - b.year_graduate
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 130,
      sorter: (a, b) => {
        return a.status.localeCompare(b.status);
      },
      render(text, record, index) {
        if (text == "สำเร็จการศึกษา") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "#06D001" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        } else if (text == "กำลังศึกษา") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "#667BC6" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        } else if (text == "ลาออก") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "#ff0000" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        } else if (text == "พ้นสภาพ") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "#000000" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        }
      }
    },
    {
      title: "สำเร็จการศึกษาตามกำหนดเวลา",
      dataIndex: "status2",
      key: "status2",
      align: "center",
      width: 150,
      render(text, record, index) {
        if (record.graduate_ontime == "Y") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "green" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        } else if (record.graduate_ontime == "N") {
          return {
            children: __TBShowText(
              <Text style={{ fontWeight: "bold", color: "red" }}>
                {text}
              </Text>,
              0,
              1
            )
          };
        }
      }
    }
  ];

  const columns2 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 80,
      sortDirections: ["descend"],
      sorter: true,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.title === dataSource2[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.title === dataSource2[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "title",
      key: "title",
      sortDirections: ["descend"],
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      },
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: (
            <a
              onClick={e => {
                //console.log(record.link)
                window.open(record.link, "_blank");
              }}
            >
              {text}
            </a>
          ),
          props: {}
        };

        if (index >= 1 && text === dataSource2[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            text === dataSource2[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      },
      width: 450
    },
    {
      title: "นักวิจัย",
      dataIndex: "authors",
      key: "authors",
      width: 200,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                Showscopuslist(record);
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "บทบาท",
      dataIndex: "role",
      key: "role",
      align: "center",
      width: 150
    },
    {
      title: "ปี",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 80,
      // sorter: (a, b) =>
      //   moment(moment(a.year, "YYYY")).unix() -
      //   moment(moment(b.year, "YYYY")).unix(),
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (
          index >= 1 &&
          record.chkyear === dataSource2[trueIndex - 1].chkyear
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.chkyear === dataSource2[trueIndex + i].chkyear;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ชื่อวารสาร",
      dataIndex: "journal_name",
      key: "journal_name",
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.title === dataSource2[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.title === dataSource2[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      },
      width: 200
    },
    {
      title: "SJR (Q)",
      dataIndex: "sjr_q",
      key: "sjr_q",
      align: "center",
      width: 80,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.title === dataSource2[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.title === dataSource2[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ISI (Q)",
      dataIndex: "isi_q",
      key: "isi_q",
      align: "center",
      width: 80,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.num === dataSource2[trueIndex - 1].num) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.num === dataSource2[trueIndex + i].num;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "impact factor",
      dataIndex: "impact_factor",
      key: "impact_factor",
      align: "center",
      width: 120,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.title === dataSource2[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.title === dataSource2[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "ทิศทางการวิจัย",
      dataIndex: "direction",
      key: "direction",
      width: 180,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.title === dataSource2[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.title === dataSource2[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    },
    {
      title: "Citation Count",
      dataIndex: "citation_count",
      key: "citation_count",
      align: "center",
      width: 120,
      render(text, record, index) {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);

        const obj = {
          children: text,
          props: {}
        };

        if (index >= 1 && record.title === dataSource2[trueIndex - 1].title) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataSource2.length &&
            record.title === dataSource2[trueIndex + i].title;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      }
    }
  ];

  const columns3 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 80,
      sorter: (a, b) => a.num - b.num,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      },
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                window.open(record.link, "_blank");
              }}
            >
              {text}
            </a>
          )
        };
      },
      width: 430
    },
    {
      title: "นักวิจัย",
      dataIndex: "authors",
      key: "authors",
      width: 200,
      sorter: (a, b) => {
        return a.authors.localeCompare(b.authors);
      }
    },
    {
      title: "บทบาท",
      dataIndex: "role",
      key: "role",
      align: "center",
      width: 150,
      sorter: (a, b) => {
        return a.role.localeCompare(b.role);
      }
    },
    {
      title: "ปี",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 80,
      sorter: (a, b) =>
        moment(moment(a.year, "YYYY")).unix() -
        moment(moment(b.year, "YYYY")).unix()
    },
    {
      title: "ชื่อวารสาร",
      dataIndex: "journal_name",
      key: "journal_name",
      width: 200,
      sorter: (a, b) => {
        return a.journal_name.localeCompare(b.journal_name);
      }
    },
    {
      title: "SJR (Q)",
      dataIndex: "sjr_q",
      key: "sjr_q",
      align: "center",
      width: 80,
      sorter: (a, b) => {
        return a.sjr_q.localeCompare(b.sjr_q);
      }
    },
    {
      title: "ISI (Q)",
      dataIndex: "isi_q",
      key: "isi_q",
      align: "center",
      width: 80,
      sorter: (a, b) => {
        return a.isi_q.localeCompare(b.isi_q);
      }
    },
    {
      title: "impact factor",
      dataIndex: "impact_factor",
      key: "impact_factor",
      align: "center",
      width: 120,
      sorter: (a, b) => a.impact_factor - b.impact_factor
    },
    {
      title: "ทิศทางการวิจัย",
      dataIndex: "direction",
      key: "direction",
      align: "center",
      width: 180,
      sorter: (a, b) => {
        return a.direction.localeCompare(b.direction);
      }
    },
    {
      title: "Citation Count",
      dataIndex: "citation_count",
      key: "citation_count",
      align: "center",
      width: 120
    }
  ];
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const TabonChange = e => {
    //console.log(e);
    _keyTab(e);
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="สำนักวิชาการวิจัยวิทยาศาสตร์สุขภาพ"
            />
            <Divider />

            <Card
              title={
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                    color: "white"
                  }}
                />
              }
              headStyle={{
                backgroundColor: "#00474f"
              }}
              style={{
                //margin: "10px",
                borderRadius: "15px",
                overflow: "hidden"
              }}
            >
              <Tabs defaultActiveKey="1" size={"small"} onChange={TabonChange}>
                <TabPane
                  tab={
                    <span>
                      <ProfileOutlined />
                      รายชื่ออาจารย์ที่ปรึกษาและนักศึกษา
                    </span>
                  }
                  key="1"
                >
                  <Row>
                    <SettingOutlined />
                    <a
                      style={{
                        marginTop: "-4px",
                        marginLeft: "2px",
                        textDecoration: "underline"
                      }}
                      onClick={() =>
                        window.open("/Research_academic_set", "_self")}
                    >
                      จัดการข้อมูล
                    </a>
                  </Row>
                  <br />

                  <Table
                    bordered
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource1}
                    columns={columns1}
                    size="small"
                    pagination={false}
                  />

                  <div
                    style={{ marginBottom: "30px", backgroundColor: "#fff" }}
                  >
                    <br />
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#748DA6",
                        fontWeight: "bold"
                      }}
                    >
                      หมายเหตุ: รายละเอียดหลักสูตร
                    </span>

                    <br />
                    <span style={{ fontSize: "12px", color: "#748DA6" }}>
                      - MS 1: M.Sc. (Health Sciences Research) Thesis only
                    </span>

                    <br />
                    <span style={{ fontSize: "12px", color: "#748DA6" }}>
                      - MS 2: M.Sc. (Health Sciences Research) Thesis +
                      Coursework
                    </span>

                    <br />
                    <span style={{ fontSize: "12px", color: "#748DA6" }}>
                      - PhD 1.1: Ph.D. (Health Sciences Research) 3 years of
                      study, MS degree holders
                    </span>

                    <br />
                    <span style={{ fontSize: "12px", color: "#748DA6" }}>
                      - PhD 1.2: Ph.D. (Health Sciences Research) 5 years of
                      study, BS degree holders
                    </span>
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <ContainerOutlined />
                      ผลงานตีพิมพ์ของนักศึกษา
                    </span>
                  }
                  key="2"
                >
                  <Spin tip="Loading..." spinning={loading} size="large">
                    <Row>
                      <Button ghost type="success" onClick={bExcelClick}>
                        <FileExcelOutlined /> Excel
                      </Button>
                    </Row>
                    <br />

                    <Table
                      bordered
                      scroll={{ x: "max-content" }}
                      dataSource={dataSource2}
                      columns={columns2}
                      size="small"
                      pagination={false}
                    />
                  </Spin>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <AlertOutlined />
                      รางวัลสนับสนุนการตีพิมพ์
                    </span>
                  }
                  key="3"
                >
                  -- ไม่มีข้อมูล --
                  <div style={{ height: "250px" }} />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Content>

      <div style={{ height: "200px" }} />

      <Footer_last />

      <Modal
        title={xtitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1800}
      >
        <Card size="small">
          <Table
            bordered
            scroll={{ x: "max-content" }}
            dataSource={dataSource3}
            columns={columns3}
            size="small"
            pagination={false}
          />
        </Card>
      </Modal>

      <BackTop />
    </React.Fragment>
  );
};

export default Research_academic;
