import React, { useState } from "react";

import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer
} from "recharts";

const Hightchart2 = prop => {
  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height={250}>
        {prop.order == 1
          ? <ComposedChart
              width={500}
              height={400}
              data={prop.dataHigh}
              margin={{
                top: 10,
                right: 0,
                bottom: 40,
                left: -30
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" scale="band" />
              <YAxis />
              <Tooltip />
              {/* <Legend /> */}
              {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}

              <Bar dataKey="data_in_Month" barSize={20} fill="#BED7DC" />

              <Line
                type="monotone"
                dataKey="data"
                stroke="#6AD4DD"
                strokeWidth={3}
              />

              <Line
                type="monotone"
                dataKey="goal"
                stroke="#9BCF53"
                strokeDasharray="3 3"
                strokeWidth={2}
              />
              {/* <Scatter dataKey="cnt" fill="red" /> */}
            </ComposedChart>
          : <ComposedChart
              width={500}
              height={400}
              data={prop.dataHigh}
              margin={{
                top: 10,
                right: 0,
                bottom: 40,
                left: -30
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" scale="band" />
              <YAxis />
              <Tooltip />
              {/* <Legend /> */}
              {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}

              <Line
                type="monotone"
                dataKey="data"
                stroke="#6AD4DD"
                strokeWidth={3}
              />

              <Line
                type="monotone"
                dataKey="goal"
                stroke="#9BCF53"
                strokeDasharray="3 3"
                strokeWidth={2}
              />
              {/* <Scatter dataKey="cnt" fill="red" /> */}
            </ComposedChart>}
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default Hightchart2;
